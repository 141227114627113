// eslint ignore no-param-reassign
import { fetch } from '@/api';
import omit from 'lodash/omit';

// import {
//   spatialLayers as layers,
//   getSpatialLayer,
//   ADD_SPATIAL_LAYER,
//   REMOVE_SPATIAL_LAYER,
// } from './spatialLayersMixins';

import {
  state as spatialState,
  getters as spatialGetters,
  actions as spatialActions,
  mutations as spatialMutations,
} from '@acorns-ui/Stores/Objects/Mixins/spatialLayersMixins';

export const LAYERS_AVAILABLE = 'LAYERS_AVAILABLE';
export const SET_MAP_BOUNDARY = 'SET_MAP_BOUNDARY';
export const SET_BOUNDARY = 'SET_BOUNDARY';
export const USE_MAP_BOUNDARY = 'USE_MAP_BOUNDARY';
export const ADD_LAYER = 'ADD_LAYER';
export const REMOVE_LAYER = 'REMOVE_LAYER';
export const ADD_LOADING_LAYER = 'ADD_LOADING_LAYER';
export const REMOVE_LOADING_LAYER = 'REMOVE_LOADING_LAYER';
export const SET_LAYER_DATA = 'SET_LAYER_DATA';
export const DESELECT_LAYER = 'DESELECT_LAYER';
export const CLEAR_MAP_LAYERS = 'CLEAR_MAP_LAYERS';

const initialState = {
  ...spatialState,
  loadingLayers: [],
  style: {},
  //layers: {},
  spatialLayersList: [],
  availableLayers: [],
  useMapBoundary: true,
  mapBoundary: {},
  hasFeatures: false, // are we recording features?
  hasGeometry: false, // should we have a geometry?
  hasProperty: false, // should we have a property?
};

const mutations = {
  ...spatialMutations,
  [LAYERS_AVAILABLE](state, value) {
    // convert ids to strings
    state.availableLayers = value.map(layer => ({
      ...layer,
      layer_id: layer.layer_id.toString(),
    }));
  },
  [SET_BOUNDARY](state, value) {
    state.boundary = value;
  },
  [SET_MAP_BOUNDARY](state, value) {
    state.mapBoundary = value;
  },
  [USE_MAP_BOUNDARY](state, value) {
    state.useMapBoundary = value;
  },
  [ADD_LOADING_LAYER](state, layerId) {
    state.loadingLayers = [...state.loadingLayers, layerId];
  },
  [REMOVE_LOADING_LAYER](state, layerId) {
    state.loadingLayers = state.loadingLayers
      .filter(layer => layer !== layerId);
  },
  [ADD_LAYER](state, layerData) {
    const id = layerData.info.id.toString();
    Vue.set(state.layers, id, { ...layerData, info: { ...layerData.info, id } });
    state.spatialLayersList = [...state.spatialLayersList, id];
  },
  [REMOVE_LAYER](state, layerId) {
    state.layers = omit(state.layers, layerId);
    state.spatialLayersList = state.spatialLayersList
      .filter(curLayerId => curLayerId !== `layer${layerId}`);
  },
  [CLEAR_MAP_LAYERS](state) {
    state.spatialLayersList = [];
    state.layers = {};
  },
  [SET_LAYER_DATA](state, layerData) {
    const key = layerData.info.id.toString();
    const info = { ...layerData.info, id: key };
    Vue.set(state.layers, key, { ...layerData, info });
  },
  [DESELECT_LAYER](state, layerData) {
    const key = layerData.info.id.toString();
    state.layers[key] = { ...state.layers[key], selected: 0 };
  },
};

const getters = {
  ...spatialGetters,
  baseUrl: (state) => '/spatial',
  //layers: (state) => state.layers
  // layers({ layers, layersList }) {
  //   // return map(layersList, id => layer[keyForLayer(id)])
  // },
};

const actions = {
  ...spatialActions,
  fetchLayer({ commit, state, dispatch }, layerId) {
    commit(ADD_LOADING_LAYER, layerId);

    return dispatch('getSpatialLayer', { id: layerId, key: 'map'})

    const args = state.useMapBoundary
          ? { boundary: state.mapBoundary }
          : {}

    if (!state.spatialLayersList.includes(layerId)) {
      return fetch(`layer/geojson/${layerId}`, args)
        .then((res) => {
          commit(ADD_LAYER, res);
          commit(REMOVE_LOADING_LAYER, layerId);
          return res;
        }).catch( err => {
          commit(REMOVE_LOADING_LAYER, layerId);
        });
    }
  },
  setupLayerList(store, { layers, boundary }) {
    store.commit(SET_MAP_BOUNDARY, boundary);
    if (layers.length) {
      layers.forEach((id) => {
        store.dispatch('fetchLayer', id.toString())
          .then((resp) => {
            if (resp && resp.message) {
              console.log('error', resp.message); // send to message tray?
            }
          });
      });
    }
  },
  toggleLayer(store, layerId) {
    if (!store.state.spatialLayersList.includes(layerId)) {
      store.dispatch('fetchLayer', layerId);
    } else {
      store.dispatch('removeLayer', layerId);
      // this.removeLayer(id);
    }
  },
  getRecord({ dispatch, getters}, { collection, id }){
    // just passing through to the layer
    return dispatch(`${collection}/getRecord`, { id }, { root: true });
  },
  updateRecord({ dispatch, getters}, { collection, id, data }){
    // just passing through to the layer
    return dispatch(`${collection}/updateRecord`, { id, data }, { root: true });
  },
  addRecord({ dispatch, getters}, { collection, data }){
    // just passing through to the layer
    return dispatch(`${collection}/addRecord`, { data }, { root: true });
  },
  removeRecord({ dispatch, getters}, { collection, data }){
    // just passing through to the layer
    return dispatch(`${collection}/removeRecord`, { data }, { root: true });
  },
  removeLayer(store, layerId) {
    // timeout to provide consistency across big/small-sized layers
    setTimeout(() => {
      store.commit(REMOVE_LAYER, layerId);
      //store.commit(REMOVE_LOADING_LAYER, layerId);
    }, 150);
  },
  getAvailableLayers(store) {
   fetch('layers/list').then((res) => {
      store.commit(LAYERS_AVAILABLE, res || []);
    });
  },
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
