import Vue from 'vue';
import Vuex from 'vuex';

import collection from '@acorns-ui/Stores/Collections/Mixins/collection';

import monitoringElements from '@acorns-ui/Stores/Collections/Elements';
import deliverables from '@acorns-ui/Stores/Collections/Deliverables';
import documents from '@acorns-ui/Stores/Collections/Documents';
import cameraPoints from '@acorns-ui/Stores/Collections/CameraPoints';
import photoPoints from '@acorns-ui/Stores/Collections/PhotoPoints';
import tasks from '@acorns-ui/Stores/Collections/Tasks';
import issues from '@acorns-ui/Stores/Collections/Issues';
import contacts from '@acorns-ui/Stores/Collections/Contacts';
import properties from '@acorns-ui/Stores/Collections/Properties';
import notes from '@acorns-ui/Stores/Collections/Notes';
import parcels from '@acorns-ui/Stores/Collections/Parcels';
import patches from '@acorns-ui/Stores/Collections/Patches';
import communications from '@acorns-ui/Stores/Collections/Communications';
import user from '@acorns-ui/Stores/User';
import files from '@acorns-ui/Stores/Collections/Files';
import events from '@acorns-ui/Stores/Collections/Events';
import projects from '@acorns-ui/Stores/Collections/Projects';

import PageMessages from '@acorns-ui/Stores/PageMessages';
import PageModals from '@acorns-ui/Stores/PageModals';
import navigation from '@acorns-ui/Stores/Navigation';
import Global from '@acorns-ui/Stores/Global';
import board from '@acorns-ui/Stores/Boards';
import Library from '@acorns-ui/Stores/Library';
import Tools from '@acorns-ui/Stores/Tools';
import Location from '@acorns-ui/Stores/Location';


import {
  actions as GetCollectionActions,
} from '@acorns-ui/Stores/Collections/Mixins/GetCollectionsMixin';

import {
  actions as GetObjectActions,
} from '@acorns-ui/Stores/Objects/Mixins/GetObjectsMixin';

import {
  actions as GetReportActions,
} from '@acorns-ui/Stores/Reports/Mixins/GetReportsMixin';

import {
  actions as GetSearchActions,
} from '@acorns-ui/Stores/Search/Mixins/GetSearchMixin';

import {
  actions as GetDatasetActions,
} from '@acorns-ui/Stores/Datasets/Mixins/GetDatasetsMixin';

import * as actions from './actions';
import { getDataset, ADD_DATASET } from './modules/datasetMixins';
import { initialState, mutations } from './mutations';
import getters from './getters';

import history from './modules/history';
import elementObservations from './modules/elementObservations';
//import tools from './modules/tools';
import taskDeliverables from './modules/taskDeliverables';
import propertyStatuses from './modules/propertyStatuses';
import accounts from './modules/accounts';
import checklists from './modules/checklists';
import stands from './modules/stands';
import map from './modules/map';
import feed from './modules/feed';


Global.state.automaticallySaveToBackend = true;

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: initialState,
  actions: {
    ...actions,
    ...GetCollectionActions,
    ...GetReportActions,
    ...GetObjectActions,
    ...GetDatasetActions,
    ...GetSearchActions,
  },
  mutations: {
    ...mutations,
    ADD_DATASET,
  },
  getters,
  modules: {
    Library,
    Tools,
    // not quite ready to add this
    // fields: collection({
    //   uniqueId: 'field_id',
    //   collection: 'fields',
    // }),
    objectFields: collection({
      uniqueId: 'object_field_id',
      collection: 'objectFields',
      form: 'ObjectFieldForm',
    }),
    objectFilters: collection({
      uniqueId: 'object_filter_id',
      collection: 'objectFilters',
      form: 'FilterForm',
    }),
    Global,
    board,
    layers: collection({
      uniqueId: 'layer_id',
      collection: 'layers',
      form: 'LayerForm',
    }),
    features: collection({
      uniqueId: 'feature_id',
      record: 'feature',
      collection: 'features',
      form: 'FeatureForm',
    }),
    roles: collection({
      uniqueId: 'role_id',
      collection: 'roles',
      form: 'RoleForm',
    }),
    patches,
    monitoringElements,
    elementObservations,
    cameraPoints,
    stands,
    photoPoints,
    photoPointImages: collection({
      uniqueId: 'photo_point_image_id',
      record: 'photopointimage',
      collection: 'photopointimages',
    }),
    accounts,
    sources: collection({
      uniqueId: 'source_id',
      collection: 'sources',
      form: 'SourceForm',
    }),
    payments: collection({
      uniqueId: 'tax_payment_id',
      collection: 'payments',
      form: 'TaxPaymentForm',
    }),
    transactions: collection({
      uniqueId: 'transaction_id',
      record: 'transactions',
      collection: 'transactions',
      tables: [
        {
          uid: 'types',
          label: 'Types',
          collection: 'transactionTypes',
        },
      ],
    }),
    control: collection({
      uniqueId: 'control_event_id',
      label: 'Vegetation Control Events',
      labelSingular: 'Vegetation Control Event',
      record: 'controlevent',
      collection: 'control',
      form: 'ControlEventForm',
      viewAs: 'rows',
      viewAsOptions: [
        { label: 'Rows', uid: 'rows' },
      ],
      tables: [
        {
          uid: 'methods',
          label: 'Methods',
          collection: 'applicationMethods',
          uniqueId: 'application_method_id',
        },
        {
          uid: 'products',
          label: 'Products',
          collection: 'controlProducts',
          uniqueId: 'control_product_id',
        },
        {
          uid: 'reasons',
          label: 'Reasons',
          collection: 'controlReasons',
          uniqueId: 'control_reason_id',
        },
        {
          uid: 'taxa',
          label: 'Target taxa/species',
          collection: 'taxa',
          uniqueId: 'taxa_id',
        },
      ],
    }),
    observations: collection({
      uniqueId: 'observation_id',
      collection: 'observations',
      form: 'ObservationForm',
    }),
    taxa: collection({
      uniqueId: 'taxon_id',
      collection: 'taxa',
    }),
    visits: collection({
      uniqueId: 'visit_id',
      collection: 'visits',
      form: 'VisitForm',
      defaultSortMethod: {
        day: 'desc',
      },
    }),
    parcels,
    history,
    tasks,
    issues,
    //tools,
    files,
    documents,
    contacts,
    communications,
    notes,
    templates: collection({
      uniqueId: 'template_id',
      collection: 'templates',
    }),
    hours: collection({
      uniqueId: 'hour_id',
      collection: 'hours',
      form: 'HoursForm',
      viewAsOptions: [
        { label: 'Rows', uid: 'rows' },
      ],
      defaultSortMethod: {
        work_date: 'desc',
      },
      batchActions: [
        { uid: 'delete', label: 'Delete records' },
        { uid: 'edit', label: 'Edit records' },
      ],
      batchEditableFields: [
        {
          label: 'Work date',
          name: 'work_date',
          type: 'date-input',
        },
        {
          label: 'Role',
          name: 'role_id',
          type: 'nested-input',
          url: 'roles/nested',
          allowSearch: true,
        },
        {
          label: 'Hours',
          name: 'hours',
          type: 'number-input',
        },
        {
          label: 'Rate',
          name: 'rate',
          type: 'number-input',
        },
      ],
    }),
    taskDeliverables,
    propertyStatuses,
    events,
    projects,
    regions: collection({
      uniqueId: 'region_id',
      record: 'region',
      collection: 'regions',
      tables: [
        {
          uid: 'regionslevels',
          label: 'Region Levels',
          collection: 'regionlevels',
          uniqueId: 'region_level_id',
        },
      ],
    }),
    properties,
    resolutions: collection({
      uniqueId: 'resolution_id',
      record: 'resolution',
      collection: 'resolutions',
    }),
    restrictions: collection({
      uniqueId: 'restriction_id',
      record: 'restriction',
      collection: 'restrictions',
      form: 'RestrictionForm',
      tables: [
        {
          uid: 'sourcetypes',
          label: 'Source types',
          collection: 'restrictionsourcetypes',
          uniqueId: 'source_type_id',
        },
        {
          uid: 'uses',
          label: 'Uses',
          collection: 'uses',
          uniqueId: 'use_id',
        },
      ],
    }),
    approvals: collection({
      uniqueId: 'approval_id',
      record: 'approval',
      collection: 'approvals',
      form: 'ApprovalForm',
    }),
    checklists,
    deliverables,
    user,
    feed,
    map,
    PageMessages,
    PageModals,
    navigation,
    Location,
  },
});
