import { fetch, post } from '@/api';
//import datasetModule from '@/store/modules/dataset';
import datasetModule from '@acorns-ui/Stores/Datasets';

//export const ADD_DATA_CHANNEL = 'ADD_DATA_CHANNEL';
//export const REMOVE_DATA_CHANNEL = 'REMOVE_DATA_CHANNEL';

export const state = {
  datasetsList: [],
  filter: {}
};

export const datasets = (state, getters, rootState, rootGetters) => {
  const lyrs = state.datasetsList;
  let key, data = {};
  return data;
};

export const ADD_DATASET = (state, value) => {
  //state.channelsList.push(value);
  //state.datasetsList.push(value)
};

export const SET_FILTER = (state, filter) => {
  state.filter = filter;
};

export const CLEAR_FILTER = (state) => {
  state.filter = {};
};

export const REMOVE_DATASET = (state, value) => {

};


export function getDataset({ dispatch, state, commit }, { id, filter }){
  //console.log("GETTING DATASET", id);
  const moduleName = `dataset${id}`;
  if(!this._modulesNamespaceMap[`${moduleName}/`]) {
    //console.log("BUILDING DATASET", id);
    this.registerModule(moduleName, datasetModule);
  }
  commit('ADD_DATASET', moduleName);
  dispatch(`${moduleName}/setup`, { id, filter }, { root: true });
};
