//import BasicLayout from '../components/Layouts/Basic';
import MapPage from '@/views/MapPage';
import MapWorkspace from '@acorns-ui/Workspaces/MapWorkspace';
import PreviewRoutes from '@acorns-ui/Workspaces/MapWorkspace/MapRoutes';

const MapWorkspaceRoute = {
  path: '',
  components: {
    workspace: MapWorkspace
  },
  meta: {
    breadcrumbs: {
      source: 'page',
      data: { title:  'spatial' }
    },
  },
  props: { workspace: true },
  children: PreviewRoutes.children,
};

const route = {
  path: '/spatial',
  components: { page: MapPage },
  props: { page: true },
  meta: {
    authAreaName: 'spatial',
    breadcrumbs: {
      title: 'Maps',
      source: 'page',
    },
  },
  children: [
    MapWorkspaceRoute,
  ],
};

export default route;
