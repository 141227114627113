<template>
<div class="map-sidebar">


  <PanelMenu
     label=""
     :collapsible="false"
     :open="true">
    <RouteMenuItem
       label="Dashboard"
       icon = "dashboard"
       description="Map overview"
       :exact="true"
       :route="getRoute('')"/>
  </PanelMenu>


</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from '@/utils'
import RouteMenuItem from '@acorns-ui/Menus/RouteMenuItem';
//import AddressSearch from './AddressSearch';
import PanelMenu from '@acorns-ui/Menus/PanelMenu';

export default {
  name: 'MapSidebar',
  props: { },
  components: {
    PanelMenu,
    RouteMenuItem,
  //  AddressSearch,
  },
  computed: {
    store(){
      return `map${this.id}`;
    },
  },
  methods: {
    getRoute(path) {
      const route = path
            ? `/maps/${this.id}/${path}`
            : `/maps/${this.id}`;
      return route;
    },
  },
};
</script>

<style lang="scss">

.map-sidebar {
  .menu-item {
    color: #5d5d5d;
  }
}

</style>
