import { fetch, post } from '@/api';
import layerModule from '@/store/modules/layer';

//export const ADD_SPATIAL_LAYER = 'ADD_SPATIAL_LAYER';
//export const REMOVE_SPATIAL_LAYER = 'REMOVE_SPATIAL_LAYER';

export const spatialState = {
  spatialLayersList: [],
};



export const spatialLayers = (state, getters, rootState, rootGetters) => {
  const lyrs = state.spatialLayersList;
  let key, layerData = {};
  for(let i in lyrs){
    key = state.spatialLayersList[i]
    layerData[key] = rootGetters[`${key}/featureCollection`]
  }
  return layerData;
};

export const ADD_SPATIAL_LAYER = (state, value) => {
  state.spatialLayersList.push(value)
};

export const REMOVE_SPATIAL_LAYER = (state, value) => {
  state.spatialLayersList = state.spatialLayersList.filter( d => d!==value)
};


export function getObjectSpatialLayer({ dispatch, state, commit }, { object, id, key }){
  // id is the object id
  const moduleName = `${object}features`;
  console.log('map object', this._modulesNamespaceMap)
  if(!this._modulesNamespaceMap[`${moduleName}/`]) {
    this.registerModule(moduleName, layerModule);
    this.commit('REGISTER_OBJECT', { type: 'object-layer', id: moduleName, key }, { root: true })
  }
  // set the boundary for the store and get data
  dispatch(`${moduleName}/getObjectData`, { object, id }, { root: true });
  // register the layer to this store
  commit('ADD_SPATIAL_LAYER', moduleName);
};

export function getSpatialLayer({ dispatch, state, commit }, { id, key }){
  const moduleName = `layer${id}`;
  console.log('map', this._modulesNamespaceMap)
  if(!this._modulesNamespaceMap[`${moduleName}/`]) {
    this.registerModule(moduleName, layerModule);
    this.commit('REGISTER_OBJECT', { type: 'layers', id, key }, { root: true })
  }
  // set the boundary for the store and get data
  dispatch(`${moduleName}/getData`, { id, boundary: state.boundary || [] }, { root: true })
    .then( res => {
      // dont add the layer until its been loaded
      // console.log('Layer as been loaded', moduleName)
      // register the layer to this store
      commit('ADD_SPATIAL_LAYER', moduleName);
    })
};
