<template>
<FormLayout
  class="monitoring-element-form"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  :tabs="tabs"
  :tab.sync="tab"
  @close="closeHandler">

  <div slot="header" class="header-label row">
    <div v-if="parent" class="parent-label">
      {{ parentLabel }} -&nbsp
    </div>
    <div>{{ actionLabel }} Monitoring Element</div>
  </div>

  <Tag
    slot="actions"
    :theme="tab != 'import' ? 'lighten' : 'darken'"
    @clicked="tab = (tab == 'import') ? 'general' : 'import'">
    Import
  </Tag>


  <template
    slot="general">

    <LabeledInput
      v-if="!restriction"
      label="Restriction"
      details="The restriction that this element is part of"
      :status="status.restriction_id">
      <DropdownInput
        v-model="record.restriction_id"
        :method="`properties/${parentId}/restrictions?f=lookup`"
        :required="true"
        new-placeholder="Add a new restriction"
        :allow-new="true"
        new-form="RestrictionForm"
        :new-form-params="{ parent, parentId }"
        @status-changed="updateStatus('restriction_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Observation type"
      details="The type of value that is recorded with each observation"
      :status="status.monitoring_type_id">
      <DropdownInput
        v-model="record.monitoring_type_id"
        method="monitoringtypes/lookup"
        :required="true"
        :fields="['description']"
        :allow-search="false"
        @status-changed="updateStatus('monitoring_type_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Label"
      details="Label used when referencing this element"
      :status="status.label">
      <TextInput
        v-model="record.label"
        :required="true"
        @status-changed="updateStatus('label', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Description"
      :fill="true"
      details="More detailed description of what to look for when making observations for this element"
      :status="status.description">
      <TextareaInput
        v-model="record.description"
        :required="false"
        @status-changed="updateStatus('description', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Question"
      details="The question that will be used in the observation form"
      :status="status.question">
      <TextInput
        v-model="record.question"
        :required="true"
        @status-changed="updateStatus('question', $event)"/>
    </LabeledInput>

  </template>


  <template
    slot="config">

    <!-- <LabeledInput -->
    <!--   :fill="true" -->
    <!--   label="Custom configuration"> -->
    <!--   <ArrayInput -->
    <!--     v-model="record.config" -->
    <!--     :required="false"/> -->
    <!-- </LabeledInput> -->

    <LabeledInput
      label="Add options"
      details="For this type you will need to add any categories that you want to use. You can move the list items to reorder them.">
      <div class="row">
        <TextInput
          class="fill"
          v-model="optionLabel"
          :required="false"/>
        <Button
          @click="addOption">
          Add
        </Button>
      </div>
    </LabeledInput>

    <template
      v-if="record.config && record.config.options">
      <div class="padded italics">
        Use the `x` icon to remove an option from the list
      </div>
      <div
        v-for="(o, i) in record.config.options"
        :key="o.uid"
        :draggable="record.config.options.length>1"
        @dragover="onDragOver($event, i)"
        @dragstart="onDragStart($event, i)"
        @drop.stop="onDrop($event, i)"
        class="config-option-item">
        <IconLink
          class="small"
          @clicked="removeOption(i)">
          close
        </IconLink>
        <div class="label">
          {{ o.label }} (#{{o.uid}})
        </div>
      </div>
    </template>

  </template>

  <template
    slot="timing">

    <LabeledInput
      label="Active"
      details="Are we still making observations for this element?"
      :status="status.is_active">
      <DropdownInput
        v-model="record.is_active"
        :options="[{uid:'f', label:'Retired'},{uid:'t', label:'Active'}]"/>
    </LabeledInput>

    <LabeledInput
      label="Required"
      details="Are observations for this element required or optional?"
      :status="status.required">
      <DropdownInput
        v-model="record.required"
        :required="false"
        :options="[{uid:'f', label:'Optional'},{uid:'t', label:'Required'}]"/>
    </LabeledInput>

    <LabeledInput
      label="Required each visit"
      details="Should an observation be made each time you are on site?"
      :status="status.required_each_visit">
      <DropdownInput
        v-model="record.required_each_visit"
        :required="false"
        :options="[{uid:'f', label:'No'},{uid:'t', label:'Yes'}]"/>
    </LabeledInput>

    <LabeledInput
      v-if="record.required_each_visit=='f'"
      label="Max observation interval"
      details="About how often should this be observed. Values should be in the format of a property interval. For example `1year`, `1month`, `2years` etc."
      :status="status.observation_interval_max">
      <TextInput
        v-model="record.observation_interval_max"
        :required="true"
        @status-changed="updateStatus('observation_interval_max', $event)"/>
    </LabeledInput>


  </template>

  <div
    class="padded column"
    slot="import">

    <LabeledInput
      label="Property"
      details="Select the property you wish to import from">
      <DropdownInput
        method="properties/lookup"
        :allow-search="true"
        @updated="importPropertyElements"/>
    </LabeledInput>

    <div class="examples-container">
      <a
        v-for="e in examples"
        :key="e.monitoring_element_id"
        @click="importExample(e)">
        <div
          class="example-item">
          <div class="header">
            {{ e.label }}
          </div>
          <div class="header">
            {{ e.question }}
          </div>
          <div class="smaller italics">
            {{ e.description }}
          </div>
        </div>
      </a>
    </div>

  </div>

  <Button
    slot="buttons"
    :disabled="!id"
    type="delete"
    @click="removeRecord">
    delete
  </Button>

  <Button
    slot="buttons"
    type="save"
    @click="saveAndClose">
    save
  </Button>

</FormLayout>
</template>

<script>
  import { fetch } from '@/api';

import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import IconLink from '@acorns-ui/Icons/IconLink';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput'
import DropdownInput from '@acorns-ui/Inputs/DropdownInput'
import TextareaInput from '@acorns-ui/Inputs/TextareaInput'
import TextInput from '@acorns-ui/Inputs/TextInput'
import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
import ChecklistInput from '@acorns-ui/Inputs/ChecklistInput';
import ArrayInput from '@acorns-ui/Inputs/ArrayInput';
import Button from '@acorns-ui/Inputs/Button';
import Tag from '@acorns-ui/Text/Tag';

export default {
  name: 'MonitoringElementForm',
  mixins: [
    CollectionFormMixin,
    FormValidationMixins,
  ],
  props: {
    collection: {
      default: 'monitoringElements'
    },
    restriction: {
      type: [String, Number],
    },
    notIn: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      tab: 'general', //'general',
      showExamples: false,
      examples: [],
      optionLabel: null,
      record: {
        label: null,
        description: null,
        question: null,
        monitoring_type_id: null,
        restriction_id: null,
        config: null,
        // {
        //   options: [
        //     { uid: 0, label: 'zero' },
        //     { uid: 1, label: 'one' },
        //     { uid: 2, label: 'two' },
        //   ],
        // },
        is_active: 't',
        required_each_visit: 'f',
        required: 't',
        observation_interval_max: '1year',
      }
    };
  },
  components: {
    LabeledInput,
    DropdownInput,
    TextareaInput,
    TextInput,
    FormLayout,
    IconLink,
    Button,
    CheckboxInput,
    ChecklistInput,
    ArrayInput,
    Tag,
  },
  created() {
    if(this.restriction) {
      this.record.restriction_id = this.restriction;
    }
  },
  computed: {
    tabs() {
      var tabs = [
        { uid: 'general', label: 'General' },
        { uid: 'timing', label: 'Timing' },
      //  { uid: 'config', label: 'Configure' },
      ];

      if([2, 3].includes(this.monitoringTypeId)) {
        tabs.push({ uid: 'config', label: 'Options' });
      }

      tabs.push({ uid: 'import', label: 'Import', hide: true});

      return tabs;
    },
    monitoringTypeId() {
      var id = this.record.monitoring_type_id;
      return parseInt(id);
    }
  },
  methods: {
    importPropertyElements(id) {
      fetch(`properties/${id}/elements?f=list`)
        .then( list => {
          this.examples = list;
        })
    },
    importExample(ex) {
      console.log('import', ex);
      this.record.label = ex.label;
      this.record.description = ex.description;
      this.record.config = ex.config;
      this.record.monitoring_type_id = ex.monitoring_type_id;
      this.record.required = ex.required
      this.record.required_each_visit = ex.required_each_visit;
      this.record.question = ex.question;
      this.record.observation_interval_max = ex.observation_interval_max;
      this.tab = 'general';
    },
    addOption() {
      var config = this.record.config || {};
      if(!config.options) {
        config.options = [];
      }
      var uid = config.options.length;
      var label = this.optionLabel;
      config.options.push({
        uid,
        label,
      });
      console.log('add option', label);

      this.record.config = config;
      this.optionLabel = null;

    },
    removeOption(i) {
      var config = this.record.config;
      if(config.options) {
        this.record.config.options.splice(i, 1);
        this.renumberOptions();
      }
    },
    onDragOver(e, i) {
      e.stopPropagation();
      e.preventDefault();
      //console.log('over', i);
    },
    onDrop(e, to) {
      e.stopPropagation();
      e.preventDefault();
      var from = e.dataTransfer.getData('index');
      // copy the options
      var items = this.record.config.options.splice(from,1);
      this.record.config.options.splice(to, 0, items[0]);
      this.renumberOptions();
    },
    onDragStart(e, i){
      e.dataTransfer.setData('action', 'reorder')
      e.dataTransfer.setData('index', i)
      //e.dataTransfer.setData('uid', this.uid)
    },
    renumberOptions() {
      var options = this.record.config.options.map( (d, i) => {
        return { uid: i, label: d.label };
      })
      this.record.config.options = options;
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .monitoring-element-form {
    @include formsize;
  }
}

.monitoring-element-form  {

  .description-input {
    flex: 1;
  }
  .element-examples-input {
    flex: 1;
  }

  .config-option-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    border-bottom: $border-style;
    cursor: pointer;

    .label {
      padding-left: 0.5rem;
    }
    &:hover {
      background-color: $hover-bkgd;
    }
  }

  .examples-container {
    overflow: auto;

    .example-item {
      cursor: pointer;
      display: block;
      &:hover {
        background-color: $hover-bkgd;
      }
      padding: 0.5rem;
      .property-label {
        font-weight: 600;
      }
    }
  }
}
</style>
