import * as types from './mutation-types';

export const initialState = {
  propertyLabelSingular: 'property',
  propertyLabelPlural: 'properties',
  propertyLabelSlug: 'properties',
  regionLabelSlug: 'regions',
  regionInfo: {
    1 : { label: "Ecoregions", lead: "Lead" },
    2 : { label: "Stewardship Areas", lead: "Natural Area Manager" },
  },
  organizationId: 40,
  appLabel: 'ouzel',
  serviceArea: null,
  serviceBoundary: null,
  routesAdded: false,
  breadcrumbs: [],
  pageTitle: null,//'Page-Title',
  workspaceTitle: 'Workspace-Title',
  loggedIn: false,
  navOpen: false,
  pageNavOpen: true,
  userNavOpen: false,
  userRoutesAllowed: [],
  activeUserTab: 'tasks',
  activeUserPanel: null,
  selectedNavItem: '',
  navFolderOpen: '',
  navMenu: [],
  modalOpen: false,
  modalComponent: null,
  modalComponentParams: {},
  modalLabel: null,
  modalId: 0,
  modalSize: '',
  apiCalls: [],
  registeredObjects: {},
  registeredTemplates: {},
  registeredProperties: {},
  registeredProjects: {},
  registeredIssues: {},
  registeredRoles: {},
  registeredContacts: {},
  registeredRegions: {},
  registeredCollections: {},
  modalIncludeToolbar: true,
  workFormOpen: false,
  workFormParams: {},
  messageFormOpen: false,
  messageFormParams: {},
  contactFormOpen: false,
  contactFormParams: {},
  issueFormOpen: false,
  issueFormParams: {},
  toolFormOpen: false,
  toolFormParams: {},
  deliverableFormOpen: false,
  deliverableFormParams: {},
  taskFormOpen: false,
  taskFormParams: {},
  fileFormOpen: false,
  fileFormParams: {},
  fileReviewOpen: false,
  fileReviewParams: {},
  noteFormOpen: false,
  noteFormParams: {},
  documentFormOpen: false,
  documentFormParams: {},
  observationFormOpen: false,
  observationFormParams: {},
  restrictionFormOpen: false,
  restrictionFormParams: {},
  approvalFormOpen: false,
  approvalFormParams: {},
  projectFormOpen: false,
  projectFormParams: {},
  propertyFormOpen: false,
  propertyFormParams: {},
  linkFormOpen: false,
  linkFormParams: {},
  libraryFormOpen: false,
  libraryFormParams: {},
  collectionFormOpen: false,
  collectionFormParams: {},
  layerFormOpen: false,
  layerFormParams: {},
  datasetFormOpen: false,
  datasetFormParams: {},
  accountFormOpen: false,
  accountFormParams: {},
  taskReportOpen: false,
  taskReportParams: {},
  workPerformedFormOpen: false,
  workPerformedFormParams: {},
  componentHelpData: null,
};

export const mutations = {
  [types.LOGGED_IN](state, value) {
    state.loggedIn = value;
  },
  [types.LOG_OUT](state) {
    localStorage.removeItem('acornsToken');
    state.loggedIn = false;
  },
  [types.ROUTES_ADDED](state) {
    state.routesAdded = true;
  },
  [types.UPDATE_BREADCRUMBS](state, newBreadcrumbs) {
    state.breadcrumbs = newBreadcrumbs;
  },
  SET_PAGE_BREADCRUMBS(state, breadcrumbs) {
    if(!breadcrumbs.length) return;
    const workspace = state.breadcrumbs.filter( d => d.source !== 'page')||[];
    state.breadcrumbs = [ ...breadcrumbs, ...workspace ];
  },
  SET_WORKSPACE_BREADCRUMBS(state, breadcrumbs) {
    if(!breadcrumbs.length) return;
    const page = state.breadcrumbs.filter( d => d.source !== 'workspace')||[];
    state.breadcrumbs = [ ...page, ...breadcrumbs ];
  },
  SET_BREADCRUMBS(state, breadcrumbs) {
    if(breadcrumbs.length) state.breadcrumbs = breadcrumbs;
  },
  CLEAR_BREADCRUMBS(state) {
    state.breadcrumbs = [];
  },
  [types.SET_WORKSPACE_TITLE](state, value) {
    state.workspaceTitle = value;
  },
  SET_PAGE_TITLE(state, value) {
    state.pageTitle = value;
    document.title = value;
  },
  [types.APPEND_BREADCRUMBS](state, newBreadcrumbs) {
    state.breadcrumbs = [...state.breadcrumbs, ...newBreadcrumbs];
  },
  [types.SET_COMPONENT_HELP](state, data) {
    state.componentHelpData = data;
  },
  [types.TOGGLE_NAV](state) {
    if (state.navOpen) {
      state.navFolderOpen = '';
    }
    state.navOpen = !state.navOpen;
  },
  TOGGLE_PAGE_NAV(state, value) {
    if(typeof(value) === 'undefined'){
      state.pageNavOpen = !state.pageNavOpen;
    } else {
      state.pageNavOpen = value;
    }
  },
  [types.TOGGLE_USER_NAV](state) {
    state.userNavOpen = !state.userNavOpen;
  },
  [types.OPEN_USER_NAV](state) {
    state.userNavOpen = true;
  },
  [types.CLOSE_USER_NAV](state) {
    state.userNavOpen = false;
  },
  [types.ACTIVE_USER_TAB](state, tabName) {
    state.activeUserTab = tabName;
  },
  [types.ACTIVE_USER_PANEL](state, panel) {
    state.activeUserPanel = panel;
  },
  [types.RESET_NAVS](state) {
    state.userNavOpen = false;
    state.navOpen = false;
  },
  [types.GET_MENU](state, { menu }) {
    state.navMenu = { ...menu };
  },
  [types.LOG_API_CALL](state, { url, args, type }) {
    const datetime = Date.now();
    state.apiCalls.push({ datetime, type, url, args })
  },
  [types.REGISTER_TEMPLATE](state, { id, moduleName, key }) {
    Vue.set(state.registeredTemplates, id, moduleName)
  },
  [types.REGISTER_PROPERTY](state, { id, key }) {
    if(!state.registeredProperties[id]){
      Vue.set(state.registeredProperties, id, [key])
    } else if(!state.registeredProperties[id].includes(key)) {
      state.registeredProperties[id].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_ISSUE](state, { id, key }) {
    if(!state.registeredIssues[id]){
      Vue.set(state.registeredIssues, id, [key])
    } else if(!state.registeredIssues[id].includes(key)) {
      state.registeredIssues[id].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_REGION](state, { id, key }) {
    if(!state.registeredRegions[id]){
      Vue.set(state.registeredRegions, id, [key])
    } else if(!state.registeredRegions[id].includes(key)) {
      state.registeredRegions[id].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_ROLE](state, { id, key }) {
    if(!state.registeredRoles[id]){
      Vue.set(state.registeredRoles, id, [key])
    } else if(!state.registeredRoles[id].includes(key)) {
      state.registeredRoles[id].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_CONTACT](state, { id, key }) {
    if(!state.registeredContacts[id]){
      Vue.set(state.registeredContacts, id, [key])
    } else if(!state.registeredContacts[id].includes(key)) {
      state.registeredContacts[id].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_COLLECTION](state, { collection, key }) {
    if(!state.registeredCollections[collection]){
      Vue.set(state.registeredCollections, collection, [key])
    } else if(!state.registeredCollections[collection].includes(key)) {
      state.registeredCollections[collection].push(key)
    } // else it must already be registered
  },
  [types.REGISTER_PROJECT](state, { id, key }) {
    if(!state.registeredProjects[id]){
      Vue.set(state.registeredProjects, id, [key])
    } else if(!state.registeredProjects[id].includes(key)) {
      Vue.set(state.registeredProjects[id], key)
    } // else it must already be registered
  },
  [types.REGISTER_OBJECT](state, { type, id, key }) {
    const uid = id.toString();
    if(!state.registeredObjects[type]){
      Vue.set(state.registeredObjects, type, {})
    }
    if(!state.registeredObjects[type][uid]){
      Vue.set(state.registeredObjects[type], uid, [key])
    } else if(!state.registeredObjects[type][uid].includes(key)) {
      state.registeredObjects[type][uid].push(key)
    }
  },
  [types.UNREGISTER_OBJECT](state, { type, id, key }) {
    const uid = id.toString();
    // double check that we actually have the type
    if(state.registeredObjects[type] && state.registeredObjects[type][uid]){
      // find the key in the object array
      const idx = state.registeredObjects[type][uid].findIndex( d => d === key)
      if(idx>=0){
        // if we found it we need to remove it
        state.registeredObjects[type][uid].splice(idx,1)
        // and then we check to see if that was the last one and then remove
        if(state.registeredObjects[type][uid].length === 0){
          delete state.registeredObjects[type][uid]
        }
        //console.log('looking for key',type, uid, key, idx, state.registeredObjects[type])
      }
    }
  },
  [types.GET_ORG_CONFIG](state, config) {
    state.propertyLabelSingular = config.propertyLabelSingular;
    state.propertyLabelPlural = config.propertyLabelPlural;
    state.propertyLabelSlug = config.propertyLabelSlug;
    state.regionLabelSlug = config.regionLabelSlug;
    if(config.organization) state.organizationId = config.organization;
    state.serviceArea = config.serviceArea;
    state.serviceBoundary = config.serviceBoundary;
    if (config.appLabel) state.appLabel = config.appLabel;
  },
  [types.SELECT_NAV_ITEM](state, navItem) {
    state.selectedNavItem = navItem;
  },
  [types.OPEN_NAV_FOLDER](state, navFolder) {
    state.navFolderOpen = navFolder;
  },
  [types.SHOW_WORK_FORM](state, params) {
    state.workFormParams = params;
    state.workFormOpen = true;
  },
  [types.CLOSE_WORK_FORM](state) {
    state.workFormParams = {};
    state.workFormOpen = false;
  },
  [types.SHOW_MESSAGE_FORM](state, params) {
    state.messageFormParams = params;
    state.messageFormOpen = true;
  },
  [types.CLOSE_MESSAGE_FORM](state) {
    state.messageFormParams = {};
    state.messageFormOpen = false;
  },
  [types.SHOW_CONTACT_FORM](state, params) {
    state.contactFormParams = params;
    state.contactFormOpen = true;
  },
  [types.CLOSE_CONTACT_FORM](state) {
    state.contactFormParams = {};
    state.contactFormOpen = false;
  },
  [types.SHOW_ISSUE_FORM](state, params) {
    state.issueFormParams = params;
    state.issueFormOpen = true;
  },
  [types.CLOSE_ISSUE_FORM](state) {
    state.issueFormParams = {};
    state.issueFormOpen = false;
  },
  [types.SHOW_WORK_PERFORMED_FORM](state, params) {
    state.workPerformedFormParams = params;
    state.workPerformedFormOpen = true;
  },
  [types.CLOSE_WORK_PERFORMED_FORM](state) {
    state.workPerformedFormParams = {};
    state.workPerformedFormOpen = false;
  },
  [types.SHOW_TOOL_FORM](state, params) {
    state.toolFormParams = params;
    state.toolFormOpen = true;
  },
  [types.CLOSE_TOOL_FORM](state) {
    state.toolFormParams = {};
    state.toolFormOpen = false;
  },
  [types.SHOW_DELIVERABLE_FORM](state, params) {
    state.deliverableFormParams = params;
    state.deliverableFormOpen = true;
  },
  [types.CLOSE_DELIVERABLE_FORM](state) {
    state.deliverableFormParams = {};
    state.deliverableFormOpen = false;
  },
  [types.SHOW_TASK_FORM](state, params) {
    state.taskFormParams = params;
    state.taskFormOpen = true;
  },
  [types.CLOSE_TASK_FORM](state) {
    state.taskFormParams = {};
    state.taskFormOpen = false;
  },
  [types.SHOW_NOTE_FORM](state, params) {
    state.noteFormParams = params;
    state.noteFormOpen = true;
  },
  [types.CLOSE_NOTE_FORM](state) {
    state.noteFormParams = {};
    state.noteFormOpen = false;
  },
  [types.SHOW_DOCUMENT_FORM](state, params) {
    state.documentFormParams = params;
    state.documentFormOpen = true;
  },
  [types.CLOSE_DOCUMENT_FORM](state) {
    state.documentFormParams = {};
    state.documentFormOpen = false;
  },
  [types.SHOW_FILE_FORM](state, params) {
    state.fileFormParams = params;
    state.fileFormOpen = true;
  },
  [types.CLOSE_FILE_FORM](state) {
    state.fileFormParams = {};
    state.fileFormOpen = false;
  },
  [types.SHOW_FILE_REVIEW](state, params) {
    state.fileReviewParams = params;
    state.fileReviewOpen = true;
  },
  [types.CLOSE_FILE_REVIEW](state) {
    state.fileReviewParams = {};
    state.fileReviewOpen = false;
  },
  [types.SHOW_OBSERVATION_FORM](state, params) {
    state.observationFormParams = params;
    state.observationFormOpen = true;
  },
  [types.CLOSE_OBSERVATION_FORM](state) {
    state.observationFormParams = {};
    state.observationFormOpen = false;
  },
  [types.SHOW_RESTRICTION_FORM](state, params) {
    state.restrictionFormParams = params;
    state.restrictionFormOpen = true;
  },
  [types.CLOSE_RESTRICTION_FORM](state) {
    state.restrictionFormParams = {};
    state.restrictionFormOpen = false;
  },
  [types.SHOW_APPROVAL_FORM](state, params) {
    state.approvalFormParams = params;
    state.approvalFormOpen = true;
  },
  [types.CLOSE_APPROVAL_FORM](state) {
    state.approvalFormParams = {};
    state.approvalFormOpen = false;
  },
  [types.SHOW_PROJECT_FORM](state, params) {
    state.projectFormParams = params;
    state.projectFormOpen = true;
  },
  [types.CLOSE_PROJECT_FORM](state) {
    state.projectFormParams = {};
    state.projectFormOpen = false;
  },
  [types.SHOW_PROPERTY_FORM](state, params) {
    state.propertyFormParams = params;
    state.propertyFormOpen = true;
  },
  [types.CLOSE_PROPERTY_FORM](state) {
    state.propertyFormParams = {};
    state.propertyFormOpen = false;
  },
  [types.SHOW_LINK_FORM](state, params) {
    state.linkFormParams = params;
    state.linkFormOpen = true;
  },
  [types.CLOSE_LINK_FORM](state) {
    state.linkFormParams = {};
    state.linkFormOpen = false;
  },
  [types.SHOW_LIBRARY_FORM](state, params) {
    state.libraryFormParams = params;
    state.libraryFormOpen = true;
  },
  [types.CLOSE_LIBRARY_FORM](state) {
    state.libraryFormParams = {};
    state.libraryFormOpen = false;
  },
  [types.SHOW_COLLECTION_FORM](state, params) {
    state.collectionFormParams = params;
    state.collectionFormOpen = true;
  },
  [types.CLOSE_COLLECTION_FORM](state) {
    state.collectionFormParams = {};
    state.collectionFormOpen = false;
  },
  [types.SHOW_LAYER_FORM](state, params) {
    state.layerFormParams = params;
    state.layerFormOpen = true;
  },
  [types.CLOSE_LAYER_FORM](state) {
    state.layerFormParams = {};
    state.layerFormOpen = false;
  },
  [types.SHOW_DATASET_FORM](state, params) {
    state.datasetFormParams = params;
    state.datasetFormOpen = true;
  },
  [types.CLOSE_DATASET_FORM](state) {
    state.datasetFormParams = {};
    state.datasetFormOpen = false;
  },
  [types.SHOW_ACCOUNT_FORM](state, params) {
    state.accountFormParams = params;
    state.accountFormOpen = true;
  },
  [types.CLOSE_ACCOUNT_FORM](state) {
    state.accountFormParams = {};
    state.accountFormOpen = false;
  },
  [types.SHOW_TASK_REPORT](state, params) {
    state.taskReportParams = params;
    state.taskReportOpen = true;
  },
  [types.CLOSE_TASK_REPORT](state) {
    state.taskReportParams = {};
    state.taskReportOpen = false;
  },
  [types.SHOW_MODAL](state, componentData) {
    const {
      name,
      params,
      label,
      size,
      includeToolbar,
    } = componentData;
    state.modalOpen = true;
    if (name) {
      state.modalIncludeToolbar = includeToolbar !== false;
      state.modalLabel = label;
      state.modalId += 1;
      state.modalComponent = name;
      state.modalSize = size;
      state.modalComponentParams = { ...params };
    }
  },
  [types.HIDE_MODAL](state) {
    state.modalOpen = false;
  },

};
