import { fetch, post } from '@/api';
import { projectEndpoints } from '@/api/resources';
import { get } from '@/utils';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations,
  getters as crudGetters,
} from './crud';

import {
  state as datasetState,
  actions as datasetActions,
  mutations as datasetMutations,
  getters as datasetGetters,
} from './parentDatasetMixins';

import {
  collection,
  collectionGetter,
  eventsGetter,
  mutations as collectionMutations,
  actions as collectionActions,
  getters as collectionGetters,
} from './childCollectionCRUD';

import {
  actions as deliverablesActions,
} from './deliverablesMixins'

import {
  spatialState,                       // has spatialLayersList right now
  spatialLayers,                      // GETTER to handle combining all the spatial layers
  getSpatialLayer,                    // ACTION to handle downloading layer
  getObjectSpatialLayer,              // ACTION to handdle downloading features (e.g. project_features)
  ADD_SPATIAL_LAYER                   // MUTATION to handle adding layer to list (state)
} from './spatialLayersMixins';

const SET_DATA = 'SET_DATA';
const SET_TEMPLATE = 'SET_TEMPLATE';
const SET_PROPERTY = 'SET_PROPERTY';
const SET_BOUNDARY = 'SET_BOUNDARY';
const SET_SECTIONS = 'SET_SECTIONS';
const SET_REPORTS = 'SET_REPORTS';


const state = {
  ...crudState,
  ...spatialState,
  ...datasetState,
  resource: 'project',
  storeType: 'project',
  pkey: 'project_id',
  label: 'Project Not Set',
  description: null,
  map: projectEndpoints,
  status: { label: 'No Status'},
  lead: { contact_id: 0, label: 'Not set'},
  template: { label: 'No template' },
  layersList: ['projectfeatures', 'properties'],
  reports: [],
  sections: [],
  boundary: null,
  startDate: null,
  endDate: null,
  closedOn: null,
  property: null,
  geometry: null,
  additionalInfo: {},
  tasks: collection(),
  hours: collection(),
  contacts: collection(),
  issues: collection(),
  messages: collection(),
  deliverables: collection(),
  checklists: collection(),
  files: collection(),
  documents: collection(),
  notes: collection(),
  features: collection(),
  parcels: collection(),
  accounts: collection(),
  events: collection(),
};

const getters = {
  ...crudGetters,
  ...collectionGetters,
  ...datasetGetters,
  baseUrl: (state) => 'projects',
  isClosed: state => !!state.closedOn,
  editable: state => true,//!state.closedOn,
  isLead: (state, getters, rootState, rootGetters) => {
    if(!state.lead) return false;
    return state.lead.contact_id === rootGetters['user/cid']
  },
  isPropertyType: state => state.template.is_property_project,
  // COLLECTIONS
  tasks: collectionGetter('tasks', 'task_id'),
  hours: collectionGetter('hours', 'hour_id'),
  contacts: collectionGetter('contacts', 'contact_id'),
  issues: collectionGetter('issues', 'issue_id'),
  messages: collectionGetter('messages', 'message_id'),
  deliverables: collectionGetter('deliverables', 'deliverable_id'),
  checklists: collectionGetter('checklists', 'checklist_id'),
  files: collectionGetter('files', 'file_id'),
  documents: collectionGetter('documents', 'document_id'),
  notes: collectionGetter('notes', 'note_id'),
  features: collectionGetter('features', 'feature_id'),
  parcels: collectionGetter('parcels', 'parcel_id'),
  accounts: collectionGetter('accounts', 'account_id'),
  events: collectionGetter('events', 'event_id'),
  // PROJECT TOOL CONFIG
  config: ( state ) => {
    let args = {
      // project: state.id,
      parent: 'project',
      parentId: state.id,
      ...state.additionalInfo,
      startDate: state.startDate,
      closedOn: state.closedOn,
    }
    if(state.template.is_property_project) args.parentProperty = get(['property','property_id'],state);
    // now mix in the attributes
    return args;
  },
  filteredDeliverables: ( state, getters) => {
    return getters.deliverables.data.filter( d => {
      // something in the deliverable needs to be passed
      // to something in the project used to filter
      if(!d.filter) return true;
      //const filter = { 'property/is_fee': false };
      // we are only supporting one type of filter right now,
      // but the following example should be extendable to more
      const value = Object.values(d.filter)[0];
      //console.log('Fee filter', d.filter, value);
      return get(['property', 'is_fee'], state) === value;
    })
  },
  requiredDeliverablesOpen: (state, getters) => {
    return getters.filteredDeliverables.filter( d => {
      return d.required && !d.finished_on;
    }).length
  },
  // SPATIAL GETTERS
  propertyFeatureCollection: state => ({
    type: 'FeatureCollection',
    info: {
      label: 'Unit',
      type: 'Polygon',
      multi: true,
      editable: false,
      is_collection: false,
      api: {
        put: 'property/save/:id',
        post: 'property/saveas',
        get: "property/:id",
      }
    },
    style: { fillColor: 'transparent' },
    features: [{
      type: 'Feature',
      id: (state.property ? state.property.property_id : null),
      properties: { id: (state.property ? state.property.property_id : null) },
      geometry: (state.property ? state.property.geometry : null),
    }],
  }),
  projectFeatureCollection: state => ({
    type: 'FeatureCollection',
    info: {
      label: 'Project',
      type: 'Polygon',
      multi: true,
      editable: true,
      is_collection: false,
      api: {
        put: 'project/save/:id',
        post: 'project/saveas'
      }
    },
    style: { fillColor: 'steelblue' },
    features: [{
      type: 'Feature',
      id: `${state.id}`,
      geometry: state.geometry,
    }],
  }),
  spatialLayers,
  layers: (state, getters) => ({
    properties: getters.propertyFeatureCollection,
    projectfeatures: getters.projectFeatureCollection,
      ...getters.spatialLayers,
  }),
  taskEvents: eventsGetter('tasks'),
  noteEvents: eventsGetter('notes'),
  issueEvents: eventsGetter('issues'),
  messageEvents: eventsGetter('messages'),
  accountEvents: eventsGetter('accounts'),
  eventEvents: eventsGetter('events'),
  calendar: (state, getters) => [
    ...getters.taskEvents,
    ...getters.issueEvents,
    ...getters.noteEvents,
    ...getters.messageEvents,
    ...getters.accountEvents,
    ...getters.eventEvents,
  ],
};

const mutations = {
    ...crudMutations,
  ...collectionMutations,
  ...datasetMutations,
  ADD_SPATIAL_LAYER,
  [SET_DATA](state, meta) {
    //state.template = meta.template;
    //console.log('set data', meta);
    state.label = meta.label;
    state.description = meta.description;
    state.type = meta.type;
    state.lead = meta.lead_contact;
    state.additionalInfo = meta.additional_info;
    state.closedOn = meta.closed_on;
    state.startDate = meta.start_date;
    state.endDate = meta.end_date;
    // if (meta.geometry) state.geometry = meta.geometry;
    state.status = meta.project_status || { label: 'No Status' };
    state.loaded = true;
  },
  [SET_TEMPLATE](state, value) {
    if(value) state.template = value;
  },
  [SET_SECTIONS](state, sections) {
    state.sections = sections || [];
  },
  [SET_REPORTS](state, reports) {
    state.reports = reports || [];
  },
  [SET_BOUNDARY](state, value) {
    if(value) state.boundary = value;
  },
  [SET_PROPERTY](state, value) {
    if(value) state.property = value;
  },
};
const actions = {
  ...crudActions,
  ...collectionActions,
  ...deliverablesActions,
  ...datasetActions,
  getSpatialLayer,
  getObjectSpatialLayer,
  processPageData({ state, commit, dispatch }, {
    meta,
    boundary,
    template,
    property,
    reports,
  }){
    // console.log('Process page data')
    commit(SET_DATA, meta);
    // Im not sure about this, just because we are creating the project
    // does not mean that we will need the property as well
    // if(property){
    //   // if this is a property project we want to just create that property object
    //   dispatch('initializeProperty', { id: property.property_id, key: `p${meta.uid}` }, { root: true });
    // }
    commit(SET_TEMPLATE, template);
    commit(SET_PROPERTY, property);
    commit(SET_BOUNDARY, boundary);
    commit(SET_REPORTS, reports);

    dispatch('getObjectSpatialLayer', {
      object: 'project',
      id: state.id,
      key: `project${state.id}`
    });
   // dispatch('getSpatialLayer', {
   //   id: 16,
   //   key: `project${state.id}`
   // });

  },
  setProperty({state, dispatch, commit }, { id }){
    const url = `project/setProperty/${state.id}`
    return post(url, { property_id: id } )
      .then( res => {
        dispatch('load', {})
        return res;
      })
  },
  getReportSections({ state, commit }, { id }) {
    // where id is the report id
   fetch(`templatereports/sections/${id}`).then( res => {
      //console.log(res)
      commit(SET_SECTIONS, res)
    });
  },
  updateStatus({ state, commit, dispatch},  data) {
    const url = data.id ? `projectstatuses/save` : `projectstatuses/saveas`;
    const args = { project_id: state.id, ...data }
    return post(url, args).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  removeStatus({ state, commit, dispatch},  { id }) {
    const url = `projectstatuses/remove/${id}`;
    return post(url).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  updateLead({ state, commit, dispatch},  data) {
    const url = data.id ? `projectleads/save` : `projectleads/saveas`;
    const args = { project_id: state.id, ...data }
    return post(url, args).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  removeLead({ state, commit, dispatch},  { id }) {
    const url = `projectleads/remove/${id}`;
    return post(url).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  leadHistory({ state }) {
    const url = `projectleads/load`;
    const args = { project_id : state.id }
    return fetch(url, args).then((res) => {
      return res;
    });
  },
    closeTask({ state, commit }, { id }) {
    const url = `projecttasks/close/${id}`;
    return post(url).then((res) => {
      // dispatch('getRecord', { id })
      //commit('UPDATE_RECORD', res[0].data);
      // dispatch a page message
      commit('pageMessages/ADD_MESSAGE', {
        type: 'record-updated',
        params: { label: 'Task Closed' },
        active: true
      }, {root: true});
    });
  },
  openTask({ state, commit }, { id }) {
    const url = `projecttasks/reopen/${id}`;
    return post(url).then((res) => {
      // dispatch('getRecord', { id })
      //commit('UPDATE_RECORD', res[0].data);
      commit('pageMessages/ADD_MESSAGE', {
        type: 'record-updated',
        params: { label: 'Task Re-opened' },
        active: true
      }, {root: true});
    });
  },
};

// combine to a vuex store
export default function (options) {
  const module = {
    namespaced: true,
    state() {
      return Object.assign(JSON.parse(JSON.stringify(state)), options);
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
