
import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from '@acorns-ui/Stores/Collections/Mixins/collection';

import {
  state as eventsState,
  getters as eventsGetters,
} from '@acorns-ui/Stores/Collections/Mixins/eventsCollectionMixins';

const initialState = {
  ...baseState,
  ...eventsState,
  uniqueId: 'account_id',
  record: 'account',
  collection: 'accounts',
  form: 'CollectionForm',
  eventFields: [
    'start_date',
    'end_date'
  ],
  tables: [
    {
      uid: 'types',
      label: 'Types',
      collection: 'accountTypes',
      uniqueId: 'account_type_id',
    },
    {
      uid: 'templates',
      label: 'Templates',
      collection: 'accountTemplates',
      uniqueId: 'account_template_id',
    },
  ]
};

const getters = {
  ...baseGetters,
  ...eventsGetters,
};

const mutations = {
  ...baseMutations,
};

const actions = {
  ...baseActions,
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
