import {
  actions as crudActions,
  mutations as crudMutations
} from './crud';

import {
  actions as childActions,
  mutations as childMutations
} from './childCollectionCRUD';

import {
  initialState as baseState,
  actions as collectionActions,
  mutations as collectionMutations
} from './collection';

import {
  fetch,
  fetchCollection,
  fetchRecord,
  post,
} from '@/api';

import { get } from '@/utils'

//import { issueEndpoints } from '@/api/resources';

export const SET_ID = 'SET_ID';
export const SET_DATA = 'SET_DATA';
export const SET_FEATURES = 'SET_FEATURES';
export const MERGE_FEATURES = 'MERGE_FEATURES';

const initialState = () => ({
    ...baseState,
  uniqueId: 'id',
  id: null,
  spatialLayerId: null,
  loaded: false,
  record: 'layer',
  collection: 'layers',
  label: null,
  description: null,
  tableInfo: { },
  attributes: {},
  style: {},
  info: {
    type: 'Polygon',
    multi: true,
    editable: true,
    is_collection: true,
  },
  api: {},
  records: {},
  data: [], // collection of features
  selected: [], //"12444","12442"
});

const getters = {
  api: (state) => (verb, id) => {
    const uri = state.api[verb];
//    console.log(state.api, uri, verb, id)
    return id ? uri.replace(':id', id) : uri.replace('/:id', '');
  },
  featureCollection: (state) => ({
    type: 'FeatureCollection',
    id: state.id,
    info: state.info,
    style: state.style,
    features: state.data,
    loaded: state.loaded,
  }),
};

const mutations = {
  // ...crudMutations,
    ...collectionMutations,
  //  ...childMutations,
  [SET_ID](state, id) {
    state.id = id;
  },
  [SET_DATA](state, d) {
    //if(d.layer_id) state.id = d.layer_id;
    console.log('setting layer info', d)
    if(d.info) {
      state.label = d.info.label;
      state.description = d.info.description;
      state.info = d.info;
      state.api = get(['info','api'], d) || {}
    } else {
      state.label = d.label;
      state.description = d.description;
    }

    //if(d.table) state.table = d.table || {};
    if(d.style) state.style = d.style || {};
    if(d.features) state.data = d.features;
    state.loaded = true;
  },
  [SET_FEATURES](state, f){

    f.forEach((item, index) => {
      if(state.selected.includes(item.uid)) {
        f[index]['properties']['isSelected'] = true;
      }
    });

    state.data = f;
  },
  [MERGE_FEATURES](state, values){
    if(!values) return;
    console.log('merge features', values)
    values.forEach((item, index) => {
      if(state.selected.includes(item.uid)) {
        values[index]['properties']['isSelected'] = true;
      }
    });

    if (state.data.length === 0) {
      console.log('Replacing the layer data', state.label)
      state.data = values //values.data || values || [];
    } else {
      console.log('Merging the layer data', state.label)
      const uid = state.uniqueId;
      const ids = values.map( d => d[uid])
      // filter out the duplicates
      const data = state.data.filter( d => !ids.includes(d[uid]))
      // but merge the duplicate data, this will make sure we have all the required data
      values.map(x => Object.assign(x, state.data.find(y => y[uid] == x[uid])));
      // and put it all back
      state.data = [...data, ...values ]
      // console.log(state.record, state.uniqueId, ids, data, state.data)
    }
  }
};

const actions = {
  // ...crudActions,
    ...collectionActions,
  //  ...childActions,
  getObjectData({ state, commit }, { object, id, boundary }) {
    const url = `${object}/features/${id}`;
    commit(SET_ID, id)
    return fetch(url).then((res) => {
      //console.log(res)
      commit(SET_DATA, res)
    });
  },
  getData({ state, commit }, { id, boundary }) {
    const url = `layer/geojson/${id}`;
    commit(SET_ID, id)
    return fetch(url, { boundary }).then((res) => {
      commit(SET_DATA, res)
    }).catch( err => {
      console.log('I caught this error for you', err)
    })
  },
  getRecord({ state, commit, getters }, { url, id }) {
    //const url = `feature/details2/${id}`;
    //console.log('layers: get record', id, state)
    const uri = url || getters.api('get', id);////get(['info','api','get'], state)
    console.log('get feature record', url, id)
    return fetch(uri)
      .then((res) => {
        commit('UPDATE_RECORD', res)
        return res;
    })
  },
  // updateRecord({ state, commit }, { url, data, callback }) {
  //   //const endpoint = url || state.record;
  //   //const address = `${endpoint}/save2`;
  //   const url = get(['info','api','put'], state)
  //   return post(url, data).then((res) => {
  //     if (callback) {
  //       callback(res);
  //     } else {
  //       commit(UPDATE_RECORD, res.data);
  //     }
  //     commit('pageMessages/ADD_MESSAGE', {
  //       type: state.addedMessageType,
  //       params: { label: 'Record updated' },
  //       active: true
  //     }, {root: true});
  //     return res;
  //   });
  // },
  getInfo({ state, commit }, { id, boundary }) {
    const url = `layer/details/${id}`;
    commit(SET_ID, id)
    return fetch(url, { boundary }).then((res) => {
      commit(SET_DATA, res)
    })
  },
  getFeatures({ state, commit }, { boundary }) {
    const url = `layer/geojson/${state.id}`;
    return fetch(url, { boundary }).then((res) => {
      console.log('get features', res)
      if(res.result){
        res = res.data[0];
      }
      commit(MERGE_FEATURES, res.features || res)
    }).catch( err => {
      // assume we had a memory issue??
      console.log('I caught this error for you', err)
    });
  },
  getSelectedFeatures({ state, commit }, { ids }) {
    const url = `layer/geojson/${state.id}`;
    return fetch(url, { ids }).then((res) => {
      commit(MERGE_FEATURES, res.features || res)
    }).catch( err => {
      // assume we had a memory issue??
      console.log('I caught this error for you', err)
    });
  },
  // this method could be called directly or through the
  // parent store
  updateFeature({ state, dispatch, getters }, { id, data } ){
    // geometries really dont need to be refreshed
    // so this could be called directly
    const uri = getters.api('put', id)
    return post(uri, data)
  },
  addRecord({ state, commit, dispatch, getters }, { data }){
    const uri = getters.api('post')
    const args = { layer_id: state.id, ...data };
    return post(uri, args).then( res => {
      commit('pageMessages/ADD_MESSAGE', {
        type: state.addedMessageType,
        params: { label: 'Record added' },
        active: true
      }, {root: true});
      return res;
    })
  },
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
