<template>
<div class="control-events-admin-sidebar">

  <PanelMenu
    label="Settings"
    :expanded="true">

    <RouteMenuItem
      label="Fields"
      description="Control event table fields"
      route="/control/admin/fields"/>

  </PanelMenu>

  <PanelMenu
    label="Support Tables"
    :expanded="true">

    <RouteMenuItem
      label="Methods"
      description="Available control methods"
      route="/control/admin/methods"/>

    <RouteMenuItem
      label="Products"
      description="Available control products"
      route="/control/admin/products"/>

    <RouteMenuItem
      label="Reasons"
      description="Available control reasons"
      route="/control/admin/reasons"/>

  </PanelMenu>

</div>
</template>

<script>
import PanelMenu from '@acorns-ui/Menus/PanelMenu';
import RouteMenuItem from '@acorns-ui/Menus/RouteMenuItem';

export default {
  name: 'ControlEventsAdminSidebar',
  props: { },
  data(){
    return {
    }
  },
  components: {
    PanelMenu,
    RouteMenuItem,
  },
  computed: {
  },
  methods: {
    pushRoute(path) {
      const url = `/admin/${path}`;
      this.$router.push(url);
    },
    showMenu(){

    }
  },
};
</script>

<style lang="scss" scoped>

</style>
