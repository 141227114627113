import {
//  fetchCollection,
//  post,
} from '@/api';

import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from './collection';

export const SET_OBSERVATIONS = 'SET_OBSERVATIONS';

const initialState = {
  ...baseState,
  uniqueId: 'property_monitoring_element_observation_id',
  record: 'elementObservations',
  collection: 'elementObservations',
};

const getters = {
  ...baseGetters,
};

const mutations = {
  ...baseMutations,
};

const actions = {
  ...baseActions,
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
