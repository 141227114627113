import { fetch, post } from '@/api';
import moment from 'moment';
import { get, assign } from '@/utils';
import { contactEndpoints } from '@/api/resources';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations } from './crud';

import {
  collection,
  collectionGetter,
  eventsGetter,
  mutations as collectionMutations,
  actions as collectionActions
} from './childCollectionCRUD';

// import {
//   spatialState,
//   spatialLayers,
//   getSpatialLayer,
//   getObjectSpatialLayer,
//   ADD_SPATIAL_LAYER
// } from './spatialLayersMixins';

export const SET_DATA = 'SET_DATA';
//export const SET_LAYERS = 'SET_FEATURES';

const state = {
    ...crudState,
  resource: 'contact',
  storeType: 'contact',
  map: contactEndpoints,
  label: 'Contact Not Set',
  description: null,
  type: { label: 'Type not set' },
  additionalInfo: {},
  roles: [],
  emails: [],
  phones: [],
  addresses: [],
  // group methods
  organizations: collection(),
  groups: collection(),
  members: collection(),
  // COLLECTION STATE
  tasks: collection(),
  hours: collection(),
  projects: collection(),
  messages: collection(),
  properties: collection(),
  issues: collection(),
  notes: collection(),
};

const getters = {
  // general
  baseUrl: (state) => 'contacts',
  currentRoles: state => state.roles.filter( r => true ),
  currentRole: ( state, getters ) => getters.currentRoles[0],
  editable: state => true,//!state.closedOn,
  // GROUPS
  organizations: collectionGetter('groups', 'contact_group_id'),
  members: collectionGetter('groups', 'contact_group_id'),
  groups: collectionGetter('groups', 'contact_group_id'),
  tasks: collectionGetter('tasks', 'task_id'),
  tasks: collectionGetter('tasks', 'task_id'),
  // COLLECTIONS
  tasks: collectionGetter('tasks', 'task_id'),
  hours: collectionGetter('hours', 'hour_id'),
  projects: collectionGetter('projects', 'project_id'),
  messages: collectionGetter('messages', 'message_id'),
  properties: collectionGetter('properties', 'property_id'),
  issues: collectionGetter('issues', 'issue_id'),
  notes: collectionGetter('notes', 'note_id'),
  // BOARD/TOOL CONFIG
  config: ( state, getters ) => { },
  // EVENTS
  taskEvents: eventsGetter('tasks'),
  noteEvents: eventsGetter('notes'),
  projectEvents: eventsGetter('projects'),
  events: ( state, getters ) => [
      ...getters.taskEvents,
      ...getters.projectEvents,
      ...getters.noteEvents,
  ],
};

const mutations = {
    ...crudMutations,
    ...collectionMutations,
  [SET_DATA](state, meta) {
    state.label = meta.label;
    state.description = meta.description;
    state.type = meta.contact_type;
    state.additionalInfo = meta.additional_info;
    state.emails = meta.emails || [];
    state.phones = meta.phones || [];
    state.addresses = meta.addresses || [];
    state.roles = meta.roles_list || [];
    state.loaded = true;
  },
};
const actions = {
    ...crudActions,
    ...collectionActions,
  processPageData({ state, commit, dispatch }, { meta }){
  //  dispatch('getObjectSpatialLayer', { object: 'contact', id: state.id });
    commit(SET_DATA, meta);
  },
};

// combine to a vuex store
export default function (options) {
  const stt = assign(state, options);
  const module = {
    namespaced: true,
    state() {
      return stt
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
