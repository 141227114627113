<template>
<div class="control-event-tool-display">
  <ControlEventPreview
    v-if="id"
    :include-header="false"
    :record-id="id"
    :loaded="loaded"
    v-bind="event"
    />
  <div v-else
       class="missing-data">
    The control event form has not been filled out yet
  </div>
</div>
</template>

<script>
import { fetch } from '@/api';
import ControlEventPreview from '../ControlEventPreview';

export default {
  name: 'ControlEventToolDisplay',
  props: {
    id: {
      type: [String, Number],
    },
    reporting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      event: {},
    };
  },
  components: {
    ControlEventPreview,
  },
  created() {
    this.loaded = false;
    if(this.id) {
      this.fetchEvent(this.id);
    }
  },
  watch: {},
  computed: {},
  methods: {
    fetchEvent(id){
      fetch(`controlevents/details/${id}`)
        .then( evt => {
          this.event = evt
          this.loaded = true;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.modal {
  .control-event-tool-display {
    //width: 40rem;
  }
}
.control-event-tool-display {
  .missing-data {
    padding: 1rem;
  }
}
</style>
