import { fetch, post } from '@/api';
import { get } from '@/utils';

const SET_DATA = 'SET_DATA';
const SET_RECORD = 'SET_RECORD';
export const UPDATE_RAW_DATA = 'UPDATE_RAW_DATA';
export const UPDATE_ADDITIONAL_INFO_DATA = 'UPDATE_ADDITIONAL_INFO_DATA';
export const SET_WORKSPACE_LABEL = 'SET_WORKSPACE_LABEL';

export const state = {
  id: null,
  additionalInfo: {},
  record: { inputs: [], data: {} },
  loaded: false,
  map: {},
};


export const getters = {
}

export const mutations = {
  // This is just a default verions, its expected that
  // each module will override this one
  [SET_DATA](state, { meta }) {
    Object.assign(state, meta);
    state.loaded = true;
  },
  [SET_RECORD](state, value) {
    state.record = value;
  },
  [UPDATE_RAW_DATA](state, value) {
    const { pkey } = state;
    for (const key in value) {
      if (key !== pkey) state.record.data[key] = value[key];
    }
  },
  [UPDATE_ADDITIONAL_INFO_DATA](state, value) {
    for (const key in value) {
      Vue.set(state.additionalInfo, key, value[key])
    }
  },
  [SET_WORKSPACE_LABEL](state, value) {
    state.workspaceLabel = value;
  },
};

export const actions = {
  // the output of this action is going to be a generic form
  // based on the inputs of the table
  setId({state, commit, dispatch}, id ) {
    dispatch('load');
  },
  load({ state, commit, dispatch }, { collections }) {
    const { id } = state;
    const url = `${state.resource}/pageData/${id}`;
    console.log('loading page data', url);
    fetch(url)
      .then( data => {
        //console.log('load', data)
        // most should have a record object that needs to be set
        if(data.record) commit(SET_RECORD, data.record)
        if(collections && collections.length){
          for(let i = 0; i<collections.length;i++) {
            let collection = collections[i]
            dispatch('getCollectionData', { collection });
          }
        }
        // and now we can do any custom processing that needs to be done
        dispatch('processPageData', data)
      })
      .catch( err => {
        commit('PageMessages/ADD_MESSAGE', {
          type: 'record-updated',
          params: { label: 'Project not found' },
          active: true
        }, {root: true});

      })
  },
  reload({ state, commit, dispatch }, { collections }) {
    const { id } = state;
    const url = `${state.resource}/pageData/${id}`;
   fetch(url).then( data => {
      // most should have a record object that needs to be set
      if(data.record) commit(SET_RECORD, data.record)
      // and now we can do any custom processing that needs to be done
      dispatch('processPageData', data)
    })
    if(collections && collections.length){
      dispatch('refreshCollections', { collections })
    }
  },
  processPageData({ commit }, data){
    commit(SET_DATA, data);
  },
  loadCollections({ state, dispatch, commit }, { collections, params }) {
    for(let i = 0; i<collections.length;i++) {
      let collection = collections[i]
      dispatch('getCollectionData', { collection, params });
    }
  },
  refreshCollections({ state, dispatch, commit }, { collections, params }) {
    for(let i = 0; i<collections.length;i++) {
      let collection = collections[i]
      dispatch('refreshCollectionData', { collection, params});
    }
  },
  loadLayers({ state, dispatch, commit }, { layers }) {
    for(let i = 0; i<layers.length;i++) {
      let layer = layers[i]
      dispatch('getSpatialLayer', { id: layer });
    }
  },
  editField({ state, commit, dispatch }, value) {

    let component = 'basic-form';
    let params = {};
    let inp;
    let err = '';
    if (state.record.inputs) {
      inp = state.record.inputs.find(obj => obj.name === value);
      // console.log(inp, typeof (inp), typeof (inp) === 'object');
    } else {
      err = 'The record variable has not been set';
    }

    if (typeof (inp) === 'object') {
      params = {
        inputs: [inp],
        data: state.record.data,
        callback(data) {
          dispatch('updateField', data);
        },
      };
    } else {
      component = 'message-window';
      params = {
        label: 'Form error',
        message: err || `Could not find the input '${value}'`,
      };
    }
    commit('SHOW_MODAL', {
      name: component,
      params,
    }, { root: true });
  },
  // and this action is responsible for doing the update
  updateField({ state, commit, dispatch }, value  ) {
    // value should be key/value
    const { id, pkey, resource } = state;
    // value[pkey] = id; // if we pass the id in the url we dont need it here
    const url = `${resource}/save/${id}`;
    // updating field message
    post(url, value).then( rsp => {
      // the response will have the base data with it
      //commit(SET_DATA, rsp.data);
      // For now we are going to refresh everything
      console.log('We are loading after update field', state.resource)
      dispatch('load', {})
      // and this is the raw data
      //commit(UPDATE_RAW_DATA, value);
    });
  },
  updateFieldNoRefresh({ state, commit }, value) {
    const { id, pkey, resource } = state;
    const url = `${resource}/save/${id}`;
    post(url, value);
  },
  updateAdditionalInfoField({ state, commit }, value) {
    // value should be key/value
    const { id, pkey, resource } = state;
    value[pkey] = id;
    const url = `${resource}/updateAttributes`;
    post(url, value).then( rsp => {
      // the response will have the base data with it
      //commit(SET_DATA, rsp.data);
      // and this is the raw data
      commit(UPDATE_ADDITIONAL_INFO_DATA, value);
    });
  },
};
