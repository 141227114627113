// Call for a dataset to be added/removed through
// the parent, which then stores the list.
export const ADD_DATASET = 'ADD_DATASET';
export const REMOVE_DATASET = 'REMOVE_DATASET';


export const state = {
  activeDatasets: [],
};

export const getters = {
  datasets: state => state.activeDatasets,
};

export const mutations = {
  [ADD_DATASET](state, id) {
    if(!state.activeDatasets.includes(id)) state.activeDatasets.push(id)
  },
  [REMOVE_DATASET](state, id) {
    const idx = state.activeDatasets.findIndex(d => d === id);
    if (idx >= 0) state.activeDatasets.splice(idx, 1);
  },
};


export const actions = {
  // call the global get dataset action and then
  // add the dataset to our local list
  getDataset({ dispatch, commit }, { id, filter}){
    return dispatch('getDataset', { id }, { root: true })
      .then(() => commit(ADD_DATASET, id))
  },
  // call the global destroy method and then
  // remove the databset from our local list
  removeDataset({ state, dispatch }, { id }){
    return dispatch('destroyDataset', { id }, { root: true })
      .then(() => commit(REMOVE_DATASET, id))
  },
};
