import { fetch, post } from '@/api';
import { templateEndpoints } from '@/api/resources';

const SET_DATA = 'SET_DATA';
const SET_SECTIONS = 'SET_SECTIONS';
const SET_CONFIG = 'SET_CONFIG';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations,
  getters as crudGetters,
} from './crud';

import {
  collection,
  collectionGetter,
  mutations as collectionMutations,
  actions as collectionActions,
  getters as collectionGetters,
} from './childCollectionCRUD';

const state = {
    ...crudState,
  resource: 'template',
  map: templateEndpoints,
  label: 'No label provided',
  description: 'No description provided',
  owner: null,
  isPropertyProject: false,
  additionalArgs: {},
  config: {
    project: 37,
    property: 85,//167,//71,//182,
    visit_date: '2018-10-05'
  },
  statuses: [],
  fields: [],
  filters: [],
  objects: [],
  reports: [],
  reports2: [], // testing the aggregate reports
  sections: [],
  deliverables: collection(),
  tasks: collection(),
  projects: collection({
    filters: { openOrClosedRecently: '4months' }
  }),
};

const getters = {
  ...crudGetters,
  ...collectionGetters,
  baseUrl: () => 'templates',
  deliverables: collectionGetter('deliverables', 'deliverable_id'),
  projects: collectionGetter('projects', 'project_id'),
};

const mutations = {
    ...crudMutations,
    ...collectionMutations,
  [SET_DATA](state, { meta, reports, groupReports }) {
    state.label = meta.label;
    state.description = meta.description;
    state.owner = meta.owner;
    state.isPropertyProject = meta.is_property_project;
    state.reports = reports || [];
    state.reports2 = groupReports || [];
    state.fields = meta.fields || [];
    state.filters = meta.filters || [];
    state.objects = meta.objects || [];
    state.statuses = meta.statuses || [];
    //state.sections = sections || [];
  },
  [SET_SECTIONS](state, sections) {
    state.sections = sections || [];
  },
  [SET_CONFIG](state, config) {
    state.config = config || {};
  },
};
const actions = {
    ...crudActions,
    ...collectionActions,
  getReportSections({ state, commit }, { id }) {
    // where id is the report id
   fetch(`templatereports/sections/${id}`).then( res => {
      console.log(res)
      commit(SET_SECTIONS, res)
    });
  }
};

// combine to a vuex store
export default function (options) {
  const module = {
    namespaced: true,
    state() {
      return Object.assign(JSON.parse(JSON.stringify(state)), options);
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
