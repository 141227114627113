<template>
<PreviewLayout
  class="control-event-preview"
  :loaded="loaded"
  :background-color="backgroundColor"
  :inline="!includeHeader"
  :error.sync="error"
  @close="$emit('close')">

  <DeleteIcon
    slot="actions"
    title="Delete the task"
    orientation="dialog left"
    :as-icon-link="true"
    @delete="removeEvent"/>

  <IconLink
    slot="actions"
    @clicked="showEventForm"
    class="clickable">
    edit
  </IconLink>

  <div
    class="control-event-header"
    slot="info">
    <div class="label">
      {{ start_datetime | date }} Control Event
    </div>
    <PreviewMenu
      :tabs="tabs"
      :active.sync="tab"
      />
  </div>

  <div
    slot="panels"
    class="children">


  <div
    v-if="tab == 'overview'"
    class="child-panel">
    <InfoLine
      v-if="parent!='property'"
      :row="true"
      title="Unit">
      <PropertyChip
        @followed="$emit('close')"
        v-bind="property"/>
    </InfoLine>
    <InfoLine
      title="Method">
      {{ control_method.label }}
    </InfoLine>
    <InfoLine
      title="Applicators">
      {{ applicatorsLabel }}
    </InfoLine>
    <InfoLine
      title="Area covered">
      {{ acres_covered | acres }} acres
    </InfoLine>
    <InfoLine
      v-if="shore_length"
      title="Shore length (meters)">
      {{ shore_length }}
    </InfoLine>
    <InfoLine
      title="Targets">
      {{ targetsLabel }}
    </InfoLine>
    <InfoLine
      v-if="isHerbicide"
      title="Chemicals">
      {{ chemicalsLabel }}
    </InfoLine>
    <!-- <InfoLine -->
    <!--   title="Wind"> -->
    <!--   {{ windDescription }} -->
    <!-- </InfoLine> -->
    <PanelLayout
      v-if="notes || true"
      label="Notes"
      :fill="true"
      :open="true">
      <EditableDiv
        class="notes"
        :value="notes"
        placeholder="Enter notes related to the event"
        @updated="updateEventField('notes', $event)"/>
    </PanelLayout>
  </div>


    <PanelLayout
      v-if="tab=='application'"
      label="Application details"
      :open.sync="panels.application">
      <InfoLine title="Applicator">
        {{ applicatorsLabel }}
      </InfoLine>
      <InfoLine title="Method">
        {{ application.application_method.label }}
      </InfoLine>
      <InfoLine
        title="Environmental Conditions">
        {{ application.environmental_conditions }}
      </InfoLine>
      <InfoLine
        title="Wind conditions">
        {{ application.wind_conditions }}
      </InfoLine>
      <InfoLine title="Threshold met?">
        {{ application.threshold_met }}
      </InfoLine>
      <InfoLine title="Aquatic treatment?">
        {{ application.aquatic_treatment }}
      </InfoLine>
      <InfoLine title="Water Body">
        {{ additionalInfo.water_body }}
      </InfoLine>
      <InfoLine title="WRIA#">
        {{ additionalInfo.wria_number }}
      </InfoLine>
      <!-- <InfoLine -->
      <!--   title="Wind Speed"> -->
      <!--   {{ windSpeed }} -->
      <!-- </InfoLine> -->
      <!-- <InfoLine -->
      <!--   title="Wind Direction"> -->
      <!--   {{ windDirection }} -->
      <!-- </InfoLine> -->
      <InfoLine
        title="Total volume">
        {{ application.total_volume }}
      </InfoLine>
      <InfoLine
        title="Threshold Met?">
        {{ application.threshold_met }}
      </InfoLine>
      <InfoLine
        title="Percent Treated">
        {{ application.percent_treated }}
      </InfoLine>
      <InfoLine
        title="Unexpected effects">
        {{ application.unexpected_effects }}
      </InfoLine>
      <InfoLine
        title="Prior non-chemical control">
        {{ application.prior_nonchem_control }}
      </InfoLine>

    </PanelLayout>

    <PanelLayout
      v-if="tab=='chemicals'"
      label="Treatment Chemicals"
      :fill="true"
      :open.sync="panels.chemicals">
      <div
        v-for="s in chemicals"
        class="row-item">
        <ItemActions
	        slot="actions"
	        @remove="removeItem('chemicals', s.application_chemical_id)"
	      />
        <div class="label">
          {{ s.label }}
        </div>
        <div class="volume">
          {{ s.vol_gallons }} gal
        </div>
      </div>
      <div
        v-if="!chemicals.length"
        class="no-data-message">
        No chemicals submitted
      </div>
    </PanelLayout>

    <PanelLayout
      v-if="tab == 'targets'"
      label="targets"
      :open.sync="panels.targets"
      :fill="true">
      <div
        v-if="targets"
        v-for="(s, i) in targets"
        class="row-item">
        <ItemActions
	        slot="actions"
	        @remove="removeItem('targets', s.control_target_id)"
	      />
        <div class="label">
          {{ s.label }} ({{ s.percent_of_control }}%)
        </div>
        <div class="field">
          {{ s.control_reason.label }}
        </div>
      </div>
      <div
        v-if="!targets || !targets.length"
        class="no-data-message">
        No targets submitted
      </div>
    </PanelLayout>

    <PanelLayout
      v-show="tab=='map'"
      class="parcel-map"
      :open="true"
      label="map">
      <GeojsonInput
        v-if="mapReady"
        :editable="false"
        :value="geometry"/>
   </PanelLayout>

  </div>

</PreviewLayout>
</template>

<script>
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout'
import InfoLine from '@acorns-ui/Text/InfoLine';
import PanelLayout from '@acorns-ui/Layouts/PanelLayout';
import EditableDiv from '@acorns-ui/Text/EditableDiv';
import IconLink from '@acorns-ui/Icons/IconLink';
import GeojsonInput from '@acorns-ui/Inputs/GeojsonInput';
import PreviewMenu from '@acorns-ui/Menus/PreviewMenu';
 import DeleteIcon from '@acorns-ui/Icons/DeleteIcon';
 import ItemActions from '@acorns-ui/Panels/ItemActions';
 import { remove } from '@/api';
 import { get } from '@/utils';
import PropertyChip from '@acorns-ui/Chips/PropertyChip';

export default {
  name: 'ControlEventPreview',
  inheritAttr: false,
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: String,
    },
    parentId: {
      type: [Number,String],
    },
    recordId: {
      type: [Number, String],
    },
    includeHeader: {
      type: Boolean,
      default: true,
    },
    property: {
      type: Object,
      default: () => ({
        label: 'Not set'
      })
    },
    start_datetime: {
      type: String,
    },
    control_method: {
      type: Object,
      default: () => ({
        label: 'Not set'
      })
    },
    acres_covered: {
      type: [Number, String],
    },
    shore_length: {
      type: [Number, String],
    },
    notes: {
      type: String,
    },
    geometry: {
      type: Object,
    },
    targets: {
      type: Array,
      default: () => []
    },
    applicators: {
      type: Array,
      default: () => []
    },
    application: {
      type: Object,
      default: () => ({
        applicator_contact: [],
        application_method: {},
        chemicals: [],
        additional_info: {}
      })
    }
  },
  data() {
    return {
      error: null,
      tab: 'overview',
      mapReady: false,
      panels: {
        details: true,
        map: true,
        notes: true,
        targets: true,
        application: true,
        chemicals: true,
      }
    }
  },
  components: {
    PanelLayout,
    PreviewMenu,
    EditableDiv,
    PreviewLayout,
    InfoLine,
    IconLink,
    GeojsonInput,
    PropertyChip,
    DeleteIcon,
    ItemActions,
  },
  watch: {
    tab(a,b) {
      if(a == 'map') {
        // I dont want to set the map up until we click to the tab
        // and I want it to stay in that state when we click away
        // hence the v-show and this
        this.mapReady = true;
      }
    },
    // recordId(a,b) {
    //   this.tab = 'overview';
    // }
  },
  computed: {
    tabs() {
      const nc = this.chemicals && this.chemicals.length;
      const nt = this.targets && this.targets.length;
      return  [
        { uid: 'overview', label: 'Overview' },
        { uid: 'application', label: 'Application' },
        { uid: 'targets', label: 'Targets', badge: nt },
        { uid: 'chemicals', label: 'Chemicals', badge: nc },
        { uid: 'map', label: 'Map' },
      ].filter( d => this.isHerbicide | !(['application','chemicals'].includes(d.uid)))
    },
    targetsLabel(){
      if(!this.targets || !this.targets.length) {
        return 'No targets listed';
      } else {
        return this.targets.map( d => d.label).join(', ');
      }
    },
    isHerbicide() {
      return this.control_method && this.control_method.control_method_id == 1;
    },
    hasChemicals(){
      return this.chemicals.length>0;
    },
    chemicals(){
      return get(['chemicals'], this.application) || [];
    },
    chemicalsLabel(){
      if(!this.chemicals || !this.chemicals.length) {
        return 'No chemicals listed';
      } else {
        return this.chemicals.map( d => d.label).join(', ');
      }
    },
    applicatorsLabel(){
      if(!this.applicators || !this.applicators.length) {
        return 'No applicators listed';
      } else {
        return this.applicators.map( d => d.label).join(', ');
      }
    },
    windDescription() {
      const ws = this.application.wind_speed;
      const wd = this.application.wind_direction
      if( ws && wd ) {
        return `${ws} mph from the ${wd}`;
      } else if (ws) {
        return `${ws} mph`;
      } else if(wd) {
        return `The wind was coming from the ${wd}`;
      } else {
        return 'No wind information';
      }
    },
    windSpeed() {
      return this.application.wind_speed
    },
    windDirection() {
      if (!this.application.wind_direction) return;
      return [
        { label: 'N', value: 360 },
        { label: 'NE', value: 45 },
        { label: 'E', value: 90 },
        { label: 'SE', value: 135 },
        { label: 'S', value: 180 },
        { label: 'SW', value: 225 },
        { label: 'W', value: 270 },
        { label: 'NW', value: 315 },
      ].find(d => d.value === this.application.wind_direction).label;
    },
    additionalInfo() {
      return this.application.additional_info || {};
    },
    backgroundColor() {
      return this.isHerbicide ? '#73962efa' : '#948b4b';
    },
  },
   methods: {
     removeItem(source, id) {
       const url = source == 'chemicals'
                 ? `applicationchemicals/${id}`
                 : `control${source}/${id}`
       remove(url)
                   .then( res => {
                     this.$emit('updated', res);
                   }).catch( err => this.error = err);
     },
     removeEvent(){
      remove(`controlevent/${this.recordId}`)
        .then( res => {
          console.log(res)
          this.$emit('updated', res[0])
          //this.$emit('updated');
          //this.$emit('close');
        }).catch( err => this.error = err);
    },
     showEventForm(){
       const id = this.recordId;
      this.$store.dispatch('PageModals/showModal', {
        component: 'ControlEventForm',
        params: {
          id,
        }
      });
    },
    updateApplicationField(field, value) {
      const data = { [field]: value, id: this.application_id };
      this.$store.dispatch('control/updateApplicationRecord', { data });
    },
    updateEventField(field, value) {
      const data = { [field]: value, id: this.recordId };
      this.$store.dispatch('control/updateRecord', { data }).then(() => {
        this.$emit('updated');
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';

.control-event-preview {


  .control-event-header {
    color: $white;
    &>.label {
      font-size: 1.5rem;
      padding: 1rem 0.5rem;
    }
  }

  .no-data-message {
    padding: 1rem;
  }

  .row-item {
    @include row;
    padding: 0.5rem;
    .label {
      flex: 1;
    }
    .field {
      padding: 0 0.5rem;
    }
    &:not(:last-child) {
      border-bottom: $border-style;
    }
  }

  .notes {
    padding: 0.5rem;
  }
  .children {
    height: 100%;
    display: flex;
    flex-direction: column;
    //overflow-y: auto;

    .child-panel {
      flex: 1;
    }
  }
}
</style>
