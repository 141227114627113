import { fetch, post } from '@/api';
import moment from 'moment';

export const state = {
  eventFields: null,
  // displayField: 'label',
  displayFunction: (d) => d.label,
};

export const getters = {
  calendar: state => {
    if(!state.eventFields) return [];
    if(!state.data.length) return [];
    // would be nice to be able to `expand`
    // the row based on the number of fields
    // but for now Im just using the first field
    const fld = state.eventFields[0]
    return state.data
      .filter( d => !!d[fld])
      .map( d => {
      const dt = moment(d[fld])
      return {
        day: dt.format('YYYY-MM-DD'),
        dateObject: dt,
        uid: d[state.uniqueId],
        label: state.displayFunction(d),//d[state.displayField],
        type: state.collection,
        class: fld,
        data: d
      }
    })
  }
};

export const mutations = {};
export const actions = {};
