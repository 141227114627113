import { fetch, post } from '@/api';
import moment from 'moment';
import { get } from '@/utils';
import { issueEndpoints } from '@/api/resources';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations,
  getters as crudGetters,
} from './crud';

import {
  collection,
  collectionGetter,
  eventsGetter,
  mutations as collectionMutations,
  actions as collectionActions,
  getters as collectionGetters,
} from './childCollectionCRUD';

import {
  actions as deliverablesActions,
} from './deliverablesMixins'

import {
  spatialState,
  spatialLayers,
  getSpatialLayer,
  getObjectSpatialLayer,
  ADD_SPATIAL_LAYER
} from './spatialLayersMixins';

export const SET_DATA = 'SET_DATA';
export const SET_REPORTS = 'SET_REPORTS';
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_FEATURES = 'SET_FEATURES';
export const SET_BOUNDARY = 'SET_BOUNDARY';

const state = {
    ...crudState,
    ...spatialState,
  resource: 'issue',
  storeType: 'issue',
  pkey: 'issue_id',
  map: issueEndpoints,
  label: 'Issue Not Set',
  description: null,
  type: {
    label: 'No type',
    is_property_issue: false,
    is_project_issue: false
  },
  resolution: null,
  property: null,
  status: null,
  lead: { contact_id: 0, label: 'Not set'},
  boundary: null,
  geometry: null,
  discoveredOn: null,
  resolvedOn: null,
  originalTask: null,
  additionalInfo: {},
  // SPATIAL STATE
  reports: [],
  layersList: ['issuefeatures', 'properties'],
  // COLLECTION STATE
  tasks: collection(),
  //taskDeliverables: collection(),
  deliverables: collection(),
  contacts: collection(),
  messages: collection(),
  files: collection(),
  documents: collection(),
  notes: collection(),
  features: collection(),
};

const getters = {
  ...crudGetters,
  ...collectionGetters,
  // general
  baseUrl: (state) => 'issues',
  isClosed: state => !!state.resolvedOn,
  isClosable: (state, getters) => {
    return !state.resolvedOn && !getters.openWorkCount && !getters.openTaskCount && !!state.resolution
  },
  openTaskCount: (state, getters) => {
    return getters.tasks.data.filter( d => !d.closed_on).length
  },
  openWorkCount: (state, getters) => {
    return getters.deliverables.data.filter( d => !d.finished_on && d.is_required).length
  },
  editable: state => true,//!state.closedOn,
  isLead: (state, getters, rootState, rootGetters) => state.lead.contact_id === rootGetters['user/cid'],
  isAdmin: (state, getters, rootState, rootGetters) => getters.isLead || rootState.isAdmin,
  isPropertyType: state => state.type.is_property_issue,
  isProjectType: state => state.type.is_project_issue,
  statusLabel: (state, getters) => {
    if(state.resolvedOn) {
      return 'Resolved'
    } else if(getters.isClosable) {
      return 'Closable'
    } else {
      return 'Open'
    }
  },
  // COLLECTIONS
  tasks: collectionGetter('tasks', 'task_id'),
  //taskDeliverables: collectionGetter('taskDeliverables', 'task_deliverable_id'),
  deliverables: collectionGetter('deliverables', 'deliverable_id'),
  contacts: collectionGetter('contacts', 'contact_id'),
  messages: collectionGetter('messages', 'message_id'),
  files: collectionGetter('files', 'file_id'),
  documents: collectionGetter('documents', 'document_id'),
  notes: collectionGetter('notes', 'note_id'),
  // SPATIAL GETTERS
  // What is this supposed to be?? issueFeatures should be issue_feature_id
  features: collectionGetter('features', 'feature_id'),
  // issueFeatureCollection: (state, getters) => ({
  //   type: 'FeatureCollection',
  //   info: {
  //     label: 'Issue features',
  //     type: 'GeometryCollection',
  //     multi: true,
  //     editable: true,
  //     is_collection: true,
  //     api: {
  //       get: "issuefeatures/:id",
  //       put: "issuefeatures/save/:id",
  //       post: "issuefeatures/saveas",
  //       remove: "issuefeatures/remove/:id",
  //     },
  //   },
  //   style: { fillColor: 'orange' },
  //   features: getters.features.data.map(d => ({
  //     type: 'Feature',
  //     id: `${d.issue_feature_id}`,
  //     geometry: d.geometry,
  //     properties: { issue_id: d.issue_id, label: d.label },
  //   })),
  // }),
  propertyFeatureCollection: state => ({
    type: 'FeatureCollection',
    info: {
      label: 'Unit',
      type: 'Polygon',
      multi: true,
      editable: false,
      is_collection: false,
      api: {
        put: 'property/save/:id',
        post: 'property/saveas'
      }
    },
    style: { fillColor: 'transparent' },
    features: [{
      type: 'Feature',
      id: (state.property ? state.property.property_id : null),
      geometry: (state.property ? state.property.geometry : null),
    }],
  }),
  spatialLayers,
  layers: (state, getters, rootState, rootGetters) => ({
    issuefeatures: getters.issueFeatureCollection,
    properties: getters.propertyFeatureCollection,
    ...getters.spatialLayers,
  }),
  // BOARD/TOOL CONFIG
  config: ( state, getters ) => {
    let args = {
      issue: state.id,
      issueType: state.type,
      parent: 'issue',
      parentId: state.id
    }
    if(getters.isPropertyType) args.parentProperty = get(['property','property_id'],state);
    if(getters.isProjectType) args.parentProject = get(['project','project_id'],state);
    // now mix in the attributes
    return args;
  },
  // EVENTS
  issueEvents: (state, getters, rootState, rootGetters) => {
    // show discovery and resolved dates
    let day,
        events = [];
    if(state.discoveredOn) {
      day = moment(state.discoveredOn);
      events.push({
        class: 'discovered_on',
        dateObject: day,
        day: day.format('YYYY-MM-DD'),
        label: 'Discovered',
        type: 'object',
      })
    }
    if(state.resolvedOn) {
      day = moment(state.resolvedOn);
      events.push({
        class: 'resolved_on',
        dateObject: day,
        day: day.format('YYYY-MM-DD'),
        label: 'Resolved',
        type: 'object',
      })
    }
    return events;
  },
  taskEvents: eventsGetter('tasks'),
  noteEvents: eventsGetter('notes'),
  messageEvents: eventsGetter('messages'),
  calendar: (state, getters) => [
      ...getters.taskEvents,
      ...getters.issueEvents,
      ...getters.noteEvents,
      ...getters.messageEvents,
  ],
};

const mutations = {
    ...crudMutations,
    ...collectionMutations,
  ADD_SPATIAL_LAYER,
  [SET_DATA](state, meta) {
    state.label = meta.label;
    state.description = meta.description;
    state.type = meta.issue_type;
    state.lead = meta.lead;
    state.additionalInfo = meta.additional_info || {};
    state.resolution = meta.resolution;
    state.discoveredOn = meta.discovered_on;
    state.resolvedOn = meta.resolved_on;
    state.status = meta.issue_status;
    state.priority = meta.priority;
    // not sure yet how I want to deal with properties and projects
    state.property = meta.property;
    state.geometry = meta.geometry;
    state.originalTask = meta.root_task;
    // add the resolutions
    state.loaded = true;
  },
  [SET_REPORTS](state, reports) {
    state.reports = reports || [];
  },
  [SET_FEATURES](state, value) {
    state.features = value[0];
  },
  [SET_BOUNDARY](state, value) {
    if(value) state.boundary = value;
  },
  [SET_PROPERTY](state, value) {
    if(value) state.property = value;
  },
};
const actions = {
    ...crudActions,
  ...collectionActions,
  ...deliverablesActions,
  getSpatialLayer,
  getObjectSpatialLayer,
  processPageData({ state, commit, dispatch }, { meta, property, reports }){
    dispatch('getObjectSpatialLayer', {
      object: 'issue',
      id: state.id,
      key: 'issue'
    });
    commit(SET_DATA, meta);
    commit(SET_REPORTS, reports);
    commit(SET_PROPERTY, property);
  },
  setProperty({state, dispatch, commit }, { id }){
    post('propertyissues/saveas', { property_id: id, issue_id: state.id} )
      .then( res => dispatch('load', {}))
  },
  updateGeometry({ state, dispatch, getters }, { layer, id, data } ){
    // if stage.features is not null we update
    // check info from the layer
    const api = get(['layers', layer,'info','api'], getters);
    const defaults = get(['layers', layer,'info','defaults'], getters);

    if(api) {

      const url = (id ? api.put.replace(':id', id) : api.post);
      return post(url, { id, ...defaults, ...data });

    } else if(id){
      return dispatch('updateCollectionItem', {
        collection: 'issuefeatures',
        data: { id, ...data }
      })
    } else {
      return dispatch('addCollectionItem', {
        collection: 'issuefeatures',
        data: { ...data }
      })
    }
  },
};

// combine to a vuex store
export default function (options) {
  const module = {
    namespaced: true,
    state() {
      return Object.assign(JSON.parse(JSON.stringify(state)), options);
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
