<template>
<FormLayout
  class="monitoring-element-observation-form"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  @close="$emit('close')">

  <LabeledInput
    :label="label">
    <MarkdownDiv
      v-if="description"
      :text="description"/>
  </LabeledInput>

  <!-- <LabeledInput
       v-if="parcels_list && parcels_list.length"
       label="Parcel">
       <DropdownInput
       name="parcel_id"
       :multiple="false"
       v-model="record.parcel_id"
       :options="parcels_list"
       />
       </LabeledInput>
  -->
  <LabeledInput
    label="Observation date"
    :status="status.day">
    <DateInput
      slot="input"
      v-model="record.day"
      default="today"
      @status-changed="updateStatus('day', $event)"
      />
  </LabeledInput>

  <LabeledInput
    v-if="observationTypeId!=5"
    :label="question"
    :status="status.value">
    <ChecklistInput
      v-if="observationTypeId===1"
      :options="[{uid: 0, label: 'No'},{uid: 1, label: 'Yes'}]"
      :inline="true"
      slot="input"
      :required="true"
      v-model="record.value"
      @status-changed="updateStatus('value', $event)"/>
    <NumberInput
      v-if="observationTypeId>2 && !config.options"
      slot="input"
      :required="true"
      v-bind="config"
      v-model="record.value"
      @status-changed="updateStatus('value', $event)"/>
    <ChecklistInput
      v-if="config.options"
      :options="config.options"
      :inline="true"
      slot="input"
      :required="false"
      v-model="record.value"
      @status-changed="updateStatus('value', $event)"/>
  </LabeledInput>

  <LabeledInput
    :fill="true"
    label="Notes"
    :status="status.notes">
    <TextareaInput
      slot="input"
      :required="observationTypeId==5"
      v-model="record.notes"
      @status-changed="updateStatus('notes', $event)"
      />
  </LabeledInput>

  <div class="element-issues">
    <ol
      class="issue-item"
      v-for="i in issues"
      :key="i.uid">
      <li class="header">
        {{ i.label }}
      </li>
    </ol>
  </div>

  <Button
    slot="buttons"
    :disabled="!ready"
    @click="id ? save() : saveas()">
    save
  </Button>

  <Button
    slot="footer"
    @click="showIssueForm">
    New Issue
  </Button>

</FormLayout>
</template>

<script>
import { get } from '@/utils';
//import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
 import LabeledInput from '@acorns-ui/Inputs/LabeledInput'
 import TextareaInput from '@acorns-ui/Inputs/TextareaInput'
 import ChecklistInput from '@acorns-ui/Inputs/ChecklistInput';
 import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
 import DateInput from '@acorns-ui/Inputs/DateInput';
 import NumberInput from '@acorns-ui/Inputs/NumberInput';
import Button from '@acorns-ui/Inputs/Button';
import MarkdownDiv from '@acorns-ui/Text/MarkdownDiv';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';


export default {
  name: 'MonitoringElementObservationForm',
  mixins: [
    FormValidationMixins,
  ],
  props: {
    id: {
      type: [Number, String]
    },
    parentId: {
      type: [Number, String],
    },
    parent: {
      type: String,
    },
    element: {
      type: [Number, String]
    },
    parcel: {
      type: [Number, String]
    },
    source: {
      type: [Number, String]
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      saving: false,
      error: null,
      loading: true,
      inline: false,
      elementId: null,
      issues: [],
      parcels_list: [],
      record: {
        parcel_id: null,
        day: null,
        value: 0,
        notes: null,
      }
    };
  },
  components: {
    LabeledInput,
    TextareaInput,
    FormLayout,
    Button,
    ChecklistInput,
    DateInput,
    MarkdownDiv,
    NumberInput,
    DropdownInput,
  },
  created() {
    if(this.id) {
      this.get(this.id);
    } else if(!this.elementData) {
      this.getRecord();
    } else {
      this.loading = false;
    }
    if(this.parcel) {
      this.record.parcel_id = this.parcel;
    } else {
      this.getParcels();
    }
  },
  computed: {
    elementData(){
      const id = this.element || this.elementId;
      return get(['monitoringElements','records', 'u'+id], this.$store.state);
    },
    label(){
      return this.elementData && this.elementData.label || 'Observation value';
    },
    question(){
      return this.elementData && this.elementData.question || 'Observation value';
    },
    description(){
      return this.elementData && this.elementData.description;
    },
    config(){
      return this.elementData && this.elementData.config || {};
    },
    observationTypeId(){
      return this.elementData && this.elementData.monitoring_type_id || 1;
    }
  },
  methods: {
    showIssueForm(){
      const callback = this.getIssues;//() => this.$emit('updated');
      const parent = this.parent;
      const parentId = this.parentId;
      const discoveredOn = this.record.day;
      const defaults = {
        restriction_id: this.elementData.restriction_id,
      };
      this.$store.dispatch('PageModals/showModal', {
	      component: 'IssueForm',
	      params: {
          parent,
          parentId,
          discoveredOn,
          defaults,
          callback,
	      }
      })
    },
    saveas(){
      const data = this.record;
      const id = this.element || this.elementId;
      if(this.parent == 'property') {
        data.property_id = this.parentId;
      } else if(this.parent == 'parcel') {
        data.parcel_id = this.parentId;
      }
      this.$store.dispatch(`monitoringElements/addObservation`, { id, data })
        .then( res => {
          this.$emit('close')
          if(this.callback) {
            this.callback(res);
          }
        })
        .catch( err => {
          //console.trace('there was an error', err)
          this.error = err
        });
    },
    save(){
      const data = this.record;
      const id = this.id;
      delete data.parcel_id;
      this.$store.dispatch(`monitoringElements/updateObservation`, { id, data })
        .then( res => {
          this.$emit('close')
          if(this.callback) {
            this.callback(res);
          }
        })
        .catch( err => {
         // console.trace('there was an error', err)
          this.error = err
        });
    },
    getParcels() {
      console.log('getting parcels');
      const id = this.element || this.elementId;
      this.$store.dispatch('monitoringElements/fetchParcels', {
        id,
        args: { f: 'lookup' }
      })
          .then( items => {
            if(items.length > 1) {
              this.parcels_list = items;
            } else if(items.length == 1) {
              this.parcels_list = [];
              this.record.parcel_id = items[0].id;
            }
          });
    },
    getIssues(){
      console.log('getting issues');
      const id = this.element || this.elementId;
      const discoveredOn = this.record.day;
      //      this.$store.dispatch('restrictions/fetchIssues', { restrictionId, discoveredOn })
      this.$store.dispatch('monitoringElements/fetchIssues', { id, args: { discoveredOn } })
        .then( res => this.issues = res.data );
    },
    getRecord(){
      const id = this.element || this.elementId;
      const url = `propertymonitoringelements/details/${id}`;
      this.$store.dispatch('monitoringElements/getRecord', { url })
        .then( res => {
          this.loading = false;
        })
    },
    get(id){
      this.$store.dispatch('monitoringElements/getObservation', { id })
        .then( res => {
          this.elementId = res.monitoring_element_id;
          this.record.day = res.day;
          this.record.value = res.value;
          this.record.notes = res.notes;
          this.loading = false;
          this.getIssues();
        })
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .monitoring-element-observation-form {
    @include formsize;
  }
}

.monitoring-element-observation-form  {

  .form-content {
    padding: 0.5rem;
  }

  .description-input {
    flex: 1;
  }
  .element-examples-input {
    flex: 1;
  }

  .element-examples {
    overflow: auto;

    .element-example {
      cursor: pointer;
      display: block;
      &:hover {
        background-color: $hover-bkgd;
      }
      padding: 0.5rem;
      .property-label {
        font-weight: 600;
      }
    }
  }
}
</style>
