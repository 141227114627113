/* eslint-disable */
import * as types from './mutation-types';
import * as api from '../api';
//import datasetModule from '@/store/modules/dataset';
import reportModule from '@/store/modules/report2';
import templateModule from '@/store/modules/template';
import propertyModule from '@/store/modules/property2';
import projectModule from '@/store/modules/project2';
import issueModule from '@/store/modules/issue2';
import regionModule from '@/store/modules/region2';
import contactModule from '@/store/modules/contact2';
import collectionModule from '@/store/modules/collection';
import layerModule from '@/store/modules/layer';

// import roleModule from '@/store/modules/role2';

// export function getDataset({ dispatch, state, commit }, { id, filter }){
//   const moduleName = `dataset${id}`;
//   this.registerModule(moduleName, datasetModule);
//   return dispatch(`${moduleName}/getData`, { id, filter }, { root: true });
// };

export const getNavMenu = ({ commit }) => {
  return api.fetch('app/globalMenuData')
    .then( menu => {
      commit(types.GET_MENU, {
        menu
      });
    });
};

export const getOrgConfig = ({ dispatch, commit }) => {
  // placeholder, later can combine with getMenu likely
  //console.trace('getOrgConfig');
  return dispatch('Global/initialize', 'app/setup')
    .then( res => {
      commit(types.GET_ORG_CONFIG, res);
    });
  //return api.fetch('app/config').then(res => {
  //  return commit(types.GET_ORG_CONFIG, res);
  //});
};

export const openUserNav = ({ state, commit }, panel) => {
  commit('ACTIVE_USER_TAB', panel);
  commit('OPEN_USER_NAV');
};

export const showComponentHelp = ({ state, commit }, data) => {
  commit('ACTIVE_USER_TAB', 'help');
  commit('ACTIVE_USER_PANEL', 'help');
  commit('SET_COMPONENT_HELP', data);
  commit('OPEN_USER_NAV');
};

export const processLogin = ({ dispatch, commit }) => {
  // console.log('PROCESSING LOGIN')
  return dispatch('getNavMenu');
  //.then(dispatch('getNavMenu'))
  //.then(dispatch('user/getAccountInfo'));
};

// export const checkLogin = ({ dispatch, commit }, loginCreds) => {
//   return api.ecoAuthorize('user/checkLogin', loginCreds)
//     .then( rsp => {
//       if (rsp.token) {
//         localStorage.setItem('acornsToken', rsp.token);
//         commit('user/INFO', rsp.user);
//       } else {
//         throw new Error(rsp.text);
//       }
//     });
// };


export function showCollectionForm({ state, commit, dispatch }, params){
  // create the collection store
  commit('SHOW_COLLECTION_FORM', params);
}

export function initializeParent({ state, dispatch, commit }, { parent, id, key, collections }){
  if(parent === 'property') {
    return dispatch('initializeProperty', { id, key, collections });
  } else if(parent === 'template') {
    return dispatch('initializeTemplate', { id, key, collections });
  } else if(parent === 'project') {
    return dispatch('getProject', { id, key, collections });
  } else if(parent === 'issue') {
    return dispatch('initializeIssue', { id, key, collections });
  } else if(parent === 'role') {
    return dispatch('getRole', { id, key, collections });
  } else if(parent === 'contact') {
    return dispatch('initializeContact', { id, key, collections });
  } else if(parent === 'region') {
    return dispatch('initializeRegion', { id, key, collections });
  } else {
    //console.trace();
  }
}

// export function initializeCollection({ state, dispatch, commit}, { key, collection, params, args }){
//   if(!collection) return;
//   const moduleName = collection;
//   commit('REGISTER_COLLECTION', { key, collection })
//   if(!state[`${moduleName}`]) {
//     console.log('Creating collection', moduleName, params);
//     this.registerModule(moduleName, collectionModule({ collection, ...params }))
//     return dispatch(`${moduleName}/getCollectionData`, args);
//   }
//   return true;
// }

export function initializeCollection({ dispatch, state, commit }, {
  collection,
  params,
  key
}){
  if(!this._modulesNamespaceMap[`${collection}/`]) {
    this.registerModule(collection, collectionModule({ collection, ...params }));
    this.commit('REGISTER_OBJECT', {
      type: 'collection',
      id: collection,
      key
    })
  }
  return true;
};


// export function getSpatialLayer({ dispatch, state, commit }, { id, key }){
//   const moduleName = `layer${id}`;
//   if(!this._modulesNamespaceMap[`${moduleName}/`]) {
//     this.registerModule(moduleName, layerModule);
//     this.commit('REGISTER_OBJECT', { type:'layers', id, key })
//   }
//   // set the boundary for the store and get data
//   // dispatch(`${moduleName}/getData`, { id, boundary: state.boundary || [] });
//   dispatch(`${moduleName}/getInfo`, { id, boundary: state.boundary || [] });
//   // register the layer to this store
//   //commit('ADD_SPATIAL_LAYER', moduleName);
// };


export function destroyObject({ dispatch, state, commit }, { type, id, key }){
  const moduleName = `${type}${id}`;
  console.log('checking for module', type, id)
  if(this._modulesNamespaceMap[`${moduleName}/`]) {
    // first we unregister
    commit('UNREGISTER_OBJECT', { type, id, key });
    // then we check to see if its needed anymore
    if(state.registeredObjects[type] && !state.registeredObjects[type][id.toString()]){
      this.unregisterModule(moduleName);
    }
    // then we kill it
  }
};

// hopefully the url is temporary
export function getReport({ dispatch, state, commit }, { id, filter, key, url }){
  const moduleName = `report${id}`;
  if(!this._modulesNamespaceMap[`${moduleName}/`]) {
    console.log("GETTING REPORT", id);
    this.registerModule(moduleName, reportModule);
    commit('REGISTER_OBJECT', { type: 'report', id, key });
    dispatch(`${moduleName}/setup`, { id, filter, url }, { root: true });
  }
};

export function initializeTemplate({ state, dispatch, commit }, { id, key, collections }){
  const moduleName = `template${id}`;
  console.log('Creating template', moduleName, collections);
  if(!state[`${moduleName}`]) {
    this.registerModule(moduleName, templateModule({ id }));
    commit('REGISTER_TEMPLATE', { id, moduleName})
    return dispatch(`${moduleName}/load`, { collections })
  }
  return true;
}

export function initializeProperty({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `property${id}`;
  commit('REGISTER_PROPERTY', { id, key })
  if(!state[`${moduleName}`]) {
    this.registerModule(moduleName, propertyModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function getProperty({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `property${id}`;
  commit('REGISTER_PROPERTY', { id, key })
  if(!state[`${moduleName}`]) {
    this.registerModule(moduleName, propertyModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function getIssue({ state, dispatch }, { id, collections }){
  const moduleName = `issue${id}`;
  if(!state[moduleName]) {
    this.registerModule(moduleName, issueModule({ id }))
    //commit('REGISTER_ISSUE', { id, key })
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function initializeProject({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `project${id}`;
  if(!state[`${moduleName}`]) {
    this.registerModule(moduleName, projectModule({ id }))
    commit('REGISTER_PROJECT', { id, key })
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function initializeIssue({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `issue${id}`;
  commit('REGISTER_ISSUE', { id, key })
  if(!state[`${moduleName}`]) {
    console.log('Creating issue', moduleName, collections);
    this.registerModule(moduleName, issueModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    console.log('Loading collections', moduleName, collections);
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function initializeRegion({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `region${id}`;
  commit('REGISTER_REGION', { id, key })
  if(!state[`${moduleName}`]) {
    console.log('Creating region', moduleName, collections);
    this.registerModule(moduleName, regionModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    console.log('Loading collections', moduleName, collections);
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function initializeRole({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `role${id}`;
  commit('REGISTER_ROLE', { id, key })
  if(!state[`${moduleName}`]) {
    console.log('Creating role', moduleName, collections);
    this.registerModule(moduleName, roleModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    console.log('Loading collections', moduleName, collections);
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}

export function initializeContact({ state, dispatch, commit}, { id, key, collections }){
  if(!id) return;
  const moduleName = `contact${id}`;
  commit('REGISTER_CONTACT', { id, key })
  if(!state[`${moduleName}`]) {
    this.registerModule(moduleName, contactModule({ id }))
    return dispatch(`${moduleName}/load`, { collections })
  } else if(collections) {
    return dispatch(`${moduleName}/loadCollections`, { collections })
  }
  return true;
}
