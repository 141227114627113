import { fetch } from '@/api';

export const SET_FEED = 'SET_FEED';
export const SET_WIDGETS = 'SET_WIDGETS';
export const APPEND_FEED = 'APPEND_FEED';

const initialState = {
  user: null,
  widgets: [],
  feedData: [],
};

const mutations = {
  [SET_FEED](state, value) {
    state.feedData = value;
  },
  [SET_WIDGETS](state, value) {
    state.widgets = value;
  },
  [APPEND_FEED](state, value) {
    state.feedData = value;
  },
};

const getters = {};

const actions = {
  getFeed(store, args) {
    const id = store.rootState.user.info.cid;
    // console.log(store.rootState)
    const url = 'user/feed';
   fetch(url, args).then((res) => {
      const rsp = res;
      store.commit(SET_FEED, rsp);
    });
  },
  getWidgets(store) {
    const args = { id: store.rootState.user.info.cid };
    //    let url = "Contacts/contactBoard/load";
    const url = 'roles/userboard/10';
   fetch(url).then((response) => {
      const rsp = response;
      store.commit(SET_WIDGETS, rsp.notes);
    });
  },
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
