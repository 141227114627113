import { post } from '@/api';

const SET_DELIVERABLE_VIEW = 'SET_DELIVERABLE_VIEW';

export const actions = {
  saveDeliverable({ state, dispatch }, { deliverable_id, document }) {
    // save the document back to the database and update the deliverable
    // args needs to be a reference to the deliverable and the document
    const { id } = state;
    // need to add the params + document {
    // const url = `project/updateDeliverable/${id}`;
    const url = `deliverables/save/${deliverable_id}`;
    //const doc = JSON.parse(JSON.stringify(args));
    // Im having trouble with the post method screwing up deeply nested json
    // ive updated the backend storage and save method so I want to expand document
    // const args = { deliverable_id, ...document }
    post(url, document).then((res) => {
       dispatch('refreshCollectionData', { collection: 'deliverables' })
       //dispatch('refreshCollectionData', { collection: 'tasks' })
    });
  },
};
