<template>
<FormLayout
  class="control-event-form"
  :label="`${propertyLabel}: Vegetation Control Event`"
  :loaded="loaded"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  :message="loadingMessage"
  @close="$emit('close')">

  <PanelMenu slot="navigation">
    <PanelMenuItem
      v-if="!hasProperty"
      label="Property"
      :active="currentSection==='property'"
      :icon="eventMenuIcon"
      @itemClicked="updateSection('property')"/>
    <PanelMenuItem
      v-if="hasProperty"
      label="Event"
      :active="currentSection==='event'"
      :class="controlEventId ? 'complete' : 'incomplete'"
      :icon="eventMenuIcon"
      @itemClicked="updateSection('event')"/>
    <PanelMenuItem
      v-if="requiresApplication"
      :active="currentSection==='application'"
      :class="applicationId ? 'complete' : 'incomplete'"
      label="Application"
      :icon="applicationMenuIcon"
      @itemClicked="updateSection('application')">
    </PanelMenuItem>
    <PanelMenuItem
      :active="currentSection==='targets'"
      v-if="requiresTarget"
      :icon="targetsMenuIcon"
      :class="targets.length ? 'complete' : 'incomplete'"
      label="Targets"
      :badge="targets.length"
      @itemClicked="updateSection('targets')"/>
    <PanelMenuItem
      v-if="requiresChemicals"
      :active="currentSection==='chemicals'"
      :icon="chemicalsMenuIcon"
      label="Chemicals"
      :class="chemicals.length ? 'complete' : 'incomplete'"
      :badge="chemicals.length"
      @itemClicked="updateSection('chemicals')"/>

    <div
      v-if="hasProperty"
      class="property-details">
      <InfoLine
        title="state">
        {{ stateLabel }}
      </InfoLine>
      <InfoLine
        title="county">
        {{ countyLabel }}
      </InfoLine>
      <InfoLine
        title="Acres">
        {{ acres | round(1) }}
      </InfoLine>
      <InfoLine
        title="shore length">
        {{shoreLength | round(1) }} miles
      </InfoLine>
      <InfoLine
        title="Target coverage">
       {{ targetPercentTotal }}%
      </InfoLine>
    </div>

    <div class="instructions">
      <MarkdownDiv
        :text="instructions[currentSection]"/>
    </div>

  </PanelMenu>

  <FormSection
    v-show="showProperty"
    class="property-form">
    <LabeledInput>
      <label slot="label">
        Select your property
      </label>
      <DropdownInput
        v-model="potentialPropertyId"
        :multiple=false
        method="properties/lookup2"
        />
    </LabeledInput>

  </FormSection>

  <FormSection
    v-show="showEvent"
    class="event-form">

    <LabeledInput>
      <label slot="label">
        Control Method
      </label>
      <DropdownInput
        v-model="event.control_method_id"
        :multiple=false
        method="controlmethods/lookup2"
        />
    </LabeledInput>

    <LabeledInput
      label="Date and time">
      <DatetimeRangeInput
        :value="{ start: event.start_datetime, end: event.end_datetime }"
        min-interval="120"
        default="none"
        @updated="updateTimeRange"
      />
    </LabeledInput>

    <GeojsonInput
      v-if="selectedPropertyId"
      class="event-map"
      name="geometry"
      :type="[ 'LineString' , 'Polygon' ]"
      :value="event.geometry"
      :base-map="mapFeatures"
      @centerChanged="center = $event"
      @areaChanged="area = $event"
      @lengthChanged="application.additional_info.shoreline_length = $event*0.000621371"
      @updated="updateGeometry"
    />

    <a
      v-if="!includeNote"
      @click="includeNote = true"
      class="clickable">
      Add note
    </a>

    <LabeledInput
      v-if="includeNote"
      class="event-notes">
      <label slot="label">
        Notes
        <a
          @click="includeNote = false"
          class="clickable">
          hide
        </a>
      </label>
      <TextareaInput
        v-model="event.notes"/>
    </LabeledInput>

  </FormSection>

  <FormSection v-show="showApplication">

    <LabeledInput
      :label="applicators.length>1 ? 'Applicators' : 'Applicator'">
      <div>
        <span
          v-for="(a, i) in applicators"
          :key="a.contact_id">
          <span v-if="i>0">, </span> <!-- " -->
          <a
            class="applicator-item"
            @click="removeApplicator(a.control_applicator_id)">
            {{ a.contact.label }}
          </a>
        </span>
        <SearchInput
          class="applicator-input"
          method="contacts/lookup"
          new-form="ContactForm"
          new-label="New applicator"
          @selected="addApplicator"/>

      </div>
    </LabeledInput>

    <LabeledInput
      label="Application method">
      <DropdownInput
        v-model="application.application_method_id"
        :multiple=false
        :required="true"
        method="applicationmethods/lookup2"
        />
    </LabeledInput>

    <LabeledInput>
      <CheckboxInput
        slot="label"
        label="Visual Assessment? Provide rationale below"
        v-model="application.visual_assessment"
        />
      <TextareaInput
        :update-on-change="false"
        v-model="application.visual_assessment_rationale"
        />
    </LabeledInput>

    <LabeledInput>
      <label slot="label">
        Assessment of environmental conditions <br/>
        (or reason for no assessment)
      </label>
      <TextareaInput
        :update-on-change="true"
        v-model="application.environmental_conditions"
        />
    </LabeledInput>

    <LabeledInput :inline="true">
      <label slot="label">
        Threshold met by visual assessment?
      </label>
      <CheckboxInput
        v-model="application.threshold_met"
        />
    </LabeledInput>

    <LabeledInput :inline="true">
      <label slot="label">
        Wind conditions (speed and direction)
      </label>
      <TextInput
        :update-on-change="true"
        v-model="application.wind_conditions"
        />
    </LabeledInput>

    <LabeledInput>
      <label slot="label">
        Unexpected effects of treatment
      </label>
      <TextareaInput
        :update-on-change="true"
        v-model="application.unexpected_effects"
        />
    </LabeledInput>

    <LabeledInput>
      <label slot="label">
        Prior non-chemical weed-control
      </label>
      <TextareaInput
        :update-on-change="true"
        v-model="application.prior_nonchem_control"
        />
    </LabeledInput>

    <LabeledInput :inline="true">
      <label slot="label">
        Percent of area treated
      </label>
      <NumberInput
        v-model="application.percent_treated"
        />
    </LabeledInput>

    <LabeledInput :inline="true">
      <CheckboxInput
        label="Aquatic treatment?"
        v-model="application.aquatic_treatment"
        />
    </LabeledInput>

    <div class="row">
      <LabeledInput :inline="true" v-if="application.aquatic_treatment">
        <label slot="label">
          WRIA #
        </label>
        <TextInput
          v-model="application.additional_info.wria_number"
          />
      </LabeledInput>

      <LabeledInput :inline="true" v-if="application.aquatic_treatment">
        <label slot="label">
          Water Body
        </label>
        <TextInput
          v-model="application.additional_info.water_body"
          />
      </LabeledInput>

      <LabeledInput :inline="true" v-if="application.aquatic_treatment">
        <label slot="label">
          Length of shoreline (miles)
        </label>
        <NumberInput
          v-model="application.additional_info.shoreline_length"
         />
    </LabeledInput>
    </div>

  </FormSection>

  <FormSection v-show="showChemicals">

    <table>
      <th slot="th">&nbsp</th>
      <th slot="th">Product</th>
      <th slot="th">% active</th>
      <th slot="th">EPA #</th>
      <th slot="th">Vol (gal)</th>
      <tr slot="tr"
          v-for="c in chemicals"
          :key="c.application_chemical_id">
        <td><a @click="removeChemical(c.application_chemical_id)">remove</a></td>
        <td>{{ c.control_product.label }}</td>
        <td>{{ c.control_product.percent_active }}</td>
        <td>{{ c.control_product.epa_registraction_number }}</td>
        <td>{{ c.vol_gallons }}</td>
      </tr>
    </table>

    <div class="form-group row">
      <LabeledInput :inline="true" class="chemical-input">
        <label slot="label">
          Chemical Product
        </label>
        <DropdownInput
           v-model="chemical.control_product_id"
           :multiple=false
          method="controlproducts/lookup"
          new-form="CollectionForm"
          :new-form-params="{ collection: 'controlProducts' }"
           />
      </LabeledInput>


      <LabeledInput :inline="true" class="volume-input">
        <label slot="label">
          Vol (gal)
        </label>
        <NumberInput
           v-model="chemical.vol_gallons"
           />
      </LabeledInput>

      <!-- <Button -->
      <!--    v-if="showChemicals" -->
      <!--    @click="saveChemical"> -->
      <!--   Add -->
      <!-- </Button> -->

    </div>

  </FormSection>

  <FormSection v-show="showTargets">

    <table>
      <th slot="th">&nbsp</th>
      <th slot="th">Taxa</th>
      <th slot="th">Reason</th>
      <th slot="th">% of treatment</th>
      <tr slot="tr"
          v-for="t in targets"
          :key="t.control_target_id">
        <td><a @click="removeTarget(t.control_target_id)">remove</a></td>
        <td>{{ t.taxon.common_name }}</td>
        <td>{{ t.control_reason.label }}</td>
        <td>{{ t.percent_of_control }}%</td>
      </tr>
    </table>

    <div v-if="undertargetPercentTotal"  class="targets-form">
    <div class="form-group row">

      <LabeledInput class="target-input" :inline="true">
        <label slot="label">
          Target
        </label>
        <DropdownInput
          v-model="target.taxon_id"
          :multiple=false
          method="taxa/lookup"
          :allow-search="true"
          new-form="CollectionForm"
          :new-form-params="{ collection: 'taxa' }"
          new-placeholder="New spp"
          />
      </LabeledInput>

      <LabeledInput class="reason-input" :inline="true">
        <label slot="label">
          reason
        </label>
        <DropdownInput
          v-model="target.control_reason_id"
          :multiple=false
          method="controlreasons/lookup"
          />
      </LabeledInput>

      <LabeledInput :inline="true">
        <label slot="label">
          % of area
        </label>
        <NumberInput
          v-model="target.percent_of_control"
          :max="targetMax"
          :min="0"
          />
      </LabeledInput>

    </div>

    <LabeledInput class="target-notes">
      <!-- <label slot="label"> -->
      <!--   Notes -->
      <!-- </label> -->
      <TextareaInput
         v-model="target.notes"
         placeholder="Target specific notes"
         />
    </LabeledInput>

    <!-- <Button -->
    <!--    v-if="showTargets" -->
    <!--    :disabled="!targetReady" -->
    <!--    @click="saveTarget"> -->
    <!--   Add Target -->
    <!-- </Button> -->

    </div>


  </FormSection>

  <Button
     v-if="showProperty"
     slot="buttons"
     :disabled="!potentialPropertyId"
     @click="setProperty">
    Next
  </Button>

  <Button
    v-if="showEvent"
    slot="buttons"
    :disabled="!eventReady"
    :saving="saving"
    @click="saveEvent">
    Save & Next
  </Button>

  <Button
     v-if="showApplication"
     slot="buttons"
    :disabled="!applicationReady"
    :saving="saving"
     @click="saveApplication">
    Next
  </Button>

  <Button
    v-if="showTargets"
    slot="buttons"
    :disabled="!targetReady"
    :saving="saving"
    @click="saveTarget">
    Add target
  </Button>

  <Button
     v-if="showChemicals"
     slot="buttons"
    :disabled="!chemicalReady"
    :saving="saving"
     @click="saveChemical">
    Add chemical
  </Button>

</FormLayout>
</template>

<script>
// API
import { fetch, post } from '@/api';
import { get } from '@/utils';

// LAYOUT
import IconLink from '@acorns-ui/Icons/IconLink';
import MarkdownDiv from '@acorns-ui/Text/MarkdownDiv';
import FormLayout from '@acorns-ui/Layouts/FormLayout';

import PanelMenu from '@acorns-ui/Menus/PanelMenu';
import PanelMenuItem from '@acorns-ui/Menus/MenuItem';

// INPUTS
import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
import SearchInput from '@acorns-ui/Inputs/SearchInput';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
import TextInput from '@acorns-ui/Inputs/TextInput';
import NumberInput from '@acorns-ui/Inputs/NumberInput';
import TextareaInput from '@acorns-ui/Inputs/TextareaInput';
import GeojsonInput from '@acorns-ui/Inputs/GeojsonInput';
import OptionsInput from '@acorns-ui/Inputs/OptionsInput';
import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
import Button from '@acorns-ui/Inputs/Button';
import DatetimeRangeInput from '@acorns-ui/Inputs/DatetimeRangeInput'
import InfoLine from '@acorns-ui/Text/InfoLine';

import FormSection from '@/components/FormSection';
import { mapState } from 'vuex';

 //import Table from '@/components/tables/table';

 export default {
  name: 'ControlEventForm',
  props: {
    id: {
      // the control event id
      type: [String, Number],
    },
    property: {
      type: [String, Number]
    },
    inline: {
      type: Boolean,
      default: false,
    },
    parent: {
      // the parent object type
      type: String,
    },
    parentId: {
      // id of the parent object
      type: [String, Number],
    },
    geometry: {
      // geometry to use as the control event patch
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
      loadingMessage: 'Fetching your data',
      loading: false,
      saving: false,
      error: null,
      includeNote: false,
      selectedPropertyId: null,
      potentialPropertyId: null,
      area: 0,
      currentSection: 'event',
      instructions: {
        'property' : 'Select the property that you want to enter a control event for.',
        'event': 'Enter the basic information about the control event. \n\nDraw a polygon on the map showing the boundary of the coverage area. \n\nDraw the shoreline treated using the line tool. Notes are optional.',
        'targets':'Enter the taxa targeted during this event. \n\nFor each taxa targeted enter the reason and and the percent of treated area.',
        'application':'Enter the key application information. \n\nChemicals will be added in the next section.',
        'chemicals':'Enter each chemical that was used in the control event. Enter the amount applied in gallons.',
      },
      event: {
        control_event_id: null, // what control event are we working with
        control_method_id: null,
        start_datetime: null,
        end_datetime: null,
        geometry: null,
        notes: null,
      },
      application: {
        application_id: null,
        applicator_contact_id: null,
        application_method_id: 1,
        total_amount: null,
        wind_conditions: null,
        environmental_conditions: null,
        threshold_met: false,
        visual_assessment: false,
        visual_assessment_rationale: null,
        aquatic_treatment: false,
        unexpected_effects: null,
        prior_nonchem_control: null,
        percent_treated: null,
        additional_info: { water_body: null, wria_number: null, shoreline_length: null },
      },
      chemical: {
        control_product_id: null,
        vol_gallons: null,
      },
      target: {
        taxon_id: null,
        control_reason_id: null,
        percent_of_control: 0,
        notes: null,
      },
      applicator: {
        contact_id: null
      },
      chemicals: [],
      targets: [],
      applicators: [],
    };
  },
  components: {
    FormLayout,
    FormSection,
    MarkdownDiv,
    PanelMenu,
    PanelMenuItem,
    DropdownInput,
    LabeledInput,
    SearchInput,
    TextInput,
    NumberInput,
    TextareaInput,
    DatetimeRangeInput,
    GeojsonInput,
    OptionsInput,
    CheckboxInput,
    Button,
    IconLink,
    //Table,
    InfoLine,
  },
  created() {
    //this.selectedPropertyId = this.property;
    this.loaded = false;
    if(this.controlEventId) {
      this.loading = true;
      this.getEvent()
        .then( () => {
          this.getProperty(this.selectedPropertyId);
          this.loaded = true;
        })
        .finally( () => {
          this.loading = false;
        })
    } else if(this.parent === 'property') {
      this.getProperty(this.parentId);
      this.loaded = true;
    } else if(this.property) {
      this.getProperty(this.property);
      this.loaded = true;
    } else {
      this.currentSection = 'property';
      this.loaded = true;
    }
    if(!this.controlEventId && this.geometry) {
      this.event.geometry = JSON.parse(JSON.stringify(this.geometry));
    }

    // for testting
    //this.currentSection = 'targets';

    // this.application = {
    //   application_id: null,
    //   applicator_contact_id: 40,
    //   application_method_id: 1,
    //   total_amount: 50,
    //   wind_conditions: 'asdfas',
    //   environmental_conditions: 'asdfas',
    //   threshold_met: false,
    //   visual_assessment: true,
    //   visual_assessment_rationale: null,
    //   aquatic_treatment: false,
    //   unexpected_effects: 'asdfas',
    //   prior_nonchem_control: 'asdfas',
    //   additional_info: { water_body: null, wria_number: null },
    // };
  },
  watch: {},
  computed: {
    ...mapState('Global', [
      'serviceArea',
      'serviceBoundary',
    ]),
    propertyId(){
      return this.selectedPropertyId;
    },
    hasProperty(){
      return !!this.propertyId;
    },
    propertyStore(){
      return `property${this.propertyId}`;
    },
    eventMenuIcon(){
      return this.controlEventId ? 'check_circle' : 'error';
    },
    applicationMenuIcon(){
      return this.applicationId ? 'check_circle' : 'error';
    },
    chemicalsMenuIcon(){
      return this.chemicals.length>0 ? 'check_circle' : 'error';
    },
    targetsMenuIcon(){
      return this.targets.length>0 ? 'check_circle' : 'error';
    },
    controlEventId(){
      return this.event.control_event_id || this.id;
    },
    applicationId(){
      return this.application.application_id
    },
    requiresApplication(){
      return !!this.controlEventId && this.event.control_method_id === 1;
    },
    requiresTarget(){
      return !!this.controlEventId;
    },
    requiresChemicals(){
      return !!this.applicationId;
    },
    showProperty(){
      return this.currentSection === 'property';
    },
    showEvent(){
      return this.currentSection === 'event';
    },
    showApplication(){
      return this.currentSection === 'application';
    },
    showChemicals(){
      return this.currentSection === 'chemicals';
    },
    showTargets(){
      return this.currentSection === 'targets';
    },
    eventReady(){
      return !!this.event.control_method_id
        && !!this.event.start_datetime
        && !!this.event.end_datetime
        && !!this.event.geometry;
    },
    applicationReady(){
      return !!this.application.wind_conditions
        && !!this.application.environmental_conditions
        && !!this.application.threshold_met
        && !!this.application.percent_treated
    },
    targetReady(){
      return !!this.event.control_method_id
    },
    chemicalReady(){
      return !!this.applicationId
    },
    propertyLabel(){
      return get([this.propertyStore,'label'], this.$store.state);
    },
    stateLabel(){
      return get([this.propertyStore,'counties', 0, 'state' ], this.$store.state);
    },
    countyLabel(){
      return get([this.propertyStore,'counties', 0 ,'label'], this.$store.state)
    },
    shoreLength(){
      return get(['additional_info','shoreline_length'], this.application)
    },
    acres(){
      // google map returns everything in square meters
      return this.area * 0.000247105;
      //if(!this.propertyStore) return 0;
      //return get([this.propertyStore,'acres'], this.$store.state);
    },
    layers(){
      if(!this.hasProperty) return {};
      return this.$store.getters[`${this.propertyStore}/layers`];// || { property: {} }
    },
    mapFeatures() {
      if(this.propertyFeatures && this.propertyFeatures.length && this.propertyFeatures[0].geometry) {
        return this.propertyFeatures;
      } else {
        return null;
      }
    },
    propertyFeatures(){
      if(!this.hasProperty) return [];
      return get(['object', 'features'], this.layers);
    },
    targetMax(){
      return 100 - this.targetPercentTotal;
    },
    targetPercentTotal(){
      return this.targets
        .map(d => d.percent_of_control)
        .reduce((a,b) => a+b, 0)
    },
    undertargetPercentTotal(){
      return this.targetPercentTotal<100
    },
    eventStatus() {
      const id = this.controlEventId;
      let status = 0;
      if(id) {
        status = 25;
      }
      if(!this.requiresTarget | this.targets.length) {
        status += 25;
      }
      if(!this.requiresChemicals | this.chemicals.length) {
        status += 25;
      }
      if(!this.requiresApplication | this.applicationId) {
        status += 25;
      }
      return { id, status }
    }
  },
  methods: {
    setProperty(){
      this.getProperty(this.potentialPropertyId);
    },
    getProperty(id){
      this.$store.dispatch('getProperty', {
        id,
        key: this._uid,
        //collections: ['cameraPoints','photoPoints']
      }).then( () => {
        this.selectedPropertyId = id;
        this.currentSection = 'event';
      })
    },
    updateSection(s){
      this.currentSection = s;
    },
    updateTimeRange({ start, end }) {
      this.event.start_datetime = start;
      this.event.end_datetime = end;
    },
    showNextSection(){
      let sections = ["property","event"];
      if(this.requiresApplication) {
        sections.push('application');
      }
      if(this.requiresTarget) {
        sections.push('targets');
      }
      if(this.requiresChemicals) {
        sections.push('chemicals');
      }
      // which section are we on?
      let idx = sections.indexOf(this.currentSection);
      if(idx>=0 && (sections.length-1) > idx) {
        this.currentSection = sections[idx+1];
      }
    },
    updateGeometry({key, value}){
      //console.log('geometry', value);
      //value.srid = 4326;
      this.event.geometry = value;
    },
    addNewApplicator(){
    },
    getEvent(){
      // download the event
      // application, chemicals and targets
      const id = this.controlEventId;
      return fetch(`controlevent/record/${id}`)
        .then( res => {
          // fix the period
          //res.event.period = { start: res.event.start_datetime, end: res.event.end_datetime };
          this.event = res.event
          this.selectedPropertyId = res.event.property_id;
          //this.currentSection = 'event';
          if(res.application) this.application = res.application;
          if(res.targets) this.targets = res.targets;
          if(res.applicators) this.applicators = res.applicators;
          if(res.chemicals) this.chemicals = res.chemicals;
          return true;
        })
    },
    refreshCollectionData(){
      if(this.propertyStore){
        const parent = this.propertyStore;
        const collection = 'controlEvents';
        console.log('refreshing control event data');
        this.$store.dispatch(`${parent}/refreshCollectionData`, { collection });
      }
    },
    saveEvent(){
      const url = 'controlevents/upsert';
      const args = { property_id: this.propertyId, ...this.event };
      this.saving = true;
      post(url, args)
        .then(res => {
          this.event.control_event_id = res.id
          this.getEvent()
            .then( d => {
              this.showNextSection();
            })
          this.refreshCollectionData();
          this.$store.commit('PageMessages/ADD_MESSAGE', {
            type: 'record-updated',
            params: { label: `Event saved` },
            active: true
          }, {root: true});
          this.$emit('updated', this.eventStatus);
        })
        .catch( err => {
          this.error = err;
        })
        .finally( () => {
          this.saving = false;
        })
    },
    saveApplication(){
      const url = 'controlapplications/upsert';
      const args = { control_event_id: this.controlEventId, ...this.application };
      //console.log('save application', args)
      this.saving = true;
      post(url, args)
        .then((res) => {
          this.application.application_id = res.id;
          this.$emit('updated', this.eventStatus);
          this.showNextSection();
          // this.$store.commit('PageMessages/ADD_MESSAGE', {
          //   type: 'record-updated',
          //   params: { label: `Application saved` },
          //   active: true
          // }, {root: true});
        })
        .catch( err => this.error = err)
        .finally( () => this.saving = false);
    },
    saveChemical(){
      const url = 'applicationchemicals/saveas';
      const args = { application_id: this.applicationId, ...this.chemical };
      this.saving = true;
      post(url, args)
        .then((res) => {
          this.chemicals.push(res.data)
          this.chemical.control_product_id = null;
          //this.showNextSection();
          this.$emit('updated', this.eventStatus);
        })
        .catch( err => this.error = err)
        .finally( () => this.saving = false);
    },
    removeChemical(id){
      const url = `applicationchemicals/remove/${id}`;
      post(url)
        .then((res) => {
          let idx = this.chemicals.findIndex( d => d.application_chemical_id === id)
          if(idx>=0) this.chemicals.splice(idx,1);
          this.$emit('updated', this.eventStatus);
        })
    },
    saveTarget(){
      const url = 'controltargets/saveas';
      const args = { control_event_id: this.controlEventId, ...this.target };
      this.saving = true;
      post(url, args)
        .then((res) => {
          this.targets.push(res.data)
          this.target.taxon_id = null;
          this.$emit('updated', this.eventStatus)
        })
        .catch( err => this.error = err)
        .finally( () => this.saving = false);
    },
    removeTarget(id){
      const url = `controltargets/remove/${id}`;
      post(url)
        .then((res) => {
          console.log(res)
          let idx = this.targets.findIndex( d => d.control_target_id === id)
          if(idx>=0) this.targets.splice(idx,1);
          this.$emit('updated', this.eventStatus);
        })
    },
    addApplicator(contact_id){
      const url = 'controlapplicators/saveas';
      const args = {
        control_event_id: this.controlEventId,
        contact_id
      };
      post(url, args)
        .then((res) => {
          this.applicators.push(res.data)
          this.$emit('updated', this.eventStatus);
        })
    },
    removeApplicator(id){
      const url = `controlapplicators/remove/${id}`;
      post(url)
        .then((res) => {
          console.log(res)
          let idx = this.applicators.findIndex( d => d.control_applicator_id === id)
          if(idx>=0) this.applicators.splice(idx,1);
          this.$emit('updated', this.eventStatus);
        })
    },

  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
.modal {
  .tool-factory {
    .control-event-form {
      height: 83vh;
    }
  }
}


.control-event-form  {
  width: 60vw;
  height: 90vh;

  .applicator-input {
    @include input-style;
    height: 40px;
  }

  .complete {
    .icon {
      color: $green;
    }
  }

  .incomplete {
    .icon {
      color: $orange;
    }
  }

  .applicator-item {
    cursor: pointer;
    padding: 0.25rem 0 0.25rem 0.25rem;
    &:hover {
      background-color: $hover-bkgd;
   }
  }
  .form-section {
    flex: 1;
  }

  .event-map {
    flex: 1;
  }


  .chemical-input {
    flex: 1;
    margin-right: 0.5rem;
  }
  .target-input, .reason-input {
    flex: 1;
    margin-right: 0.5rem;
  }
  .volume-input {
    flex: 0 0 10rem;
  }
  .target-notes {
    min-height: 7rem;
  }

  .event-notes {
    flex: 1;
  }

  .instructions {
   // border-top: $border-style;
    padding: 0.5rem;
    //font-size: 125%;
  }

  .property-details {
    .title {
      font-weight: 600;
    }
  }
}
</style>
