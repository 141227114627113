// currently this is being used to represent the whole camera->photo->image stack
import { fetch, post } from '@/api';
import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from '@acorns-ui/Stores/Collections/Mixins/collection';

import {
  state as spatialState,
  getters as spatialGetters,
  actions as spatialActions,
  mutations as spatialMutations,
} from '@acorns-ui/Stores/Collections/Mixins/spatialCollectionMixins';

const initialState = {
  ...baseState,
  ...spatialState,
  uniqueId: 'stand_id',
  record: 'stands',
  collection: 'stands',
  label: 'Stands',
  style: { fillColor: 'tomato' },
  SystemlayerId: null,
  info: {
    label: 'Stands',
    type: 'Polygon',
    editable: true,
    is_collection: true,
    multi: true,
  },
};

const getters = {
  ...baseGetters,
  ...spatialGetters,
};

const mutations = {
  ...baseMutations,
  ...spatialMutations,
};

const actions = {
  ...baseActions,
  ...spatialActions,
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
