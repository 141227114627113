import { fetch, post } from '@/api';
import { propertyEndpoints } from '@/api/resources';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations,
  getters as crudGetters,
} from './crud';

import {
  collection,
  collectionGetter,
  eventsGetter,
  mutations as collectionMutations,
  actions as collectionActions,
  getters as collectionGetters,
} from './childCollectionCRUD';

import {
  spatialLayers,
  getSpatialLayer,
  ADD_SPATIAL_LAYER
} from './spatialLayersMixins';

import { get } from '@/utils';

export const SET_DATA = 'SET_DATA';
export const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';
export const SET_BOUNDARY = 'SET_BOUNDARY';
export const SET_RECORD = 'SET_RECORD';
export const SET_STATUS = 'SET_STATUS';
export const SET_LEAD = 'SET_LEAD';
export const UPDATE_ADDITIONAL_INFO = 'UPDATE_ADDITIONAL_INFO';

const state = {
    ...crudState,
  resource: 'property',
  storeType: 'property',
  pkey: 'property_id',
  map: propertyEndpoints,
  // meta data
  label: 'No label provided',
  description: 'No description provided',
  type: { label: 'Type not set' },
  lead: { label: 'No lead' },
  status: { label: 'UK' },
  isFee: true,
  conservationTool: null,
  additionalInfo: {}, // extra information based on property type
  region: { label: 'No region', lead_contact_id: null },
  geometry: null,
  acres: 0,
  counties: [],
  // template data
  standardProjectTemplates: [ 2 , 4 ],
  // layersList: ['property', 'parcels', 'cameraPoints'],
  spatialLayersList: ['property', 'parcels', 'cameraPoints', 'stands'],
  // collections
  approvals: collection(),
  issues: collection(),
  messages: collection(),
  tasks: collection(),
  contacts: collection(),
  files: collection(),
  documents: collection(),
  notes: collection(),
  projects: collection(),
  observations: collection(),
  restrictions: collection(),
  cameraPoints: collection(),
  stands: collection(),
  photoPoints: collection(),
  photoPointImages: collection(),
  parcels: collection(),
  controlEvents: collection(),
  propertyStatuses: collection(),
  monitoringElements: collection(),
  accounts: collection(),
};

const getters = {
  // general
  ...crudGetters,
  ...collectionGetters,
  baseUrl: (state, getters, rootState) => rootState.propertyLabelPlural,
  editable: state => true,//!state.closedOn,
  isLead: (state, getters, rootState, rootGetters) => {
    if(!state.lead) return false;
    return state.lead.contact_id === rootGetters['user/cid']
  },
  hasNonStandardProjects: (state, getters) => {
    //if(!getters.nonStandardProjects) return false;
    return getters.nonStandardProjects.data.length>0
  },
  // Collections
  approvals: collectionGetter('approvals', 'approval_id'),
  issues: collectionGetter('issues', 'issue_id'),
  messages: collectionGetter('messages', 'message_id'),
  tasks: collectionGetter('tasks', 'task_id'),
  contacts: collectionGetter('contacts', 'contact_id'),
  files: collectionGetter('files', 'file_id'),
  documents: collectionGetter('documents', 'document_id'),
  notes: collectionGetter('notes', 'note_id'),
  projects: collectionGetter('projects', 'project_id'),
  observations: collectionGetter('observations', 'observation_id'),
  restrictions: collectionGetter('restrictions', 'restriction_id'),
  stands: collectionGetter('stands', 'stand_id'),
  cameraPoints: collectionGetter('cameraPoints','camera_point_id'),
  photoPoints: collectionGetter('photoPoints','photo_point_id'),
  photoPointImages: collectionGetter('photoPointImages','photo_point_image_id'),
  parcels: collectionGetter('parcels', 'parcel_id'),
  controlEvents: collectionGetter('controlEvents', 'control_event_id'),
  monitoringElements: collectionGetter('monitoringElements', 'property_monitoring_element_id'),
  propertyStatuses: collectionGetter('propertyStatuses', 'property_status_id'),
  accounts: collectionGetter('accounts', 'account_id'),
  nonStandardProjects: (state, getters, rootState) => ({
    meta: state.projects.meta,
    fields: state.projects.fields,
    data: rootState.projects.data.filter(d => {
      return state.projects.ids.includes(d.project_id) && !state.standardProjectTemplates.includes(d.template_id)
    }),
  }),
  // spatial getters
  spatialLayers,
  propertyFeatureCollection: (state, getters, rootState) => ({
    type: 'FeatureCollection',
    id: 1, // this is the layer id
    info: {
      label: rootState.propertyLabelSingular,
      type: 'Polygon',
      id: 'property',
      multi: true,
      editable: true,
      is_collection: false,
    },
    style: {
      fillColor: 'gray'
    },
    features: !state.geometry ? [] : [
      {
        type: 'Feature',
        id: `${state.id}`,
        geometry: state.geometry,
      }
    ],
  }),
  // cameraPoints: (state, getters, rootState, rootGetters) => {
  //   if (!rootState.cameraPoints) return { data: [] };
  //   return {
  //     meta: state.cameraPoints.meta,
  //     fields: state.cameraPoints.fields,
  //     data: rootGetters['cameraPoints/data']
  //       .filter(d => state.cameraPoints.ids.includes(d.camera_point_id)),
  //   };
  // },
  layers: (state, getters, rootState, rootGetters) => ({
    property: getters.propertyFeatureCollection,
    ...( state.cameraPoints.loaded ? { cameraPoints: rootGetters['cameraPoints/featureCollection'] } : {} ),
    ...( state.stands.loaded ? { stands: rootGetters['stands/featureCollection'] } : {} ),
    ...( state.parcels.loaded ? { parcels: rootGetters['parcels/featureCollection'] } : {} ),
    ...getters.spatialLayers,
  }),
  // event getters
  taskEvents: eventsGetter('tasks'),
  projectEvents: eventsGetter('projects'),
  noteEvents: eventsGetter('notes'),
  issueEvents: eventsGetter('issues'),
  messageEvents: eventsGetter('messages'),
  statusEvents: eventsGetter('propertyStatuses'),
  accountEvents: eventsGetter('accounts'),
  calendar: (state, getters) => [
      ...getters.projectEvents,
      ...getters.taskEvents,
      ...getters.issueEvents,
      ...getters.noteEvents,
      ...getters.messageEvents,
    ...getters.statusEvents,
    ...getters.accountEvents,
  ],
};

const mutations = {
    ...crudMutations,
    ...collectionMutations,
  ADD_SPATIAL_LAYER,
  [SET_DATA](state, { meta }) {
    state.label = meta.label;
    state.description = meta.description;
    //state.acquiredOn = meta.acquired_on;
    state.type = meta.property_type;
    state.lead = meta.lead;
    state.region = meta.region;
    state.counties = meta.counties;
    state.geometry = meta.geometry;
    state.acres = meta.acres;
    state.additionalInfo = meta.additional_info || {};
    state.status = meta.property_status;
    state.conservationTool = meta.conservation_tool;
    state.isFee = meta.is_fee;//meta.conservation_tool.is_fee;
    state.loaded = true;
  },
 [SET_TEMPLATE_DATA](state, template) {
    if(template.collections) state.standardCollections = template.collections || [];
    if(template.layers_list) state.layersList = template.layers_list;
    state.spatialLayersList = template.spatial_layers_list || [];
    if(template.standard_project_templates) state.standardProjectTemplates = template.standard_project_templates || [];
 },
  [SET_BOUNDARY](state, value) {
    state.boundary = value;
  },
  [SET_RECORD](state, value) {
    state.record = value;
  },
  [SET_STATUS](state, value) {
    state.status = value;
  },
  [SET_LEAD](state, value) {
    state.lead = value;
  },
  [UPDATE_ADDITIONAL_INFO](state, value) {
    state.additionalInfo = Object.assign(state.additionalInfo, value);
  },
};
const actions = {
    ...crudActions,
    ...collectionActions,
  getSpatialLayer,
  load({ state, commit, dispatch }, { collections }) {
    const { id } = state;
    const url = `${state.resource}/pageData/${id}`;
    fetch(url).then((rsp) => {

      commit(SET_DATA, rsp);
      commit(SET_TEMPLATE_DATA, rsp.template);
      commit(SET_BOUNDARY, rsp.boundary);
      commit(SET_RECORD, rsp.record);

    })
    if(collections && collections.length){
      dispatch('loadCollections', { collections })
    }
    //dispatch('loadLayers', { layers: [24] })
  },
  // updateStatus({ state, commit, dispatch},  data) {
  //   const url = `propertystatuses/saveas`;
  //   const args = { property_id: state.id, ...data }
  //   return post(url, args).then((res) => {
  //     commit('SET_STATUS', res.data)
  //     dispatch('refreshCollectionData', { collection: 'propertyStatuses' });
  //     return true;
  //   });
  // },
  updateAdditionalInfo({ state, commit, dispatch},  data) {
    const url = `property/updateAttributes`;
    const args = { property_id: state.id, ...data }
    return post(url, args).then((res) => {
      // commit the values
      //dispatch('reload', {})
      commit(UPDATE_ADDITIONAL_INFO, data)
      return true;
    });
  },
  updateStatus({ state, commit, dispatch},  data) {
    const url = data.id ? `propertystatuses/save` : `propertystatuses/saveas`;
    const args = { property_id: state.id, ...data }
    return post(url, args).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  removeStatus({ state, commit, dispatch},  { id }) {
    const url = `propertystatuses/remove/${id}`;
    return post(url).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  statusHistory({ state }) {
    const url = `propertystatuses/load`;
    const args = { property_id : state.id }
    return fetch(url, args).then((res) => {
      return res;
    });
  },
  updateLead({ state, commit, dispatch},  data) {
    const url = data.id ? `propertyleads/save` : `propertyleads/saveas`;
    const args = { property_id: state.id, ...data }
    return post(url, args).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  removeLead({ state, commit, dispatch},  { id }) {
    const url = `propertyleads/remove/${id}`;
    return post(url).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  leadHistory({ state }) {
    const url = `propertyleads/load`;
    const args = { property_id : state.id }
    return fetch(url, args).then((res) => {
      return res;
    });
  },
};

// combine to a vuex store
export default function (options) {
  const module = {
    namespaced: true,
    state() {
      return Object.assign(JSON.parse(JSON.stringify(state)), options);
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
