import { fetch, post } from '@/api';

export const UPDATE_RECORD = 'UPDATE_RECORD';
export const TOGGLE_CHECKLIST_ITEM = 'TOGGLE_CHECKLIST_ITEM';
export const EXPAND_CHECKLIST_ITEM = 'EXPAND_CHECKLIST_ITEM';
export const COLLAPSE_CHECKLIST_ITEM = 'COLLAPSE_CHECKLIST_ITEM';
const ADD_NOTE = 'ADD_NOTE';
const REPLACE_ITEM = 'REPLACE_ITEM';


import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from './collection';

const initialState = {
  ...baseState,
  uniqueId: 'checklist_id',
  record: 'checklist',
  collection: 'checklists',
};

const getters = {
  ...baseGetters,
  events: state => state.data,
  requiredItems: (state) => {
    return state.data.length;
  },
  completedRequiredItems: (state) => {
    return state.data.filter( d => {
      return !!d.finished_on
    }).length;
  },
  percentComplete: (state, getters) => {
    // count of checklist items that are required
    return (getters.completedRequiredItems/getters.requiredItems)*100
  }
};


const mutations = {
    ...baseMutations,
  [TOGGLE_CHECKLIST_ITEM](state, { project_id, checklist_id }) {
    const idx = state.data.findIndex(d => d.project_id === project_id && d.checklist_id === checklist_id)
    if(idx >= 0) {
      state.data[idx].expanded = !state.data[idx].expanded;
      Vue.set(state.data, idx, state.data[idx]);
    }
  },
  [EXPAND_CHECKLIST_ITEM](state, { project_id, checklist_id}) {
    const idx = state.data.findIndex(d => d.project_id === project_id && d.checklist_id === checklist_id)
    if(idx >= 0) {
      state.data[idx].expanded = true;
      Vue.set(state.data, idx, state.data[idx]);
    }
  },
  [COLLAPSE_CHECKLIST_ITEM](state, { project_id, checklist_id}) {
    const idx = state.data.findIndex(d => d.project_id === project_id && d.checklist_id === checklist_id)
    if(idx >= 0) {
      state.data[idx].expanded = false;
      Vue.set(state.data, idx, state.data[idx]);
    }
  },
  [REPLACE_ITEM](state, data ) {
    let idx;
    const { project_id, checklist_id } = data
    idx = state.data.findIndex(d => d.project_id === project_id && d.checklist_id === checklist_id)
    if(idx >= 0) {
      // is it expanded??
      data.expanded = state.data[idx].expanded;
      Vue.set(state.data, idx, data);
    }
  },
};

const actions = {
    ...baseActions,
  getRecord({ state, commit }, { url, params }) {
    const endpoint = url || state.record;
    const address = `project/checklist`;
    return fetch(address, params).then((res) => {
      commit(UPDATE_RECORD, res);
      return res[0]
    });
  },
  close({ state, commit, dispatch }, args) {
    const url = `projectchecklist/close`;
    return post(url, args)
      .then( res => {
        dispatch('refreshChecklist', args)
      });
  },
  reopen({ state, commit, dispatch }, args) {
    const url = `projectchecklist/reopen`;
    return post(url, args)
      .then( res => {
        dispatch('refreshChecklist', args)
      });
  },
 addNote({ state, commit, dispatch }, { project_id, checklist_id, note }){
    // we might not have a project_checklist_id at this point
    const url = `projectchecklist/addNote`
    const params = { project_id, checklist_id, note }
    return post(url, params)
      .then(res => {
        dispatch('refreshChecklist', params)
      })
 },
  removeNote({ state, dispatch }, { id, project_id, checklist_id }){
    // we might not have a project_checklist_id at this point
   const url = `projectchecklistnotes/remove`;
   return post(url, { id })
     .then(res => {
       dispatch('refreshChecklist', { project_id, checklist_id })
     })
 },
  refreshChecklist({ state, commit }, { project_id, checklist_id }){
    const url = `projectchecklists/load`
    const params = { project_id, checklist_id }
    return fetch(url, params)
      .then( data => {
        console.log(data)
        commit(REPLACE_ITEM, data)
      })
  }
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
