<template>
<ObjectPageLayout
  class="map-page"
  :include-sidebar="false"
  >

  <MapSidebar
    slot="sidebar"/>

  <div class="actions" slot="pagebar">
    <IconLink
      title="Add layer to map"
      class="lighten"
      @clicked="openLayersList">
      add
    </IconLink>
  </div>

  <router-view
    slot="content"
    parent="map"
    :store="store"
    :update-boundary-on-change="true"
    name="workspace"
    @ready="loadSpatialLayers">
  </router-view>

  <ModalContainer
    slot="modal"
    v-if="showLayersList"
    @close="closeLayersList">
    <LayersList
      :store="store"
      :boundary="mapEnvelope"
      :zoom="mapZoom"
      @close="closeLayersList"/>
  </ModalContainer>

</ObjectPageLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, clone } from '@/utils'
import ObjectPageLayout from '@acorns-ui/Layouts/ObjectPageLayout';
import ModalContainer from '@acorns-ui/Layouts/ModalContainer';
import IconLink from '@acorns-ui/Icons/IconLink'

import MapSidebar from './MapSidebar';
import LayersList from '@acorns-ui/Maps/LayersList';

export default {
  name: 'MapPage',
  props: {
    //    id: { type: [String, Number], default: 4 }
  },
  data() {
    return {
      //showLayersList: false,
    };
  },
  components: {
    ObjectPageLayout,
    ModalContainer,
    LayersList,
    MapSidebar,
    IconLink,
  },
  created() {

  },
  watch: {
    currentLayerIds(a, b){
      const id = a.join(',')
      var { query, path, hash } = this.$route;
      if(id!=query.id) {
        query = { ...query, id };
        this.$router.push({ query, path, hash});
      }
    }
  },
  computed: {
    store(){
      return `map`;
    },
    currentLayerIds() {
      return this.$store.state[this.store].currentLayerIds;
    },
    layerOrder() {
      return this.$store.state[this.store].layerOrder;
    },
    spatialLayersList() {
      return this.$store.state[this.store].spatialLayersList;
    },
    showLayersList(){
      return this.$route.hash == '#add'
    },
    mapEnvelope(){
      return get([this.store, 'mapEnvelope'], this.$store.state);
    },
    mapZoom(){
      return get([this.store, 'mapZoom'], this.$store.state);
    },
  },
  methods: {
    loadSpatialLayers(boundary) {
      // we want to wait until the map is ready otherwise we dont have a boundary
      //console.log('page is ready', boundary);
      if (this.$route.query.id) {
        const layers = this.$route.query.id.split(',');
        this.$store.dispatch(`${this.store}/fetchSpatialLayers`, { layers, boundary });
      }
    },
    closeLayersList(){
      const { query, path, hash } = this.$route;
      this.$router.push({ query, path });
    },
    openLayersList(){
      const { query, path } = this.$route;
      this.$router.push({ query, path, hash: '#add' });
    },
    showCollectionForm(){
      this.$store.dispatch('PageModals/showModal', {
        component: 'LayerForm',
        params: {
          callback: d => this.$store.dispatch(`${this.store}/fetchLayer`, d.id)
        }
      })
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
.map-page  {

}
</style>
