<template>
<div class="ControlEventTool">
  <!-- Display Component -->
  <ControlEventToolDisplay
    v-if="displaying"
    :id="controlEventId"
    :reporting="reporting"/>
  <!-- Edit Component -->
  <ControlEventForm
    v-if="editing"
    :inline="true"
    :id="controlEventId"
    :property="propertyId"
    @updated="updateEvent"/>
  <!-- Config Component -->
  <ControlEventToolConfig
    v-if="configuring"
    :defaults="{ }"
    @updated="updateConfig"/>
</div>
</template>

<script>
import ToolMixin from '@acorns-ui/Tools/Mixins/ToolMixin';
// import ControlEventToolEdit from './ControlEventToolEdit'
import ControlEventToolConfig from './ControlEventToolConfig'
import ControlEventToolDisplay from './ControlEventToolDisplay'
import ControlEventForm from '../ControlEventForm';

export default {
  name: 'ControlEventTool',
  mixins: [ ToolMixin ],
  props: {
    property: {
      type: [String, Number],
    },
  },
  data() {
    return {
      propertyId: null,
      controlEventId: null,
      controlEventStatus: 0,
    };
  },
  components: {
    // ControlEventToolEdit,
    ControlEventToolConfig,
    ControlEventToolDisplay,
    ControlEventForm,
  },
  created() {
    if(this.parent === 'property'){
      this.propertyId = this.parentId;
    } else if(this.property) {
      this.propertyId = this.property;
    }
  },
  watch: {},
  computed: {
    // property(){
    //   if(this.parent === 'property'){
    //     return this.parentId;
    //   }
    // },
    status(){
      return this.controlEventStatus;
    },
    value(){
      return this.controlEventId;
    },
  },
  methods: {
    updateEvent(d){
      //console.log('update event', d)
      if(d && d.id) {
        this.controlEventId = d.id;
        this.controlEventStatus = d.status;
        this.updated();
      }
    },
    initialize(){
      // enter startup code here
    }
  }
};
</script>
<style lang="scss">
@import '~@/styles/variables';
.ControlEventTool  { }
</style>
