import { fetch, post } from '@/api';
import { taskDeliverablesEndpoints } from '@/api/resources';

import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from './collection';

const initialState = {
  ...baseState,
  uniqueId: 'task_deliverable_id',
  record: 'taskdeliverable',
  collection: 'taskdeliverables',
  map: taskDeliverablesEndpoints,
};

const getters = {
  ...baseGetters,
};

const mutations = {
  ...baseMutations,
};

const actions = {
  ...baseActions,
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
