import { fetch, post, fetchRecord, fetchCollection } from '@/api';
import { assign } from '@/utils';

export const SET_RECORDS = 'SET_RECORDS';
export const SET_INPUTS = 'SET_INPUTS';
export const SET_REPORTS = 'SET_REPORTS';
export const SET_RECORD_DATA = 'SET_RECORD_DATA';
export const MERGE_RECORDS = 'MERGE_RECORDS';
export const SET_COLLECTION = 'SET_COLLECTION';
export const APPEND_RECORDS = 'APPEND_RECORDS';
export const SET_UNIQUEID = 'SET_UNIQUEID';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const MODIFY_RECORD = 'MODIFY_RECORD';
export const REMOVE_RECORD = 'REMOVE_RECORD';
export const ADD_RECORD = 'ADD_RECORD';

export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const EXPAND_ITEM = 'EXPAND_ITEM';
export const COLLAPSE_ITEM = 'COLLAPSE_ITEM';
export const EXPAND_ITEMS = 'EXPAND_ITEMS';
export const COLLAPSE_ITEMS = 'COLLAPSE_ITEMS';

export const TOGGLE_SELECTED = 'TOGGLE_SELECTED';
export const SELECT_ITEM = 'SELECT_ITEM';
export const UNSELECT_ITEM = 'UNSELECT_ITEM';
export const SELECT_ITEMS = 'SELECT_ITEMS';
export const UNSELECT_ITEMS = 'UNSELECT_ITEMS';

export const initialState = {
  uniqueId: null, // string representing unique id
  record: null, // singular
  collection: null, // name of the collection
  form: 'SHOW_COLLECTION_FORM',
  inputs: [], // these are the inputs needed to edit/add
  meta: {},
  fields: [],
 // filter: {}, // filter used for getting data
  data: [], // actual data
//  keys: {}, // storing who belongs to what
  records: {}, // Detail data, keyed by uniquid
  rawRecords: {}, // Raw record data, keyed by unique
  history: [], // future ??
  expanded: [],
  selected: [],
  reports: [],
  addedMessageType: 'record-updated',
  api: {
    get: 'details/:id',
    put: 'save/:id',
    post: 'saveas',
    remove: 'remove/:id',
    unlink: 'unlink/:id',
    search: 'search2',
    record: 'record',
  }
};

export const getters = {
  count: state => state.data.length,
  label: state => state.meta.label || state.label || state.collection,
  api: (state) => (verb, id) => {
    const uri = `${state.collection}/${state.api[verb]}`;
    return id ? uri.replace(':id', id) : uri.replace('/:id', '');
  },
  data: state => {
    return state.data.map( d => {
      return {
          ...d,
        expanded: state.expanded.includes(d.uid),
        selected: state.selected.includes(d.uid)
      }
    })
  }
};

export const mutations = {
  [SET_UNIQUEID](state, value) {
    state.uniqueId = value;
  },
  [SET_RECORDS](state, values) {
    if (values) {
      state.data = values.data || values || [];
    }
  },
  [SET_RECORD_DATA](state, data) {
    if(data) Vue.set(state.rawRecords, data[state.uniqueId], data)
  },
  [SET_INPUTS](state, inputs) {
    // console.log('inputs', inputs)
    if(inputs) state.inputs = inputs;
  },
  [SET_REPORTS](state, reports) {
    state.reports = reports || [];
  },
  [MERGE_RECORDS](state, values) {
    const t0 = performance.now();
    if(!values) return;
    // quit merging for now
    state.data = values.data || values || [];

    if (state.data.length === 0) {
      //console.log('replacing records', state.record, t0)
      state.data = values.data || values || [];
    } else {
      //console.log('merging records', state.record, t0)
      // merge willy nilly
      const uid = state.uniqueId;
      const ids = values.map( d => d[uid])
      // filter out the duplicates
      const data = state.data.filter( d => !ids.includes(d[uid]))
      //console.log('filtered', performance.now()-t0)
      // but merge the duplicate data, this will make sure we have all the required data
      values.map(x => Object.assign(state.data.find(y => y[uid] == x[uid])||{}, x));
      //console.log('Mapped', performance.now()-t0, values.length, state.data.length, data.length)
      // and put it all back
      state.data = [...data, ...values ]
      // console.log(state.record, state.uniqueId, ids, data, state.data)
    }
    //console.log('merged records', performance.now()-t0)
  },
  [SET_COLLECTION](state, col) {
    //console.log('set collection', state.collection, col)
    if (col.meta) state.meta = col.meta || {};
    if (col.fields) state.fields = col.fields || [];
    if (col.data) state.data = col.data || [];
  },
  [APPEND_RECORDS](state, values) {
    if (values) state.data = state.data.concat(values);
  },
  [UPDATE_RECORD](state, value) {
    if(!value) return;
    //    const uid = `u${value[state.uniqueId]}`;
    // having an issue with feature_id vs issue_feature_id
    // and with deliverable_id vs project_deliverable_id (with deliverables)
    const uid = `u${value['uid']}`;
    //console.log('UPDATE_RECORD', state.uniqueId, value, uid)
    // have to use the set method to get a refresh
    Vue.set(state.records, uid, value);
  },
  [ADD_RECORD](state, value) {
    // console.log('COLLECTION: ADD_RECORD');
    state.data = [...state.data, value];
  },
  [REMOVE_RECORD](state, { id }) {
    // console.log('COLLECTION: ADD_RECORD');
    const pkey = 'uid';//state.uniqueId;
    const idx = state.data.findIndex(obj => parseInt(obj[pkey]) === id);
    if (idx >= 0) state.data.splice(idx, 1);
  },
  [MODIFY_RECORD](state, { id, data }) {
    const pkey = 'uid';//state.uniqueId;
    const idx = state.data.findIndex(obj => parseInt(obj.uid) === id);
    console.log('COLLECTION: Modify record', id, idx);
    if (idx >= 0) state.data[idx] = Object.assign(state.data[idx], data)
  },
  // this method of toggling seems to recreate all components based on the data
  // which means that the expanded watcher is not being triggered
  // for now we can put the expanded watcher in the create method
  [TOGGLE_ITEM](state, { id }) {
    const idx = state.expanded.findIndex(d => d === id)
    if(idx>=0){
      Vue.delete(state.expanded, idx)
    } else {
      state.expanded.push(id)
    }
    //console.log('togggle item',id, idx)
  },
  [EXPAND_ITEM](state, { id }) {
    const idx = state.expanded.findIndex(d => d === id)
    if(idx < 0) {
      state.expanded.push(id)
    }
  },
  [EXPAND_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].expanded = true;
        Vue.set(state.data, i, state.data[i]);
      }
    }
  },
  [TOGGLE_SELECTED](state, { id }) {
    const idx = state.selected.findIndex(d => d === id)
    if(idx >= 0){
      Vue.delete(state.selected, idx)
    } else {
      state.selected.push(id)
    }
    //console.log('toggle selected', state.record, id, idx)
  },
  [SELECT_ITEM](state, { id }) {
    if(!state.selected.includes(id)) {
      state.selected.push(id)
    }
  },
  [UNSELECT_ITEM](state, { id}) {
    const idx = state.data.findIndex(d => d.uid === id)
    if(idx >= 0) {
      state.data[idx].selected = false;
      Vue.set(state.data, idx, state.data[idx]);
    }
  },
  [SELECT_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].selected = true;
        Vue.set(state.data, i, state.data[i]);
      }
    }
  },
  [UNSELECT_ITEMS](state, ids) {
    for(let i = 0; i<state.data.length; i++){
      let { uid } = state.data[i];
      if(!ids || ids.includes(uid)) {
        state.data[i].selected = false;
        Vue.set(state.data, i, state.data[i]);
      }
    }
  },
};

export const actions = {
  setCollectionInfo(context, { uniqueId }) {
    context.commit(SET_UNIQUEID, uniqueId);
  },
  // this is to be used when not part of a parent module
  getCollectionData({state, dispatch, commit}, args) {
    const url = `${state.collection}/search2`
    return fetch(url, args)
      .then( res => {
        console.log('fetched collection data', res)
        commit('SET_COLLECTION', res)
        return res
      })
  },
  getReports({state, dispatch, commit}, args) {
    const url = `${state.collection}/reports`
    return fetch(url, args)
      .then( res => {
        commit('SET_REPORTS', res.data || res)
        return res
      })
  },
  search({state, dispatch, commit}, args) {
    const url = `${state.collection}/search2`
    return fetch(url, args)
      .then( res => {
        return res
      })
  },
  // The purpose here is to have a generic function that can
  // grab the right inputs, create a form and submit the results of that
  // form to an update function
  // id is the primary key value of the record being updated
  // field is the field we need an input for
  // the url is used to override the current collection url and could be replaced later
  // the callback is to provide some method to notifiy the caller that we are done
  editRecord({ state, dispatch, commit, getters }, { url, id, callback, defaults, parent, parentId, field }) {
    //const address = url || (id
    //                        ? `${state.record}/record/${id}`
    //                        : `${state.record}/record`);
    const address = url ? url : getters.api('record', id);

    // check for inputs and data
    // if not we need to load the inputs we may need
    console.log('collection: edit record', state.record, address, url)
   fetch(address).then(res => {
      commit(SET_INPUTS, res[0].inputs)
      commit(SET_RECORD_DATA, res[0].data)
    })
    // open the form
    const { collection } = state;
    commit(state.form, { id, collection, callback, defaults, parent, parentId, field }, { root: true });
  },
  editField({ state, dispatch, commit }, {
    id, field, url, callback,
  }) {
    // need to get the raw data from the server
    // then provide a form
    let component = 'basic-form';
    let params = {};
    let inp;
    let err = '';

    const address = url || state.record;
    // start working
   fetch(`${address}/record/${id}`).then( record => {

      if (record.inputs) {
        inp = record.inputs.find(obj => obj.name === field);
      } else {
        err = 'The record variable has not been set';
      }

      if (typeof (inp) === 'object') {
        params = {
          inputs: [inp],
          id,
          data: record.data,
          callback(data) {
            // we should reload and not update
            // but I need to now what called this
            // this is a mess, the url pass above will not work here
            // url should really be resource but Im not sure if I can make
            // that change without breaking something else
            let u = `${address}/save/${id}`
            dispatch('updateRecord', { url: u, data, callback });
          },
        };
      } else {
        component = 'message-window';
        params = {
          label: 'Form error',
          message: err || `Could not find the input '${inp}'`,
        };
      }
      // here we are creating a form and telling it that
      // the submit should run the updateRecord method
      commit('SHOW_MODAL', {
        name: component,
        params,
      }, { root: true });
    });
  },
  // closeRecord(store, data){
  //   const url = 'record/close';
  //   post(url, data).then( res => {
  //     store.commit(UPDATE_RECORD, res[0].data);
  //   });
  // },
  // openRecord(store, data){
  //   const url = 'record/reopen';
  //   post(url, data).then( res => {
  //     console.log(res)
  //     store.commit(UPDATE_RECORD, res[0].data);
  //   });
  // },
  // to provide the option to pass the endpoint/method
  // along with the data
  postMethod({ state, commit }, { method, data }) {
    const url = `${state.record}/${method}`;
    return post(url, data).then((res) => {
      // console.log(res)
      // commit(UPDATE_RECORD, res[0].data);
    });
  },
  getEditHistory({ state, commit }, { url, id }) {
    const address = url || `${state.record}/audit/${id}`;
    return fetch(address).then((res) => {
      console.log(res)
      return res // changed this from true
    });
  },
  getRecord({ state, commit }, { url, id }) {
    //   const endpoint = url || state.record;
    const address = url || `${state.record}/details2/${id}`;
    return fetch(address).then((res) => {
      commit(UPDATE_RECORD, res);
      return res // changed this from true
    });
  },
  getRecordRaw({ state, commit }, { url, id }) {
    const address = url || `${state.record}/record/${id}`;
    console.trace('get raw record data', address, id, url)
    return fetch(address).then((res) => {
      commit(SET_RECORD_DATA, res.data || res)
      commit(SET_INPUTS, res.inputs)
      return res
    });
  },
  getInputs({ state, commit }, { url }) {
    const address = url || `${state.record}/record`;
    return fetch(address).then((res) => {
      commit(SET_INPUTS, res.inputs)
      return res
    });
  },
  addRecord({ state, commit }, { url, data }) {
    const endpoint = state.collection;
    const address = url || `${endpoint}/saveas`;
    console.log('collection add record', address)
    return post(address, data).then( res => {
      // should we be updating or refreshing here?
      commit('PageMessages/ADD_MESSAGE', {
        type: state.addedMessageType,
        params: { label: 'Record added' },
        active: true
      }, {root: true});
      return res.id;
    })
  },
  removeRecord({ state, commit, getters }, { url, data }) {
    const uri = url || getters.api('remove', data.id);
    console.log('collection remove record', uri, data)
    return post(uri, data).then( res => {
      // should we be updating or refreshing here?
      // we have only removed one so Im going to delete it here
      commit('REMOVE_RECORD', data);
      commit('PageMessages/ADD_MESSAGE', {
        type: 'record-deleted',
        params: { label: 'Record deleted' },
        active: true
      }, {root: true});
      return res;
    })
  },
  updateRecord({ state, commit, getters}, { url, id, data, callback }) {
    // if we are calling this from a parent store we have passed
    // a url to use, otherwise we can pull one from the collection
    const uri = url ? url : getters.api('put', id);
    console.log('update record data', id, url, uri)
    return post(uri, data).then((res) => {
      console.log('UPDATE RECORD', res)
      if (callback) {
        callback(res);
      } else {
        if(res.data) commit(UPDATE_RECORD, res.data);
      }
      commit('PageMessages/ADD_MESSAGE', {
        type: state.addedMessageType,
        params: { label: 'Record updated' },
        active: true
      }, {root: true});
      return res;
    });
  },
  insertChildItem({ state, dispatch }, { child, data, id }) {
    const { post } = state.map[child];
    const url = post;
    return post(url, data);// .then((res) => {
    //   dispatch('getRecord', { id });
    // });
  },
  updateChildItem({ state, dispatch }, { child, data }) {
    // update a child record and then refresh the parent record
    // requires that urls are in the parent map
    const { put } = state.map[child];
    const url = put.replace(':id', data.id);
    return post(url, data);// .then((res) => {
    //   // data sent back will have the pkey
    //   dispatch('getRecord', { id: res[0].data[state.uniqueId] });
    // });
  },
  removeChildItem({ state, dispatch }, { child, cid, id }) {
    // where child is for the map and cid is to the child
    const { remove } = state.map[child];
    const url = remove.replace(':id', cid);
    return post(url);// .then((res) => {
    //   dispatch('getRecord', { id });
    // });
  },
  addComment({ state, commit, dispatch }, { id, comment }){
    const url = `${state.record}comments/saveas`
    const pkey = state.uniqueId;
    const params = { [pkey]: id, comment }
    return post(url, params)
  },
  removeComment({ state, dispatch }, { id, pid }){
   const url = `${state.record}comments/remove`;
   return post(url, { id })
 },

  // addRecord(store, data){
  //   const url = 'record/insert';
  //   post(url, data).then( res => {
  //     store.commit(ADD_RECORD, res[0].data);
  //   });
  // },
  // deleteRecord(store, data){
  //   const uid = store.state.uniqueId;
  //   const url = `/delete/${data[uid]}`;
  //   post(url).then( res => {
  //     store.commit(REMOVE_RECORD, data);
  //   });
  // },
};

// export default function (options) {
//   const module = {
//     namespaced: true,
//     state() {
//       return {
//         uniqueId: options.uniqueId,
//         record: options.record,
//         collection: options.collection,
//         data: [],
//         history: [],
//       };
//     },
//     getters,
//     mutations,
//     actions,
//   };
//   return module;
// }

export default function (options) {
  const state = assign(initialState, options);
  const module = {
    namespaced: true,
    state() {
      return state
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
