<template>
<div class="ControlEventTool-config">

  <LabeledInput
    label="Tesing">
    <TextInput
      v-model="record.example1"
      />
  </LabeledInput>

</div>
</template>

<script>
import ToolConfigMixin from '@acorns-ui/Tools/Mixins/ToolConfigMixin';

// import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
// import ChecklistInput from '@acorns-ui/Inputs/ChecklistInput';
// import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
 import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
// import NestedInput from '@acorns-ui/Inputs/NestedInput';
// import NumberInput from '@acorns-ui/Inputs/NumberInput';
 import TextInput from '@acorns-ui/Inputs/TextInput';
// import TextareaInput from '@acorns-ui/Inputs/TextareaInput';

export default {
  name: 'ControlEventToolConfig',
  mixins: [ToolConfigMixin],
  props: { },
  data() {
    return {
      record: {
        example1: null,
        example2: null,
      }
    };
  },
  components: {
    LabeledInput,
    TextInput,
   // Button,
   // CheckboxInput,
   // ChecklistInput,
   // DropdownInput,
   // NestedInput,
   // NumberInput,
   // TextareaInput,
},
  created() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.ControlEventTool-config { }
</style>
