<template>
<AppLayout
  id="app"
  :loading="!loggedIn"
  :loaded="loggedIn || onLoginScreen">

  <template
    v-if="loggedIn">
    <GlobalSearch
      slot="global"/>
    <GlobalSidebar
      slot="global"/>

    <Navbar
      slot="navbar">
      <IconLink
        slot="logo"
        @clicked="showGlobalSearch">
        search
      </IconLink>
      <!-- <IconAcorn -->
      <!--   slot="logo"/> -->
      <router-link
        slot="logo"
        to="/"
        class="app-label">
        Ouzel
      </router-link>
    </Navbar>

    <UserSidebar
      slot="user"/>
    <PageMessages/>
    <PageModals/>
    <router-view
      name="page"/>

  </template>

  <router-view
    name="login"/>

</AppLayout>
</template>

<script>
import AppLayout from '@acorns-ui/Layouts/AppLayout';
import GlobalSidebar from '@acorns-ui/Navigation/GlobalSidebar';
import UserSidebar from '@acorns-ui/Navigation/UserSidebar';
import Navbar from '@acorns-ui/Navigation/Navbar';
import IconAcorn from '@acorns-ui/Icons/IconAcorn';
import IconLink from '@acorns-ui/Icons/IconLink';
import PageMessages from '@acorns-ui/PageMessages';
import PageModals from '@acorns-ui/PageModals';
import GlobalSearch from '@acorns-ui/Navigation/GlobalSearch';
import CollectionMenu from '@acorns-ui/Navigation/GlobalSidebar/CollectionMenu';

import { mapState } from 'vuex';
import { get } from '@/utils';

export default {
  name: 'App',
  components: {
    AppLayout,
    IconAcorn,
    GlobalSearch,
    PageMessages,
    PageModals,
    GlobalSidebar,
    UserSidebar,
    Navbar,
    IconLink,
    CollectionMenu,
  },
  data(){
    return {
      loading: true,
      loaded: false,
    }
  },
  created(){
    // this will get us started off
    //this.$store.dispatch('user/initialize')
    // this.$store.dispatch('Global/setStorage', {
    //   collection: 'notes',
    //   data: //[
    //     {uid: 1, note: 'first fake note'},
    // {uid: 2, note: 'second fake note'}
    // ],
    // })
  },
  watch: {

  },
  computed: {
    ...mapState('user', [
      'loggedIn'
    ]),
    onLoginScreen() {
      return this.$route.path == '/login';
    }
  },
  methods: {
    initialize(){
    },
    showGlobalSearch(){
      this.$store.commit('navigation/SET_GLOBAL_SEARCH', true);
    },
    pushRoute(path){
      this.$router.push(path);
      this.$store.commit('navigation/SET_GLOBAL_NAV', false);
    }
  }
};
</script>

<style lang="scss">
@import './styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');
@import '~@acorns-ui/Styles/acorns';
@import '~@acorns-ui/Styles/print';

html, body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


#app {
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  //font-family: 'Varela Round', Helvetica, Arial, sans-serif;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  background-color: $page-color;

  #global-sidebar {
    .global-menu-item {
      padding: 0.75rem;
      .label {
        color: $white;
        font-size: 1.25rem;
      }
    }
  }

  a.app-label {
    font-size: 2rem;
    margin-left: 1rem;
    text-decoration: none;
    color:inherit;
  }
}
</style>
