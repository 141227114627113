import { fetch, put, post, patch, remove, authorize, download, log }  from '@acorns-ui/Api';
export { fetch, put, post, patch, remove, authorize, download, log };

import axios from 'axios';
// //import store from '@/store';
// //import router from '@/router';

// axios.defaults.baseURL = '/api';
// const PAGE_MESSAGE = 'PageMessages/ADD_MESSAGE';
// const debugging = false;

// function BadRequestError(err){
//   //console.trace('request error', err);
//   this.message = err.message || err.error || err;
//   this.name = err.name || 'Bad Request';
//   if(err.sql) {
//     this.sql = err.sql;
//   }
// }

// function MissingDataError(err){
//   //console.trace('request error', err);
//   this.type = 'MissingDataError';
//   this.name = err.name || 'Missing data';
//   this.data = err.data || err;
// }

// function BackendError({ error, sql }){
//   this.message = error;
//   this.sql = sql;
//   this.name = 'Backend Error';
// }

// // serialize the query parameters
// const urlParameters = (args) => {
//   const params = new URLSearchParams();
//   Object.keys(args).forEach((key) => {
//     if (typeof args[key] === 'object') {
//       params.append(key, JSON.stringify(args[key]));
//     } else {
//       params.append(key, args[key]);
//     }
//   });
//   return params;
// };

// const asFormData = args => {
//   if (!(args instanceof FormData)) {
//     const fd = new FormData();
//     fd.append('WITH', JSON.stringify(args));
//     args = fd;
//   }
//   return args;
// };

// const buildFormData = (formData, data, parentKey) => {
//   //console.log('building form data', formData, data, parentKey);
//   if (data
//       && typeof data === 'object'
//       && !(data instanceof Date)
//       && !(data instanceof File)
//       && !(data instanceof Blob)
//      ) {
//     Object.keys(data).forEach(key => {
//       buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
//     });
//   } else {
//     const value = data == null ? '' : data;
//     formData.append(parentKey, value);
//   }
// };


// function handleError(err) {
//   if (axios.isCancel(err)) {
//     // console.log('Canceled request', err.message)
//     throw err;
//   } else if (!err.response) {
//     console.trace('no response in error', err);
//   } else if (err.response.status === 400) {
//     // bad request error
//     //console.log('error = 400', err.response);
//     //let message = err.response.data.message || err.response.data.error;
//     throw new BadRequestError(err.response.data);
//   } else if (err.response.status === 401) {
//     localStorage.removeItem('acornsToken');
//     if(window.location.pathname!=='/login') {
//       window.location.replace('/login');
//     }
//     //router.push('/login');
//     //throw err;
//     throw new BadRequestError(err.response.data.message);
//   } else if (err.response.status === 411) {
//     // store.commit(PAGE_MESSAGE, {
//     //   type: 'api-error',
//     //   params: { label: 'API info', data: err.response.data },
//     //   active: true,
//     // });
//     throw err;
//   } else if (err.response.status === 410) {
//     // the resource is gone
//     // if we were deleting something than we can ignore this

//   } else if (err.response.status === 412) {
//     throw new MissingDataError(err.response.data);
//   } else if (err.response.status === 500) {
//     // this is a catastrophic error, somethign that could not be
//     // caught by the backend for some reason. We need to be aware
//     // of these errors so that we can fix and/or catch them
//     // store.commit('pageMessages/ADD_MESSAGE', {
//     //   type: 'api-error',
//     //   params: { label: 'API info', data: err.response.data },
//     //   active: true.
//     // });
//     //console.log('500 API ERROR', err.response);
//     throw new BackendError(err.response.data);
//   } else {
//     console.log('UK API ERROR', err.response);
//     throw new BadRequestError(err.response.data);
//     // store.commit('pageMessages/ADD_MESSAGE', {
//     //   type: 'api-error',
//     //   params: { label: msg, data: err.response.data },
//     //   active: true,
//     // });
//     //throw err;
//   }
// }

// function handleResponse(res){
//   //console.log(res)
//   if(res.data && res.data.responseType){
//     // check to see what type of object we are dealing with
//     if(res.data.results[0] && res.data.results[0].data){
//       // most likely a search object
//       return res.data.results[0];
//     } else {
//       // most likely a collection of stuff
//       return res.data.results;
//     }
//   }
//   return res.data;
// }

// function request({ url, method, headers, params, cancelToken, config }) {
//   // get the token from storage
//   const token = localStorage.getItem('acornsToken');
//   const options = { headers: { ...headers, Authorization: token }, cancelToken, ...config };
//   //console.log('axios', url, method, options);
//   if(method === 'post'){
//     return axios.post(url, params, options)
//       .then( res => handleResponse(res))
//       .catch( err => handleError(err));
//   } else if(method === 'delete'){
//     return axios.delete(url, options)
//       .then( res => handleResponse(res))
//       .catch( err => handleError(err));
//   } else if(method === 'put'){
//     return axios.put(url, params , options)
//       .then( res => handleResponse(res))
//       .catch( err => handleError(err));
//   } else if(method === 'patch'){
//     return axios.patch(url, params, options)
//       .then( res => handleResponse(res))
//       .catch( err => handleError(err));
//   } else {
//     return axios.get(url, { params, ...options })
//       .then( res => handleResponse(res))
//       .catch( err => handleError(err));
//   }
// }

// export function fetch(url, args = {}, cancel) {
//   const params = urlParameters(args);
//   const cancelToken = cancel ? cancel.token : null;
//   const headers = {};
//   const method = 'get';
//   return request({
//     url,
//     method,
//     params,
//     headers,
//     cancelToken,
//   });
// }

// export function remove(url, args = {}, cancel) {
//   const params = urlParameters(args);
//   const cancelToken = cancel ? cancel.token : null;
//   const headers = {};
//   const method = 'delete';
//   return request({
//     url,
//     method,
//     params,
//     headers,
//     cancelToken,
//   });
// }

// export function post(url, args = {}, cancel, config) {
//   var params;
//   if (!(args instanceof FormData)) {
//      params = new FormData();
//   } else {
//     params = args;
//     args = {};
//   }
//   buildFormData(params, args);
//   const cancelToken = cancel ? cancel.token : null;
//   const headers = { 'Content-Type' : 'multipart/form-data' };
//   const method = 'post';

//   return request({
//     url,
//     method,
//     headers,
//     params,
//     cancelToken,
//     config,
//   });
// }


// export function put(url, args = {}, cancel) {
//   //const params = asFormData(args);
//   const params = urlParameters(args);
//   const cancelToken = cancel ? cancel.token : null;
//   const headers = {};
//   const method = 'put';
//   return request({
//     url,
//     method,
//     params,
//     headers,
//     cancelToken,
//   });
// }


// export function patch(url, args = {}, cancel) {
//   //const params = asFormData(args);
//   const params = urlParameters(args);
//   const cancelToken = cancel ? cancel.token : null;
//   const headers = {};
//   const method = 'patch';
//   return request({
//     url,
//     method,
//     params,
//     headers,
//     cancelToken,
//   });
// }


// export function authorize(url, args = {}) {
//   const params = urlParameters(args);
//   // route call to a different spot
//   url = `authorize/${url}`;
//   //if(debugging) store.commit('LOG_API_CALL', { type: 'authorize', url, args})
//   return axios.post(url, params)
//     .then( res => handleResponse(res))
//     .catch( err => handleError(err));
// }

// export function ecoAuthorize(url, args = {}) {
//   const params = urlParameters(args);
//   // route call to a different spot
//   url = `authorize/${url}`;
//  // if(debugging) store.commit('LOG_API_CALL', { type: 'authorize', url, args})
//   return axios({ url, params })
//     .then( res => handleResponse(res))
//     .catch( err => handleError(err));
// }

// export function download(url, args = {}) {
//   // get the token from storage
//   const token = localStorage.getItem('acornsToken');
//   // serialize the args
//   const params = urlParameters(args);

//   return axios({
//     url,
//     params,
//     responseType: 'blob',
//     headers: { Authorization: token },
//   }).then((response) => {

//     const objUrl = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = objUrl;
//     link.setAttribute('download', response.headers['file-name']);
//     document.body.appendChild(link);
//     link.click();
//     window.URL.revokeObjectURL(objUrl);

//     return response;
//   }).catch( err => {
//     const reader = new FileReader();
//     reader.addEventListener('loadend', (e) => {
//       err.response.data = e.srcElement.result;
//       console.log('blog', e.srcElement.result, typeof(e.srcElement.result));
//       //handleError(err)
//     });
//     reader.readAsText(err.response.data);
//   });
// }

// export function ecoStream(url, args = {}, processStream) {
//   // get the token from storage
//   const token = localStorage.getItem('acornsToken');
//   // serialize the args
//   const params = urlParameters(args);
//   var cursor = 0;
//   return axios({
//     url,
//     params,
//     responseType: 'stream',
//     onDownloadProgress: pe => {
//       var data, pre, response = pe.currentTarget.responseText.substring(cursor).split('|TOER|')
//       console.log(cursor, pe.loaded, (pe.loaded - cursor)*2/1024, response.length);
//       for(let i in response){
//         data = response[i];
//         try {
//           if(data.match(/(^\[.*\]$)|(^\{.*\}$)/)){
//             var json = JSON.parse(data)
//             console.log("LENGTH", json.length)
//             //processStream(json)
//             pre = "";
//           }else{
//             console.log('error', data.length)
//             //if(cursor === 0) console.log(data)
//           }
//         } catch (e) {
//           // pre = data;
//           //window.data = data;
//           //console.log(i , cursor, e, data)
//         }
//       }
//       cursor = pe.loaded;
//     },
//     headers: { Authorization: token },
//   }).then((res) => {
//     //console.log(res.data)
//     console.log("DONE");
//     return res.data;
//   })
// }

// export function ecoCreate(url, args = {}, config = {}) {
//   // get the token from storage
//   const token = localStorage.getItem('acornsToken');
//   // run the call
//   // console.log('ECOPOST:', token);
//   const opt = Object.assign({
//     headers: {
//       Authorization: token,
//       'Content-Type': 'multipart/form-data',
//     },
//   }, config);

//   // make sure that args is FormData
//   if (!(args instanceof FormData)) {
//     const fd = new FormData();
//     fd.append('WITH', JSON.stringify(args));
//     args = fd;
//   }

//   return axios.post(url, args, opt)
//     .then(res => res.data)
//     .catch((err) => { handleError(err) })
//     // .catch((err) => {
//     //   const msg = errorMessage(err);
//     //   store.commit('pageMessages/ADD_MESSAGE', { type: 'api-error', params: { label: msg }, active: true });
//     //   console.log('ecoPost promise err', url, err);
//     //   throw err;
//     // });
// }
