<template>
<FormLayout
  class="approval-form"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  @close="closeHandler">


  <LabeledInput
    v-if="!id && parent == 'property' && parentId"
    label="Restriction"
    :status="status.restriction_id">
    <DropdownInput
      v-model="record.restriction_id"
      :method="`properties/${parentId}/restrictions?f=lookup`"
      :fields="['description']"
      :required="true"
      @status-changed="updateStatus('restriction_id', $event)"/>
  </LabeledInput>

  <LabeledInput
    label="Approved for"
    :status="status.approved_contact_id">
    <DropdownInput
      v-model="record.approved_contact_id"
      :method="`contacts/lookup`"
      :allow-search="true"
      :allow-new="true"
      new-form="ContactForm"
      :required="true"
      @status-changed="updateStatus('approved_contact_id', $event)"/>
  </LabeledInput>


  <div class="input-group">

    <LabeledInput
      :fill="true"
      label="From">
      <DateInput
       default="today"
        v-model="record.start_date"
        />
    </LabeledInput>

    <LabeledInput
      :fill="true"
      label="To">
    <DateInput
       v-model="record.end_date"
       />
  </LabeledInput>


  </div>

  <LabeledInput
    label="Comments"
    :fill="true"
    :status="status.comments">
    <TextareaInput
      v-model="record.comments"
      :required="false"
      @status-changed="updateStatus('comments', $event)"/>
  </LabeledInput>



  <Button
    v-if="isDirty && ready"
    slot="buttons"
    title="Cancel"
    @click="cancelAndClose"
    type="cancel">
    Cancel
  </Button>


  <Button
    slot="buttons"
    :disabled="!ready"
    type="save"
    @click="saveAndClose">
    save
  </Button>

</FormLayout>
</template>

<script>
  import { fetch } from '@/api';

import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput'
import DropdownInput from '@acorns-ui/Inputs/DropdownInput'
import TextareaInput from '@acorns-ui/Inputs/TextareaInput'
import DateInput from '@acorns-ui/Inputs/DateInput';
import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'ApprovalForm',
  mixins: [
    CollectionFormMixin,
    FormValidationMixins,
  ],
  props: {
    collection: {
      default: 'approvals'
    },
  },
  data() {
    return {
      record: {
        restriction_id: null,
        approved_contact_id: null,
        start_date: null,
        end_date: null,
        comments: null,
      }
    };
  },
  components: {
    LabeledInput,
    DropdownInput,
    TextareaInput,
    FormLayout,
    Button,
    DateInput,
  },
  created() {},
  methods: { }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .approval-form {
    @include formsize;
  }
}

.approval-form  {

  .form-content {
    padding: 0.5rem;
  }

  .description-input {
    flex: 1;
  }
  .element-examples-input {
    flex: 1;
  }

  .element-examples {
    overflow: auto;

    .element-example {
      cursor: pointer;
      display: block;
      &:hover {
        background-color: $hover-bkgd;
      }
      padding: 0.5rem;
      .property-label {
        font-weight: 600;
      }
    }
  }
}
</style>
