<template>
<FormLayout
  class="saved-search-form"
  @close="$emit('close')">

  <H2 slot="header">Saved search</H2>

  <LabeledInput
    v-if="!defaults.object_id">
    <label slot="label">
      Collection
    </label>
    <DropdownInput
      v-model="record.object_id",
      method="objects/lookup"
      :allow-null="false"
      />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">
      Label
    </label>
    <TextInput
      v-model="record.label"
      />
  </LabeledInput>

  <LabeledInput
    :fill="true">
    <label slot="label">
      Description
    </label>
    <TextareaInput
      v-model="record.description"
      />
  </LabeledInput>

  <Button
    slot="buttons"
    @click="save">
    Save
  </Button>

</FormLayout>
</template>

<script>
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import IconLink from '@acorns-ui/Icons/IconLink';
import Button from '@acorns-ui/Inputs/Button';
import TextInput from '@acorns-ui/Inputs/TextInput';
import TextareaInput from '@acorns-ui/Inputs/TextareaInput';
import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
import { post } from '@/api';


export default {
  props: {
    defaults: {
      type: Object,
      default: {}
    },
    callback: {
      type: Function,
    }
  },
  data() {
    return {
      record: {
        id: null,
        object_id: null,
        label: null,
        description: null,
        config: {}
      },
    };
  },
  components: {
    IconLink,
    FormLayout,
    Button,
    LabeledInput,
    TextInput,
    TextareaInput,
    DropdownInput,
  },
  created(){
    this.record = Object.assign(this.record, this.defaults);
  },
  computed: {

  },
  methods: {
    save(){
      const data = this.record;
      post('searches/upsert', data)
        .then( res => {
          if(this.callback) {
            this.callback(res);
          }
          this.$emit('close')
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .saved-search-form {
    @include formwidth;
  }
}

.saved-search-form {
  .form-content {
    padding: 0.5rem;
  }
}

</style>
