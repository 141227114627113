import { fetch, post } from '@/api';
import { get } from '@/utils';

const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA';
const RESET_COLLECTION = 'RESET_COLLECTION';
const SET_COLLECTION_FILTERS = 'SET_COLLECTION_FILTERS';
const TOGGLE_COLLECTION_FILTER = 'TOGGLE_COLLECTION_FILTER';
const SET_COLLECTION_LOADING = 'SET_COLLECTION_LOADING';
const ADD_COLLECTION_ID = 'ADD_COLLECTION_ID';

const getMappedUrl = (endpoints, verb, id) => {
  const url = verb != 'resource'
        ? endpoints.resource + '/' + endpoints[verb]
        : endpoints[verb];
  return !url
    ? null
    : id ? url.replace(':id', id) : url.replace('/:id', '')
}

export const collection = (options) => {
  return Object.assign({
    ids: [],
    loaded: false,
    loading: false,
    status: 'empty',
    meta: {},
    fields: [], // fields needs to be an array (filtered in ChildCollectionMixin)
    filters: {},
    selected: [],
    expanded: [],
  }, options)
};

///////////////////////////////////////////////////////////////////////
// The first two getters are functions that need to be parameterized //
///////////////////////////////////////////////////////////////////////
export function collectionGetter(collection, pkey) {
  return (state, getters, rootState, rootGetters) => {
    //console.log('collection getter', collection, pkey)
    return {
      meta: state[collection].meta,
      fields: state[collection].fields,
      data: rootGetters[`${collection}/data`]
        .filter(d => state[collection].ids.includes(d[pkey]))
      //.map( d => ({ inParent: 'yes', ...d }))
    }}
};

export function eventsGetter(collection) {
  return (state, getters, rootState, rootGetters) => {
    const { ids } = state[collection];
    // console.log('event getter', collection, ids, ids.length, rootGetters[`${collection}/calendar`])
    if(!rootGetters[`${collection}/calendar`] || !rootGetters[`${collection}/calendar`].length) return [];
    return rootGetters[`${collection}/calendar`]
      .filter(id => ids.includes(id.uid));
  }
};
////////////////////////////////////////////////////////////////////////
// And here are the standard getters for the working with collections //
////////////////////////////////////////////////////////////////////////
export const getters = {
  // requires a map
  api: (state) => (collection, verb, id) => {
    console.log('api', collection, verb, id, state.map)
    const endpoints = get(['map', collection], state);
    if(!endpoints) return;
    const uri = verb != 'resource'
          ? endpoints.resource + '/' + endpoints[verb]
          : endpoints[verb];
    return id ? uri.replace(':id', id) : uri.replace('/:id', '');
  },
}
///////////////////////////
// And all the mutations //
///////////////////////////
export const mutations = {
  [SET_COLLECTION_DATA](state, {
    collection, uniqueId, data, fields, meta,
  }) {
    state[collection].fields = fields || [];
    state[collection].meta = meta || {};
    state[collection].status = 'loaded';
    state[collection].loading = true;
    state[collection].loaded = true;
    // assume that if data is null than there is no data
    if (data) {
      state[collection].ids = data.map(d => d[uniqueId]);
    } else {
      state[collection].ids = [];
    }
  },
  [RESET_COLLECTION](state, { collection, clear }) {
    console.log('reset collection', collection)
    state[collection].loaded = false;
    state[collection].loading = false;
    if(clear) {
      state[collection].ids = []
      state[collection].fields = []
    }
  },
  [SET_COLLECTION_FILTERS](state, { collection, filters }) {
    state[collection].filters = filters || [];
  },
  [TOGGLE_COLLECTION_FILTER](state, { collection, filter }) {
    state[collection].filters[filter].active = !state[collection].filters[filter].active;
  },
  [SET_COLLECTION_LOADING](state, { collection }) {
    state[collection].loading = true;
  },
  [ADD_COLLECTION_ID](state, { collection, id }) {
    state[collection].ids.push(id);
  },
};

export const actions = {
  // this is responsible for actually pulling data down from the db
  // it relies on the parent object on the db to have a method
  // that searches the child object. Data is expected back
  // along with fields and meta data
  getCollectionData({ state, commit, rootState }, { collection, params }) {
    // const col = state[collection];
    const childStore = rootState[collection];
    const localStore = state[collection];

    if (!childStore) {
      console.log(`Could not find info for ${collection} in ${state.resource}`);
      return false;
    }
    // make sure the collection actually exists in the object
    if (!localStore) {
      console.log(`Could not find any state node for ${collection} in ${state.resource}`);
      return false;
    }
    // make sure its not loaded or being loaded
   // console.log('get collection data', localStore)
    if(localStore.loaded || localStore.loading) return;
    // if (col.status) return; // skip because we have already loaded it
    // we could also look in the api map for this
    const uniqueId = childStore.uniqueId; // this is the name of the field
    // console.log(collection, uniqueId)
    const { id, resource } = state;
    const url = `${resource}/${collection}/${id}`;
    // store the current filter
    // console.log("GET COLLECTION DATA", url, collection, uniqueId);
    commit(SET_COLLECTION_LOADING, { collection });
    // these are the workspace filters
    // and Im not sure if I am using these anywhere
    var f,
      filters = state[collection].filters;
    if (filters) {
      var f = {};
      for (const i in filters) {
        if (filters[i].active) {
          Object.keys(filters[i].rule).forEach((key) => {
            f[key] = filters[i].rule[key];
          });
        }
      }
    }
    //console.log('get collection data', state.resource, url, filters, f, params, state)
    fetch(url, { ...filters, ...params }).then((rsp) => {
      //const rsp = res[0];
      console.log('parent: fetched collection data', collection , rsp)
        commit(`${collection}/MERGE_RECORDS`, rsp.data || rsp, { root: true });
        commit(SET_COLLECTION_DATA, {
          collection,
          uniqueId,
          data: rsp.data || rsp,
          fields: rsp.fields || [],
          meta: rsp.meta || {},
        });
    });
  },
  // basically just resets the status and reloads data
  refreshCollectionData({ state, commit, dispatch }, { collection, params }) {
    // for when the collection is already setup and we can get uid from state
    // we have two different types of joins that we are dealing with here
    // a linked join and just a child/parent join
    // the next step is to break those up
    // console.log('REFRESHING COLLECTION', collection);
    commit(RESET_COLLECTION, { collection, clear: false });
    dispatch('getCollectionData', { collection, params });
  },
  // This is to toggle on and off predefinded filters
  toggleCollectionFilter({ commit, dispatch }, { collection, filter }) {
    // remove filter
    commit(TOGGLE_COLLECTION_FILTER, { collection, filter });
    // update collection
    dispatch('refreshCollectionData', { collection });
  },
  addCollectionItem({ state, commit, rootState }, { collection, data, cid }) {
    console.log('eco: add collection item', state.id, collection, cid)
    const obj = state.map[collection];
    const child = rootState[collection];

    if (!obj) {
      console.log(`You must set resources for ${collection} in ${state.resource}`);
      return;
    }
    const url = getMappedUrl(obj, 'post')
    // get the foreign key, this is the pkey of the child table
    // if not use the current pkey and hope they are the same
    const fkey = (child ? child.uniqueId : state.pkey);
    const cMutation = `${collection}/ADD_RECORD`;
    // some child collections are not linked so this is not needed
    if (url) {
      // const address = `${url}/insert`;
      data[state.pkey] = state.id;
      return post(url, data).then((res) => {
        if (res.result) {
          //commit(cMutation, res[0].data, { root: true });
          commit(ADD_COLLECTION_ID, { collection, id: res.id });
          return cid ? res.rid : res.id
        }
      });
    } else if (collection) { // in which case we just append
      commit(cMutation, data.data, { root: true });
      commit(ADD_COLLECTION_ID, { collection: store, id: data.data[fkey] });
    }
  },
  // GET THE RAW DATA
  getRecordRaw({ dispatch, getters}, { collection, id }){
    const url = getters.api(collection, 'record', id);
    console.log('parent: get raw record data', collection, id, url)
    return dispatch(`${collection}/getRecordRaw`, { url, id }, { root: true });
  },
  // GET A COLLECTION RECORD
  getRecord({ dispatch, getters}, { collection, id }){
    // use this store to get the data
    // and then commit it to the collection store
    const url = getters.api(collection, 'get', id);
    console.log('parent: get record data', collection, id, url)
    return dispatch(`${collection}/getRecord`, { url, id }, { root: true });
  },
  // UPDATE A COLLECTION RECORD
  updateRecord({ dispatch, getters}, { collection, id, data}){
    const url = getters.api(collection, 'put', id);
    console.log('parent: update record data', collection, id, url)
    return dispatch(`${collection}/updateRecord`, { url, data, id }, { root: true });
  },
  // ADD A COLLECTION RECORD
  addRecord({ state, dispatch, getters}, { collection, data}){
    const url = getters.api(collection, 'post');
    console.log('parent: add record data', collection, url, data)
    return dispatch(`${collection}/addRecord`, {
      url,
      data: { [state.pkey]: state.id, ...data }
    }, {
      root: true
    });
  },
  // REMOVE A COLLECTION ITEM
  removeRecord({ dispatch, getters}, { collection, data }){
    const url = getters.api(collection, 'remove', data.id );
    console.log('parent remove record data', collection, data, url)
    return dispatch(`${collection}/removeRecord`, { url, data }, { root: true });
  },
  editField({ state, dispatch, getters}, { collection, id, field }){
    const url = getters.api(collection, 'record', id);
    const callback = (d) => {
      dispatch('refreshCollectionData', { collection })
      dispatch('getRecord', { collection, id })
    }
    const parent = state.storeType;
    const parentId = state.id;
    console.log('parent edit record data', collection, url, field)
    return dispatch(`${collection}/editRecord`, { url, id, field, callback, parent, parentId }, { root: true });
  },
  editRecord({ state, dispatch, getters }, { collection, id, defaults }){
    const url = getters.api(collection, 'record', id);
    const callback = (d) => {
      dispatch('refreshCollectionData', { collection })
      if(id) dispatch('getRecord', { collection, id })
    }
    const parent = state.storeType;
    const parentId = state.id;
    console.log('parent: edit record', collection, id, parent, parentId, url)
    return dispatch(`${collection}/editRecord`, { url, id, defaults, callback, parent, parentId }, { root: true });
  },
  updateCollectionItem({ state, commit, rootState }, { collection, data }) {
    const obj = state.map[collection];
    const fkey = rootState[collection].uniqueId;
    // not sure what to use here, I use to have it start with fkey
    const id =  data['id'] || data['uid'] || data[fkey];
    const url = getMappedUrl(obj, 'put', id)
    return post(url, data);
  },
  // this should unlink the collection item, not delete it
  removeCollectionItem({ state, dispatch, commit }, { collection, ids }) {
    const obj = state.map[collection];
    if (!obj) {
      console.log(`You must set resources for ${collection} in ${state.resource}`);
      return;
    }
    const args = { items: ids };
    const url = getMappedUrl(obj, 'remove')
    // console.log(collection, remove, url, uniqueId, args);
    return post(url, args).then((res) => {
      commit('PageMessages/ADD_MESSAGE', {
        type: 'record-deleted',
        params: { label: 'Record deleted' },
        active: true
      }, {root: true});
      dispatch('refreshCollectionData', { collection });
    });
  },
  unlinkCollectionItem({ state, dispatch, commit }, { collection, ids }) {
    // console.log('UNLINK', collection, ids, state.map)
    const obj = state.map[collection];
    const args = { items: ids };
    const url = getMappedUrl(obj, 'unlink')
    // console.log(collection, unlink, url, uniqueId, args)
    return post(url, args).then((res) => {
      commit('PageMessages/ADD_MESSAGE', {
        type: 'record-deleted',
        params: { label: 'Record unlinked' },
        active: true
      }, {root: true});
      dispatch('refreshCollectionData', { collection });
    });
  },
  showCollectionForm({ state, commit, rootState }, params ){
    const { collection } = params;
    const FORM_COMMIT = get([collection, 'form'], rootState)
    console.log('show form', collection, FORM_COMMIT)
    commit(FORM_COMMIT, params, { root: true })
  },
  // this is going to create a form so that we can add data
  // its going to download the required inputs and then use the basic form method
  // a callback can be added
  newCollectionItem({ state, commit, dispatch }, { collection, data, callback }) {
    const obj = state.map[collection];
    const url = getMappedUrl(obj, 'record')

   fetch(url).then((res) => {
      const params = {
        inputs: res.inputs,
        data: {},
        callback(data) {
          dispatch('addCollectionData', {
            collection,
            data,
          }).then((res) => {
            if (callback) callback(res);
          });
        },
      };
      store.commit('SHOW_MODAL', {
        name: 'basic-form',
        params,
      }, { root: true });
    });
  },
};
