<template>
<component
  v-if="internalData || prid || endpoint"
  :editable="editable"
  :showMap="showMap"
  :is="componentName"
  :d="internalData"
  :prid="prid"
  :store="store"
  :endpoint="endpoint"
  :dataset="dataset"
  :collection="collection"
  :parent="parent"
  :parent-id="id"
  :callback="callback"
  :record-id="prid"
  :initial-tab="initialTab"
  v-bind="record"
  @close="$emit('close', $event)"
  @updated="refresh"
  class="preview" />
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { get } from '@/utils';
import projectPreview from '@acorns-ui/Previews/ProjectPreview';
import contactPreview from '@acorns-ui/Previews/ContactPreview';
import communicationPreview from '@acorns-ui/Previews/CommunicationPreview';
import taskPreview from '@acorns-ui/Previews/TaskPreview';
import propertyPreview from '@acorns-ui/Previews/PropertyPreview';
import issuePreview from '@acorns-ui/Previews/IssuePreview';
import featurePreview from '@acorns-ui/Previews/FeaturePreview';
import documentPreview from '@acorns-ui/Previews/DocumentPreview';
import filePreview from '@acorns-ui/Previews/FilePreview';
import notePreview from '@acorns-ui/Previews/NotePreview';
import parcelPreview from '@acorns-ui/Modules/ParcelsModule/ParcelPreview';
import rolePreview from '@acorns-ui/Modules/RoleModule/RolePreview';
import monitoringElementPreview from '@acorns-ui/Previews/MonitoringElementPreview';
import cameraPointPreview from '@acorns-ui/Previews/CameraPointPreview';
import patchPreview from '@acorns-ui/Previews/PatchPreview';
import eventPreview from '@acorns-ui/Previews/EventPreview';
import approvalPreview from '@acorns-ui/Workspaces/RestrictionsWorkspace/ApprovalPreview';
import sourcePreview from '@acorns-ui/Workspaces/RestrictionsWorkspace/SourcePreview';
import genericPreview from '@acorns-ui/Previews/GenericPreview';

// import genericPreview from './genericPreview';
// import messagePreview from './messagePreview';
// import photoPointPreview from './photoPointPreview';
// import standsPreview from './standsPreview';
// import hourPreview from './hourPreview';
// import eventPreview from './EventPreview';
// import regionPreview from './regionPreview';
// import propertyStatusPreview from './propertyStatusPreview';
//import monitoringElementPreview from './MonitoringElementPreview';

const collectionPreviews = {
  'tasks': 'taskPreview',
  'files': 'filePreview',
  'contacts': 'contactPreview',
  'properties': 'propertyPreview',
  'parcels': 'parcelPreview',
  'roles' : 'rolePreview',
  'communications': 'communicationPreview',
  'notes': 'notePreview',
  'issues': 'issuePreview',
  'regions': 'regionPreview',
  'events': 'eventPreview',
  'projects': 'projectPreview',
  'hours': 'hourPreview',
  'documents': 'documentPreview',
  'elements': 'monitoringElementPreview',
  'patches':'patchPreview',
  'sources':'sourcePreview',
  'approvals': 'approvalPreview',
  'events': 'eventPreview',
};

const snakeToCamel = (str) => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
                    .replace('-', '')
                    .replace('_', '')
);

export default {
  name: 'PreviewFactory',
  props: {
    endpoint: {
      type: String,
      required: false,
    },
    parent: {
      type: String,
      required: false,
    },
    showMap: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: [Number, String],
      required: false,
    },
    prid: {
      type: [Number, String],
      required: false,
    },
    editable: { type: Boolean, default: true },
    component: { required: false },
    store: String, // this is the parent of the dataset or workspace
    dataset: { required: false }, // latest iteration of identifying the preview
    collection: {
      required: false,
      type: String,
    }, // new latest iteration of identifying the preview
    initialTab: {
      type: String,
    },
    data: {
      type: Object,
      required: false
    },
    callback: {
      type: Function,
      //default: res => console.log(res),
    },
  },
  components: {
    // eventPreview,
    genericPreview,
    // hourPreview,
    // messagePreview,
    // monitoringElementPreview,
    // photoPointPreview,
    // regionPreview,
    // standsPreview, // plural should be used from now on
    // visitPreview,
    approvalPreview,
    sourcePreview,
    cameraPointPreview,
    communicationPreview,
    contactPreview,
    documentPreview,
    featurePreview,
    filePreview,
    issuePreview,
    notePreview,
    parcelPreview,
    patchPreview,
    projectPreview,
    propertyPreview,
    //propertyStatusPreview,
    rolePreview,
    taskPreview,
    eventPreview,
  },
  created() {
    // if this is one of the more updated previews, where we
    // are binding everything to props we will need to fetch the data
    // and bind it
    // this will only show up as modals so I dont think we need to do
    // more than just load the data and v-bind it
    if(['files','sources'].includes(this.collection)) {
      this.getRecordData();
    }
  },
  computed: {
    record(){
      const uid = `u${this.prid}`;
      const record = get([this.collection, 'records', uid], this.$store.state);
      if(record) {
        return { ...record, loaded: true };
      } else {
        return
      }
    },
    internalData() {
      if (this.data) {
        return this.data;
      }
      return this.selectedData;
    },
    componentString() {
      if (this.component) {
        return this.component;
      } else if (this.collection) {
        return collectionPreviews[this.collection] || 'genericPreview';
      } else if (this.dataset) {
        return `${this.datasetSingularName}Preview`
      } else if (this.type) {
        const type = this.type.replace(/s$/,'')
        return `${type}Preview`
      } else if (this.meta && this.meta.templates) {
        return this.meta.templates.preview;
      }
    },
    datasetSingularName(){
      const ds = this.$store.state[this.dataset];
      if(ds) return ds.record;
      return this.dataset;
    },
    genericPreviewName(){
      return this.showMap ? 'generic-preview' : 'feature-preview';
    },
    // componentName2() {
    //   return snakeToCamel(this.componentString);
    // },
    // componentName3() {
    //   return this.$options.components;
    // },
    componentName() {
      const cs = this.componentString;
      const csPath = snakeToCamel(cs);
      if (this.$options.components[csPath]) {
        return cs;
      }
      return this.genericPreviewName;
    },
  },
  methods: {
    refresh(){
      const collection = this.collection;
      if( collection == 'files') {
        this.getRecordData()
      } else {
        this.$emit('updated', { collection });
      }
    },
    getRecordData(){
      const id = this.prid;
      const collection = this.collection;
      if(id && collection) {
        return this.$store.dispatch(`${collection}/getRecord`, { id })
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  &>.preview {
    @include formsize;
  }
}
.modal-container.preview {
  position: relative;

  &>.header {
    position: absolute;
    background-color: transparent;
    border-bottom: none;
    width: 100%;
    z-index: 100;
    .icon {
      color: rgba(255,255,255,0.7);
    }
  }
  #modal-content {
    padding: 0px;
  }

  .preview {
    max-height: 95vh;

    &>.toolbar {
      padding: 10px;
      right: inherit;
    }
  }
}

</style>
