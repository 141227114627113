// Page Messages
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS';
export const DEACTIVATE_MESSAGE = 'DEACTIVATE_MESSAGE';

export const REGISTER_TEMPLATE = 'REGISTER_TEMPLATE';
export const REGISTER_PROPERTY = 'REGISTER_PROPERTY';
export const REGISTER_ISSUE = 'REGISTER_ISSUE';
export const REGISTER_REGION = 'REGISTER_REGION';
export const REGISTER_ROLE = 'REGISTER_ROLE';
export const REGISTER_CONTACT = 'REGISTER_CONTACT';
export const REGISTER_COLLECTION = 'REGISTER_COLLECTION';
export const REGISTER_PROJECT = 'REGISTER_PROJECT';
export const REGISTER_OBJECT = 'REGISTER_OBJECT';
export const UNREGISTER_OBJECT = 'UNREGISTER_OBJECT';

export const LOG_API_CALL = 'LOG_API_CALL';

// SearchPage
export const SET_MODULE = 'SET_MODULE';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const CURRENT_SEARCH = 'CURRENT_SEARCH';
export const PAGE_LOADED = 'PAGE_LOADED';
export const FILTER_LOADED = 'FILTER_LOADED';
export const CURRENT_SELECTION = 'CURRENT_SELECTION';
export const RESULTS = 'RESULTS';
export const SET_SELECTED_PREVIEW = 'SET_SELECTED_PREVIEW';
export const FILTERS = 'FILTERS';
export const FACTORS = 'FACTORS';
export const METRICS = 'METRICS';
export const SELECT_ACTIONS = 'SELECT_ACTIONS';
export const TOGGLE_SELECTED_FACTOR = 'TOGGLE_SELECTED_FACTOR';
export const TOGGLE_SELECTED_METRIC = 'TOGGLE_SELECTED_METRIC';
export const SELECTED_FACTORS = 'SELECTED_FACTORS';
export const SELECTED_METRICS = 'SELECTED_METRICS';
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const UPDATE_FILTER_VALUES = 'UPDATE_FILTER_VALUES';
export const CLEAR_FILTER_VALUES = 'CLEAR_FILTER_VALUES';
export const SET_CURRENT_SEARCH_PARAMS = 'SET_CURRENT_SEARCH_PARAMS';
export const META = 'META';
export const FIELDS = 'FIELDS';
export const SAVED_SEARCHES = 'SAVED_SEARCHES';
export const SAVED_REPORTS = 'SAVED_REPORTS';
export const MERGE_ENTRY = 'MERGE_ENTRY';
export const REMOVE_ENTRY = 'REMOVE_ENTRY';
export const TOGGLE_TABLE_SELECT_ROW = 'TOGGLE_TABLE_SELECT_ROW';
export const TOGGLE_TABLE_SELECT_ALL = 'TOGGLE_TABLE_SELECT_ALL';
export const CURRENT_REPORT = 'CURRENT_REPORT';

// User
export const USER = 'USER';
export const LOGGED_IN = 'LOGGED_IN';
export const LOG_OUT = 'LOG_OUT';
export const ROUTES_ADDED = 'ROUTES_ADDED';
export const GET_ORG_CONFIG = 'GET_ORG_CONFIG';

// Nav
export const GET_MENU = 'GET_MENU';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const RESET_NAVS = 'RESET_NAVS';
export const TOGGLE_USER_NAV = 'TOGGLE_USER_NAV';
export const OPEN_USER_NAV = 'OPEN_USER_NAV';
export const CLOSE_USER_NAV = 'CLOSE_USER_NAV';
export const ACTIVE_USER_TAB = 'ACTIVE_USER_TAB';
export const ACTIVE_USER_PANEL = 'ACTIVE_USER_PANEL';
export const SELECT_NAV_ITEM = 'SELECT_NAV_ITEM';
export const OPEN_NAV_FOLDER = 'OPEN_NAV_FOLDER';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const APPEND_BREADCRUMBS = 'APPEND_BREADCRUMBS';
export const SET_WORKSPACE_TITLE = 'SET_WORKSPACE_TITLE';
// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_WORK_FORM = 'SHOW_WORK_FORM';
export const CLOSE_WORK_FORM = 'CLOSE_WORK_FORM';
export const SHOW_MESSAGE_FORM = 'SHOW_MESSAGE_FORM';
export const CLOSE_MESSAGE_FORM = 'CLOSE_MESSAGE_FORM';
export const SHOW_CONTACT_FORM = 'SHOW_CONTACT_FORM';
export const CLOSE_CONTACT_FORM = 'CLOSE_CONTACT_FORM';
export const SHOW_ISSUE_FORM = 'SHOW_ISSUE_FORM';
export const CLOSE_ISSUE_FORM = 'CLOSE_ISSUE_FORM';
export const SHOW_WORK_PERFORMED_FORM = 'SHOW_WORK_PERFORMED_FORM';
export const CLOSE_WORK_PERFORMED_FORM = 'CLOSE_WORK_PERFORMED_FORM';
export const SHOW_TOOL_FORM = 'SHOW_TOOL_FORM';
export const CLOSE_TOOL_FORM = 'CLOSE_TOOL_FORM';
export const SHOW_DELIVERABLE_FORM = 'SHOW_DELIVERABLE_FORM';
export const CLOSE_DELIVERABLE_FORM = 'CLOSE_DELIVERABLE_FORM';
export const SHOW_TASK_FORM = 'SHOW_TASK_FORM';
export const CLOSE_TASK_FORM = 'CLOSE_TASK_FORM';
export const SHOW_NOTE_FORM = 'SHOW_NOTE_FORM';
export const CLOSE_NOTE_FORM = 'CLOSE_NOTE_FORM';
export const SHOW_DOCUMENT_FORM = 'SHOW_DOCUMENT_FORM';
export const CLOSE_DOCUMENT_FORM = 'CLOSE_DOCUMENT_FORM';
export const SHOW_FILE_FORM = 'SHOW_FILE_FORM';
export const CLOSE_FILE_FORM = 'CLOSE_FILE_FORM';
export const SHOW_FILE_REVIEW = 'SHOW_FILE_REVIEW';
export const CLOSE_FILE_REVIEW = 'CLOSE_FILE_REVIEW';
export const SHOW_OBSERVATION_FORM = 'SHOW_OBSERVATION_FORM';
export const CLOSE_OBSERVATION_FORM = 'CLOSE_OBSERVATION_FORM';
export const SHOW_RESTRICTION_FORM = 'SHOW_RESTRICTION_FORM';
export const CLOSE_RESTRICTION_FORM = 'CLOSE_RESTRICTION_FORM';
export const SHOW_APPROVAL_FORM = 'SHOW_APPROVAL_FORM';
export const CLOSE_APPROVAL_FORM = 'CLOSE_APPROVAL_FORM';
export const SHOW_PROJECT_FORM = 'SHOW_PROJECT_FORM';
export const CLOSE_PROJECT_FORM = 'CLOSE_PROJECT_FORM';
export const SHOW_PROPERTY_FORM = 'SHOW_PROPERTY_FORM';
export const CLOSE_PROPERTY_FORM = 'CLOSE_PROPERTY_FORM';
export const SHOW_LINK_FORM = 'SHOW_LINK_FORM';
export const CLOSE_LINK_FORM = 'CLOSE_LINK_FORM';
export const SHOW_LIBRARY_FORM = 'SHOW_LIBRARY_FORM';
export const CLOSE_LIBRARY_FORM = 'CLOSE_LIBRARY_FORM';
export const SHOW_COLLECTION_FORM = 'SHOW_COLLECTION_FORM';
export const CLOSE_COLLECTION_FORM = 'CLOSE_COLLECTION_FORM';
export const SHOW_LAYER_FORM = 'SHOW_LAYER_FORM';
export const CLOSE_LAYER_FORM = 'CLOSE_LAYER_FORM';
export const SHOW_DATASET_FORM = 'SHOW_DATASET_FORM';
export const CLOSE_DATASET_FORM = 'CLOSE_DATASET_FORM';
export const SHOW_ACCOUNT_FORM = 'SHOW_ACCOUNT_FORM';
export const CLOSE_ACCOUNT_FORM = 'CLOSE_ACCOUNT_FORM';
// reports
export const SHOW_TASK_REPORT = 'SHOW_TASK_REPORT';
export const CLOSE_TASK_REPORT = 'CLOSE_TASK_REPORT';

export const SET_COMPONENT_HELP = 'SET_COMPONENT_HELP';
