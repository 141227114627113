<template>
  <BoardWorkspace
    v-if="isLoaded"
    class="user-feed-page"
    :parent="boardType"
    :id="currentBoardId"
    :store="parentStore"
    :url="url"
    :center="true">
</BoardWorkspace>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
 import BoardWorkspace from '@acorns-ui/Workspaces/BoardWorkspace';
 import { get } from '@acorns-ui/../utils';
// import feedList from './feedList';

export default {
  name: 'UserFeedPage',
  data() {
    return {
      component: 'dashboard-layout',
      api: 'Research/boardNote/widget',
      type: 'contact',
      config: {store: "user"},
    };
  },
  created(){
    if(this.currentRoleId && this.isRoleBoard) {
      this.getRoleBoard(this.currentRoleId);
    } else {
      this.getUserBoard();
    }
  },
  components: {
    BoardWorkspace,
  },
  watch: {
    currentRoleId(a,b) {
      this.getRoleBoard(a);
    },
    boardType(a,b) {
      console.log('board type change', a, b);
    },
  },
  computed: {
    ...mapGetters('user', [
      'cid',
      'id',
      'currentRole',
    ]),
    ...mapState('user', [
      'currentRoleId',
      'boardType',
      'loaded',
    ]),
    isLoaded() {
      if(this.isRoleBoard) {
        return this.roleLoaded;
      } else {
        return this.loaded;
      }
    },
    isRoleBoard() {
      return this.boardType == 'role';
    },
    roleLoaded() {
      return get([this.roleStore, 'loaded'], this.$store.state);
    },
    parentStore() {
      if(this.isRoleBoard) {
        return this.roleBoard;
      } else {
        return 'user';
      }
    },
    roleStore() {
      var name = `role${this.currentRoleId}`;
      if(typeof(this.$store.state[name]) == 'object') {
        return name;
      } else {
        return null;
      }
    },
    currentBoardId(){
      if(this.boardType == 'role') {
        return this.currentRoleId;
      } else {
        // the user should not have an id
        return //this.id;
      }
    },
    url(){
      if(this.boardType == 'role') {
        return `role/userboard/${this.currentRoleId}`;
      } else {
        return `user/board`;
      }
    }
  },
  methods: {
    getUserBoard() {
      // what should I do here
      console.log('get user board', this.id);
      //this.loaded = true;
    },
    getRoleBoard(id) {
      this.$store.dispatch('getRole', { id })
       // .then( res => this.loaded = true);
    }
  },
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.user-feed-page {
  &.board-workspace {
    .board-container {
      margin: 0 auto;
    }
  }
}

</style>
