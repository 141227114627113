import { fetch, post } from '@/api';
import moment from 'moment';
import { get, assign } from '@/utils';
import { regionEndpoints } from '@/api/resources';

import {
  state as crudState,
  actions as crudActions,
  mutations as crudMutations,
  getters as crudGetters,
} from './crud';

import {
  collection,
  collectionGetter,
  eventsGetter,
  mutations as collectionMutations,
  actions as collectionActions,
  getters as collectionGetters,
} from './childCollectionCRUD';

import {
  spatialState,
  spatialLayers,
  getSpatialLayer,
  getObjectSpatialLayer,
  ADD_SPATIAL_LAYER
} from './spatialLayersMixins';

export const SET_DATA = 'SET_DATA';
export const SET_LAYERS = 'SET_FEATURES';

const state = {
    ...crudState,
    ...spatialState,
  resource: 'region',
  storeType: 'region',
  map: regionEndpoints,
  label: 'Region Not Set',
  description: null,
  lead: { contact_id: 0, label: 'Not set'},
  regionPath: [],
  parentRegions: {},
  geometry: null,
  additionalInfo: {},
  // SPATIAL STATE
  layersList: [],
  // COLLECTION STATE
  approvals: collection(),
  restrictions: collection(),
  parcels: collection(),
  regions: collection(),
  tasks: collection(),
 // taskDeliverables: collection(),
  contacts: collection(),
  projects: collection(),
  properties: collection(),
  issues: collection(),
  notes: collection(),
};

const getters = {
  // general
  ...crudGetters,
  ...collectionGetters,
  baseUrl: (state) => 'regions',
  editable: state => true,//!state.closedOn,
  leadContactId: state => get(['lead','contact_id'], state),
  hasRegions: state => state.regionPath.length<=1,
  // COLLECTIONS
  approvals: collectionGetter('approvals', 'approval_id'),
  restrictions: collectionGetter('restrictions', 'restriction_id'),
  parcels: collectionGetter('parcels', 'parcel_id'),
  properties: collectionGetter('properties', 'property_id'),
  regions: collectionGetter('regions', 'region_id'),
  tasks: collectionGetter('tasks', 'task_id'),
  //taskDeliverables: collectionGetter('taskDeliverables', 'task_deliverable_id'),
  contacts: collectionGetter('contacts', 'contact_id'),
  projects: collectionGetter('projects', 'project_id'),
  issues: collectionGetter('issues', 'issue_id'),
  notes: collectionGetter('notes', 'note_id'),
  // SPATIAL GETTERS
  propertiesFeatureCollection: (state, getters) => ({
    type: 'FeatureCollection',
    info: {
      type: 'Polygon',
      label: 'Units',
      api: {
        details: "properties/:id",
        put: "property/save/:id",
        post: "property/saveas",
        remove: "property/remove/:id",
      },
    },
    style: { fillColor: '' },
    features: getters.properties.data.map(d => ({
      type: 'Feature',
      id: `${d.property_id}`,
      geometry: d.geometry,
      properties: { id: d.property_id, property_id: d.property_id, label: d.label },
    })),
  }),
  spatialLayers,
  layers: (state, getters, rootState, rootGetters) => ({
    properties: getters.propertiesFeatureCollection,
    ...getters.spatialLayers,
  }),
  // BOARD/TOOL CONFIG
  config: ( state, getters ) => { },
  // EVENTS
  taskEvents: eventsGetter('tasks'),
  issueEvents: eventsGetter('issues'),
  projectEvents: eventsGetter('projects'),
  events: ( state, getters ) => [
      ...getters.taskEvents,
      ...getters.projectEvents,
      ...getters.issueEvents,
  ],
};

const mutations = {
    ...crudMutations,
    ...collectionMutations,
  ADD_SPATIAL_LAYER,
  [SET_DATA](state, meta) {
    state.label = meta.label;
    state.description = meta.description;
    state.type = meta.region_type;
    state.lead = meta.lead;
    state.parentRegions = meta.parents || {}
    state.regionPath = meta.region_path.split(".");
    state.geometry = meta.geometry;
    state.loaded = true;
  },
};
const actions = {
    ...crudActions,
    ...collectionActions,
  getSpatialLayer,
  getObjectSpatialLayer,
  processPageData({ state, commit, dispatch }, { meta }){
   // dispatch('getObjectSpatialLayer', { object: 'region', id: state.id });
    commit(SET_DATA, meta);
  },
  updateLead({ state, commit, dispatch},  data) {
    const url = data.id ? `regionleads/save` : `regionleads/saveas`;
    const args = { region_id: state.id, ...data }
    return post(url, args).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
  removeLead({ state, commit, dispatch},  { id }) {
    const url = `regionleads/remove/${id}`;
    return post(url).then((res) => {
      dispatch('reload', {})
      return true;
    });
  },
};

// combine to a vuex store
export default function (options) {
  const stt = assign(state, options);
  const module = {
    namespaced: true,
    state() {
      return stt
    },
    getters,
    mutations,
    actions,
  };
  return module;
}
