import store from '@/store';
import { ROUTES_ADDED, LOGGED_IN, GET_MENU, UPDATE_BREADCRUMBS } from '@/store/mutation-types';
import router from './index';
import { get } from '@/utils';

//import propertyRoute from './property';
import propertyRoute from '@acorns-ui/Modules/PropertyModule/PropertyRoutes';
import contactRoutes from '@acorns-ui/Modules/ContactModule/ContactRoutes';

const hasToken = () => {
  const value = document.cookie
        .split('; ')
        .find(row => row.startsWith('acorns_token'));
  return !!value;
};


function buildAndSetBreadcrumb(to) {

  const workspace = to.matched
        .filter(r => r.meta && r.meta.source == 'workspace')
        .map(r => ({ ...r.meta }));

  const page = to.matched
        .filter(r => r.meta && r.meta.source == 'page')
        .map(r => ({ ...r.meta }));

  // make sure we want to update the page
  const n = page.length;
  //console.log('breadcrumbs', page, workspace);
  if(n>0 && !(page[n-1].skip)){
    store.commit('navigation/SET_PAGE_BREADCRUMBS', page);
  }
  store.commit('navigation/SET_WORKSPACE_BREADCRUMBS', workspace);
  //console.log('setting wbc', workspace);
  let url = to.path;
  store.dispatch('navigation/pageView', url);
  //store.commit('SET_BREADCRUMBS', [ ...page, ...workspace ]);
}

function setTitleAndHeaderTags(to) {
  // This goes through the matched routes from last to first, finding the closest route
  // with a title. eg. if we have /some/deep/nested/route and /some, /deep, and
  // /nested have titles, nested's will be chosen.

  const pageTitle = store.state.pageTitle || 'Ouzel';

  const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse()
  //   .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = `${pageTitle} - ${nearestWithTitle.meta.title}`;
  } else {
    // For pages that need to lookup its title it seems to work better to not have set
    // a title yet.
   // document.title = pageTitle;
  }

  // Remove any stale meta tags from the document using the key attribute set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return;

  // Add the meta tags to document head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));
}

function setMetaInfo(to) {
  setTitleAndHeaderTags(to);
  buildAndSetBreadcrumb(to);
}

function addDynamicRoutes() {
  const {
    propertyLabelSlug,
    propertyLabelSingular,
    propertyLabelPlural
  } = store.state.Global;
  //console.log('adding dynamic routes', propertyLabelSlug);
  if (store.state.routesAdded) return;
  if(propertyLabelSlug != 'properties') {
    router.addRoute({
        path: '/property/:id',
        redirect: `/${propertyLabelSlug}/:id`
    });
    router.addRoute({
        path: '/properties/:id',
        redirect: `/${propertyLabelSlug}/:id`
    });
  }
  var pr = propertyRoute(propertyLabelSingular, propertyLabelSlug);
  var cr = contactRoutes(propertyLabelPlural, propertyLabelSlug);
  //console.log('adding routes', propertyLabelSlug);
  router.addRoute(pr);
  router.addRoute(cr);
  //console.log('added routes', pr, cr);
  store.commit(ROUTES_ADDED);
}

function permissionCheck(to) {
  // replace with fetched store i.e. userRoutesAllowed value;
  const auth = to.matched[0].meta.authAreaName;
  // Allow unmatched routes to go to Not Found
  const wildcardRoute = to.matched[0].path === '*';

  const temp = [
    'accounts',
    'admin',
    'board',
    'contact',
    'contacts',
    'controlEvents',
    'control',
    'database',
    'documents',
    'home',
    'hours',
    'issue',
    'issues',
    'knowledgebase',
    'observations',
    'login',
    'logs',
    'parcels',
    'payments',
    'project',
    'projects',
    'properties',
    'property',
    'region',
    'regions',
    'role',
    'roles',
    'files',
    'spatial',
    'taxa',
    'tasks',
    'template',
    'templates',
    'transactions',
    'contracts',
    'invoices',
    'communications',
  ];

  if(!auth) {
    return true;
  } else if (!temp.includes(auth)) {
    console.log(`add '${auth}' to global permissions list:`, to.matched[0]);
    return false;
  } else {
    return wildcardRoute || temp.includes(auth);
  }
}


export default function globalRouteGuard(to, from, next) {

  const loggedIn = store.state.user.loggedIn;
  const initialized = store.state.Global.ready;
  const requiresAuth = true;//to.meta.requiresAuth;

  // Always allow /login
  //console.log('Start', from, to);
  const token = hasToken();
  const url = to.fullPath;
  const matchedPath = get(['matched', 0, 'path'], to);
  const pathChanged = to.path != from.path;

  //console.log('Route Guard', router, token, store.state.user.loggedIn, initialized, to.path);

  if(to.path === "/login") {
    // console.log('Going to login page');
    next();
  } else if (loggedIn && initialized && matchedPath === '*') {
    if(from.matched.length === 0) {
      // Bad url on entry so lets redirect
      next('/');
    } else {
      // Bad url from within the app so we just freeze and show the search modal
      // console.log(`Location "${to.fullPath}" does not exist`);
      next(false);
      //next(new Error(`Location "${to.fullPath}" does not exist`));
      //store.dispatch('PageModals/showModal', {
      //  component: 'NotFoundModal',
      //  params: { from, to }
      //});
    }
  } else {
    // console.log('running user check');
    store.dispatch('user/check')
      .then( registered => {
        // console.log('user check', registered);
        if(!registered) {
          // console.log('not registered', registered);
          next({ path: '/login', replace: true, query: { url } });
        } else {
          // console.log('Are we initialized?');
          if(!initialized) {
            // this is so we can use a link to show the task bugs
            if(to.query['serviceId']) {
              store.dispatch('navigation/showUserTab', 'requests');
            }
            //console.log('initializing');
            const endpoint = 'app/setup';
            store.dispatch('Global/initialize', { endpoint })
              .then( res => {
                // console.log('Success!');
                let { menu, roles, collections } = res;
                addDynamicRoutes();
                store.commit(GET_MENU, { menu });
                store.commit('navigation/SET_GLOBAL_SIDEBAR_ROLES', roles);
                store.commit('navigation/SET_GLOBAL_SIDEBAR_COLLECTIONS', collections);
                //console.log('all ready', to.fullPath);
                store.dispatch('user/initialize');
                setMetaInfo(to);
                next(to.fullPath);
                //next();
              })
              .catch((err) => {
                //console.error('error initializing, back to login page', err);
                next({ path: '/login', replace: true, query: { url } });
              });
          } else {
            //console.log('Success! Setting meta info', pathChanged, from.path, to.path);
            // lets not reset anything unless the path has been changed
            if(pathChanged) setMetaInfo(to);
            next();
          }
        }
      }).catch( err => {
        // assume its a bad token error
        console.error('Error #2, back to login page');
        next({ path: '/login', replace: true, query: { url } });
      });
  }


}
