<template>
<ObjectPageLayout
  class="control-events-admin">

  <Breadcrumbs
    slot="pagebar"
    :breadcrumbs="breadcrumbs" />

  <div
    slot="pagebar"
    class="actions">
    <IconLink
      :title="`New ${table}`"
      @clicked="addRecord">
      add
    </IconLink>
  </div>

  <ControlEventsAdminSidebar
    slot="sidebar"/>

  <div
    slot="content">
    <FadeTransition>
      <FlexTable
        v-if="loaded"
        :fields="fields"
        :table-data="data"
        :sorting="false"
        @rowClicked="rowClicked"
        />
    </FadeTransition>
  </div>

</ObjectPageLayout>
</template>

<script>
  import { mapState } from 'vuex';
import ObjectPageLayout from '@acorns-ui/Layouts/ObjectPageLayout';
import ControlEventsAdminSidebar from './ControlEventsAdminSidebar.vue';
import FlexTable from '@acorns-ui/Tables/FlexTable';
import FadeTransition from '@acorns-ui/Transitions/FadeTransition.vue';
import IconLink from '@acorns-ui/Icons/IconLink';
import Breadcrumbs from '@acorns-ui/Text/Breadcrumbs.vue';
import CollectionAdminMixin from '@acorns-ui/Stores/Collections/Mixins/CollectionAdminMixin';

const moduleTables = {
  methods: {
    collection: 'controlMethods',
    uniqueId: 'control_method_id',
  },
  products: {
    collection: 'controlProducts',
    uniqueId: 'control_product_id',
  },
  reasons: {
    collection: 'controlReasons',
    uniqueId: 'control_reason_id',
  },
  fields: {
    collection: 'objectFields',
    uniqueId: 'object_field_id',
  },
};

export default {
  name: 'ControlEventsAdmin',
  mixins: [CollectionAdminMixin],
  props: {
    module: {
      type: String,
      default: 'controlEvents',
    },
    table: {
      type: String,
      default: 'methods',
    },
  },
  data() {
    return {
    };
  },
  components: {
    ObjectPageLayout,
    ControlEventsAdminSidebar,
    FlexTable,
    FadeTransition,
    IconLink,
    Breadcrumbs,
  },
  created() {
  },
  watch: {
  },
  computed: {
    ...mapState([
      'breadcrumbs',
    ]),
    tables() {
      return moduleTables;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.control-events-admin  {
  .table-container {
    padding: 1rem 2rem;
  }
}
</style>
