<template>
  <div>
    <img src="https://images.vexels.com/media/users/3/134743/isolated/preview/97ae591756f3dc69db88c09fd097319a-sad-face-emoji-emoticon-by-vexels.png" >
    <h2>404</h2>
    <h3>Page not found!</h3>
    <!-- <p>{{ $route.path }}</p> -->
    <!-- <button
      @click="SHOW_MODAL({ name: 'new-payment', params: { greg: true } })">
      Launch modal
    </button> -->
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { SHOW_MODAL } from '@/store/mutation-types';
// import taskForm from '@/components/forms/taskForm'

export default {
  methods: {
    ...mapMutations([SHOW_MODAL]),
  },
};
</script>
<style scoped>
div {
  text-align: center;
  width:100%;
  margin-top:65px;
}
img {
  max-width: 140px;
}
</style>
