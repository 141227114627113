// I dont think that report1 is actually being used but I wanted to
// be safe and create this.
// this should be a parent store/container for layout, sections and datasources
// basically its a board
import { fetch, post } from '@/api';

const SET_META = 'SET_META';
export const SET_ID = 'SET_ID';
export const SET_URL = 'SET_URL';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CONFIG_VALUE = 'SET_CONFIG_VALUE';
export const SET_RESOURCE = 'SET_RESOURCE';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_BASE_FILTER = 'SET_BASE_FILTER';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SET_SECTIONS = 'SET_SECTIONS';
export const SET_SECTION_LAYOUT = 'SET_SECTION_LAYOUT';
export const SET_TOOL_FORM_PARAMS = 'SET_TOOL_FORM_PARAMS';

export const SHOW_TOOL_FORM = 'SHOW_TOOL_FORM';
export const CLOSE_TOOL_FORM = 'CLOSE_TOOL_FORM';

export const UPDATE_ACTIVE_SECTION = 'UPDATE_ACTIVE_SECTION';
export const UPDATE_SECTION_LAYOUT = 'UDPATE_SECTION_LAYOUT';

export const MOVE_ACTIVE_SECTION = 'MOVE_ACTIVE_SECTION';
export const MOVE_SECTION = 'MOVE_SECTION';

export const ADD_SECTION = 'ADD_SECTION';
export const ADD_DATASET = 'ADD_DATASET';

export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_DATASET = 'REMOVE_DATASET';

const initialState = {
  id: null,
  resource: 'reports',
  label: 'Report not loaded',
  description: null,
  layout: {
    label: 'No layout',
    component: 'dashboard-layout'
  },
  url: 'report2/load/:id',
  showToolForm: false,
  showReportForm: false,
  toolFormParams: {},
  activeSectionId: null,
  activeSectionPosition: null,
  baseFilter: {},
  config: {}, //{ filter: [ { worker: 40 } ] },
  sections: [],
  datasets: [],
};

const mutations = {
  [SET_ID](state, value) {
    if(value) state.id = value;
  },
  [SET_URL](state, value) {
    if(value) state.url = value;
  },
  [SET_CONFIG](state, value) {
    state.config = value || {};
  },
  [SET_CONFIG_VALUE](state, { key, value }) {
    if(!state.config) state.config = {};
    state.config[key] = value;
  },
  [SET_META](state, meta) {
    state.label = meta.label;
    state.description = meta.description;
  },
  [SET_RESOURCE](state, value) {
    if(value) state.resource = value;
  },
  [SET_LAYOUT](state, value) {
    if(value) state.layout = value;
  },
  [SET_BASE_FILTER](state, value) {
    if(value) state.baseFilter = value;
  },
  [SHOW_TOOL_FORM](state) {
    state.showToolForm = true;
  },
  [SET_TOOL_FORM_PARAMS](state, params) {
    state.toolFormParams = params || {};
  },
  [CLOSE_TOOL_FORM](state) {
    state.showToolForm = false;
  },
  [SET_ACTIVE_SECTION](state, id) {
    state.activeSectionId = id;
  },
  [SET_SECTIONS](state, value) {
    if(value) state.sections = value;
  },
  [SET_SECTION_LAYOUT](state, { id, layout }) {
    const idx = state.sections.findIndex( d => d.section_id === id);
    if(idx>=0){
      state.sections[idx] = Object.assign(state.sections[idx], { layout, dirty: true })
    }
  },
  [ADD_SECTION](state, section) {
    if(!section) {
      section = {}
    }
    if(!section.layout){
      section.layout = { width: 4, height: 4}
    }
    if(!section.tool_id){
      section.tool_id = 1
    }
    if(!section.section_id){
      section.section_id = state.sections.length * -1;
    }
    state.sections.unshift(Object.assign({ new: true }, section))
  },
  [MOVE_SECTION](state, { from, to }) {
    state.sections.splice(to, 0, state.sections.splice(from, 1)[0])
  },
  [MOVE_ACTIVE_SECTION](state, to) {
    const from = state.sections.findIndex( d => d.section_id === state.activeSectionId)
    if(from>=0 && from!=to) state.sections.splice(to, 0, state.sections.splice(from, 1)[0])
  },
  [UPDATE_ACTIVE_SECTION](state, data) {
    const idx = state.sections.findIndex( d => d.section_id === state.activeSectionId)
    console.log('update active section', idx)
    if(idx>=0) state.sections[idx] = Object.assign(state.sections[idx], { ...data, dirty: true });
  },
  [ADD_DATASET](state, id) {
    if(!state.datasets.includes(id)) state.datasets.push(id)
  },
  [REMOVE_DATASET](state, id) {
    const idx = state.datasets.findIndex(d => d === id);
    if (idx >= 0) state.datasets.splice(idx, 1);
  },
};

const getters = {
  config: state => state.config,
  config: ( state ) => {
    return { parent: 'report', parentId: state.id, ...state.config }
  },
  sections({ boards, boardsList }) {
    // return map(boardsList, id => board[keyForBoard(id)])
  },
};

const actions = {
  // provide the resource and unique id
  setup({ dispatch, commit }, { id, filter, url }){
    commit(SET_ID, id);
    commit(SET_URL, url);
    commit(SET_SECTIONS, []);
    commit(SET_BASE_FILTER, filter);
    dispatch('fetchReport')
  },
  fetchReport({ state, commit }) {
    console.log('fetch report2' , state)
    //const url = `${state.resource}/report/${state.id}`;
    //const url = `report2/load/${state.id}`;
    const url = state.url.replace(':id', state.id);
    return fetch(url).then( rsp => {
      commit(SET_META, rsp.meta)
      commit(SET_LAYOUT, rsp.layout);
      commit(SET_SECTIONS, rsp.sections);
    });
  },
  updateSectionOrder({ state, commit }, { from, to }){
    console.log('update section order', from, to)
    if(from && to) commit(MOVE_SECTION, { from , to })
    // now get the order and save it
    const url = `${state.resource}reports/reorder`;
    const order = state.sections.map( (d, i) => {
      return { id: d.section_id, row_order: i }
    });
    return post(url, { order }).then((res) => {
      //console.log(res)
    });
    return true;
  },
  saveReportChanges({ state, dispatch}){
    // save any report layout changes
    dispatch('updateSectionOrder', {})
    // save any section changes
    const sections = state.sections.filter( d => d.dirty  || d.new)
    // only saving config, layout and tool_id
    for(let w of sections){
      let { config, layout } = w
      // new sections dont have the tool object
      let tool_id = w.new ? w.tool_id : w.tool.tool_id;
      // new sections will need the report id
      let id = w.new ? state.id : w.section_id;
      let url = w.new
          ?  `${state.resource}reports/saveasSection`
          :  `${state.resource}reports/saveSection`;
      post(url, { id, config, layout, tool_id })
        //.then( (res) => commit(''))
    }
    // need to chain all these before the return
    //console.log('saved report changes')
    return true;
  },
  // update the paramenters of a dataset and
  // refresh
  updateDataset({ dispatch, commit }, { id, args }){
    const dataset = `dataset${id}`;
    return dispatch(`${dataset}/update`, args, { root: true });
  },
  updateDatasets({ dispatch, state }, args){
    for(var i of state.datasets){
      dispatch(`dataset${i}/update`, args, { root: true });
    }
  },
  getDataset({ dispatch, commit }, { id, filter}){
    return dispatch('getDataset', { id }, { root: true })
      .then(() => commit(ADD_DATASET, id))
  },
  removeDataset({ state, dispatch }, { id }){
    return dispatch('destroyDataset', { id }, { root: true })
      .then(() => commit(REMOVE_DATASET, id))
  },
  updateReport({ state, dispatch}, data){
    const url = `reports/save`;
    const id = state.id;
    return post(url, { id, ...data }).then((res) => {
      dispatch('fetchReport')
    });
  },
  editSection({ state, commit }, { id }){
    commit(SET_ACTIVE_SECTION, id)
    commit(SHOW_TOOL_FORM)
    // need tool and tool config
    var params = state.sections.find( d => d.section_id === id);
    var callback = (d) => commit(UPDATE_ACTIVE_SECTION, d)
    commit(SET_TOOL_FORM_PARAMS, { ...params, callback })
  },
  updateSection({ state, dispatch }, data){
    const url = `reports/saveSection`;
    return post(url, data).then((res) => {
      dispatch('fetchReport')
    });
  },
  addSection({ state, dispatch }, data){
    console.log('save as section', data)
    const { id } = state;
    const url = `sections/saveas`;
    return post(url, { id , ...data }).then((res) => {
      dispatch('fetchReport')
    });
  },
  removeSection({ state, dispatch }, data){
    const url = `sections/remove/:id`;
    return post(url, data).then((res) => {
      dispatch('fetchReport')
    });
  },
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
