<template>
<FormLayout
  class="restriction-form"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  :tabs="tabs"
  :tab.sync="tab"
  @close="closeHandler">

  <div slot="header" class="header-label row">
    <div v-if="parent" class="parent-label">
      {{ parentLabel }} -&nbsp
    </div>
    <div>{{ actionLabel }} restriction</div>
  </div>


  <Tag
    slot="actions"
    :theme="tab != 'import' ? 'lighten' : 'darken'"
    @clicked="tab = (tab == 'import') ? 'general' : 'import'">
    Import
  </Tag>

  <template
    slot="general">

    <LabeledInput
      v-if="!hasSource"
      label="Source"
      details="What is the source of the restriction? For example, a title or a management plan?"
      :status="status.source_id">
      <DropdownInput
        v-model="record.source_id"
        :method="`sources/lookup?propertyId=${parentId}`"
        :required="true"
        new-placeholder="Add a new source"
        new-form="SourceForm"
        :new-form-params="{ parent, parentId }"
        @status-changed="updateStatus('source_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Use"
      details="What type of land use does this restriction cover?"
      :status="status.use_id">
      <DropdownInput
        v-model="record.use_id"
        method="uses/lookup"
        :required="true"
        @status-changed="updateStatus('use_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Classification"
      details="Is this allowed, prohibited or regulated (allowed with permission)"
      :status="status.classification_id">
      <DropdownInput
        v-model="record.classification_id"
        method="classifications/lookup"
        :required="true"
        @status-changed="updateStatus('classification_id', $event)"/>
    </LabeledInput>

    <div class="input-group">

      <LabeledInput
        label="Starts"
        :status="status.start_date">
        <DateInput
          v-model="record.start_date"
          :required="true"
          @status-changed="updateStatus('start_date', $event)"/>
      </LabeledInput>

      <LabeledInput
        label="Ends"
        :status="status.end_date">
        <DateInput
          v-model="record.end_date"
          :required="false"
          @status-changed="updateStatus('end_date', $event)"/>
      </LabeledInput>

    </div>

    <LabeledInput
      label="Description"
      :fill="true"
      details=""
      :status="status.description">
      <TextareaInput
      v-model="record.description"
      :required="true"
      @status-changed="updateStatus('description', $event)"/>
  </LabeledInput>

  </template>

  <div
    class="padded column"
    slot="import">

    <LabeledInput
      label="Property"
      details="Select the property you wish to import from">
      <DropdownInput
        method="properties/lookup"
        :allow-search="true"
        @updated="importPropertyRestrictions"/>
    </LabeledInput>

    <LabeledInput
      label="Sources"
      details="Select the source you wish to import from">
      <DropdownInput
        method="sources/lookup"
        :allow-search="true"
        @updated="importSourceRestrictions"/>
    </LabeledInput>

    <LabeledInput
      label="Uses"
      details="Select the land use you are looking for">
      <DropdownInput
        method="uses/lookup"
        :allow-search="false"
        @updated="importUseRestrictions"/>
    </LabeledInput>

    <div
      v-if="examples.length"
      class="examples-container">
      <div class="smaller italics">
        Click on a restriction to select that one to modify or select multiple using the check boxes to import them each as is.
      </div>
      <ItemLayout
        v-for="(e, i) in examples"
        :key="e.restriction_id"
        :selected="e.selected"
        :as-row="false"
        @selected="updateSelected(i)"
        @clicked="importExample(e,i)">
        <div>
          {{ e.source.label }}: {{ e.use.label }} / {{ e.classification.label }}
        </div>
        <div class="smaller italics">
          {{ e.description }}
        </div>
        <div
          v-if="e.error"
          class="smaller italics alert">
          {{ e.error }}
        </div>
        <div
          v-if="e.saved"
          class="smaller italics success">
          Restriction imported
        </div>
      </ItemLayout>
    </div>

  </div>


  <Button
    v-if="tab == 'general'"
    slot="buttons"
    @click="saveAndClose">
    save
  </Button>

  <Button
    v-if="tab == 'import'"
    slot="buttons"
    :disabled="!selectedExamples.length"
    @click="importSelectedExamples">
    import
  </Button>

</FormLayout>
</template>

<script>
  import { fetch } from '@/api';

import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import IconLink from '@acorns-ui/Icons/IconLink';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput'
import DropdownInput from '@acorns-ui/Inputs/DropdownInput'
import TextareaInput from '@acorns-ui/Inputs/TextareaInput'
import TextInput from '@acorns-ui/Inputs/TextInput'
import DateInput from '@acorns-ui/Inputs/DateInput'
import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
import ChecklistInput from '@acorns-ui/Inputs/ChecklistInput';
import Button from '@acorns-ui/Inputs/Button';
import Tag from '@acorns-ui/Text/Tag';
import ItemLayout from '@acorns-ui/Layouts/ItemLayout/ItemLayout2';

export default {
  name: 'RestrictionForm',
  mixins: [
    CollectionFormMixin,
    FormValidationMixins,
  ],
  props: {
    collection: {
      default: 'restrictions'
    },
    source: {
      type: [String, Number],
    },
  },
  data() {
    return {
      tab: 'general',
      examples: [],
      record: {
        description: null,
        use_id: null,
        classification_id: null,
        source_id: null,
        start_date: null,
        end_date: null,
      }
    };
  },
  components: {
    LabeledInput,
    DropdownInput,
    TextareaInput,
    TextInput,
    FormLayout,
    IconLink,
    DateInput,
    Button,
    CheckboxInput,
    ChecklistInput,
    Tag,
    ItemLayout,
  },
   created() {
     if(this.source) {
       this.record.source_id = this.source;
     } else if(this.parent == 'sources' && this.parentId) {
       this.record.source_id = this.parentId;
     }
  },
  watch: {
    showExamples(a){
      if(a) this.fetchExamples();
    }
  },
  computed: {
    tabs() {
      var tabs = [
        { uid: 'general', label: 'General' },
      ];
      tabs.push({ uid: 'import', label: 'Import', hide: true});
      return tabs;
    },
    selectedExamples() {
      return this.examples.filter(d=>d.selected);
    },
    hasSource() {
      return this.source || (this.parent == 'sources' && this.parentId);
    },
    hasParent() {
      return this.parent
        && this.parentId
        && !(['sources'].includes(this.parent));
    },
  },
  methods: {
    updateSelected(idx) {
      const ex = this.examples[idx];
      ex.selected = !ex.selected;
      this.examples.splice(idx,1,ex);
    },
    importSourceRestrictions(id) {
      fetch(`sources/${id}/restrictions?f=list`)
        .then( list => {
          this.examples = list;
        })
    },
    importUseRestrictions(id) {
      fetch(`uses/${id}/restrictions?f=list`)
        .then( list => {
          this.examples = list;
        })
    },
    importPropertyRestrictions(id) {
      fetch(`properties/${id}/restrictions?f=list`)
        .then( list => {
          this.examples = list;
        })
    },
    importExample(ex, idx) {
      this.updateSelected(idx);
      this.record.description = ex.description;
      this.record.classification_id = ex.classification.classification_id;
      this.record.use_id = ex.use.use_id;
      this.tab = 'general';
    },
    updateExample(ex) {
      let idx = this.examples.findIndex(d => d.restriction_id == ex.restriction_id);
      if(idx>=0) {
        this.examples.splice(idx, 1, ex);
      }
    },
    importSelectedExamples() {
      // console.log('import', ex);
      // for each selected example
      let data = {};
      data.source_id = this.record.source_id;
      data.start_date = this.record.start_date;
      data.end_date = this.record.end_date;
      this.selectedExamples.map( ex => {
        data.description = ex.description;
        data.classification_id = ex.classification.classification_id;
        data.use_id = ex.use.use_id;
        this.$store.dispatch('restrictions/addRecord', { data })
          .then( res => {
            console.log(res)
            ex.error = null;
            ex.saved = true;
            this.updateExample(ex)
            if(this.onSave) this.onSave(res);
          })
          .catch( err => {
            // update example with error
            ex.error = err.message;
            this.updateExample(ex)
          })
      })
    },
  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .restriction-form {
    @include formsize;
  }
}

.restriction-form  {

  .input-group {
    @include row;
    &>.form-input-container {
      //margin-right: 1rem;
      flex: 1;
      margin: 0.25rem;
    }
  }

  .description-input {
    flex: 1;
  }
  .alert {
    color: $red;
  }
  .success {
    color: $green;
  }
}
</style>
