import directives from './directives';
import filters from './filters';
import * as d3 from 'd3';
import moment from 'moment';

export function queryString(params) {
  if (!params) return '';
  return `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`;
}

export function setLocalValue(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

export const format = (str, args) => {
  for (const arg in args) {
    str = str.replace(new RegExp(`\\{${arg}\\}`, 'gi'), args[arg])
  }
  return str;
}

export const clone = (a) => {
  return JSON.parse(JSON.stringify(a||{}))
}

export const assign = (a, b) => {
  return Object.assign(JSON.parse(JSON.stringify(a || {})), b || {})
}

export const isEmpty = obj => {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}


// https://gist.github.com/youssman/745578062609e8acac9f
export const camel2dash = str =>
  str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()

export const dash2pascal = str =>
  str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()

// https://github.com/ianstormtaylor/to-pascal-case/blob/master/index.js
export const toPascalCase = str => {
  return space(str).replace(/(?:^|\s)(\w)/g, function (matches, letter) {
    return letter.toUpperCase()
  })
}

export const age = (date) => {
  if(!date) return 'N/A'
  const dt = moment(date);
  const day = moment().subtract(1,'day');
  const week = moment().subtract(1,'week');
  const month = moment().subtract(1,'month');
  const year = moment().subtract(1,'year');

  if(dt.isAfter(day)) {
    return '1';
  } else if(dt.isAfter(week)) {
    return '2';
  } else if(dt.isAfter(month)) {
    return '3';
  } else if(dt.isAfter(year)) {
    return '4';
  } else {
    return '5';
  }
}
// pairs up with the age function above
export const ageLabel = (grp) => {
  const labels = {
    1: 'Today',
    2: 'This week',
    3: 'This month',
    4: 'This year',
    5: 'Older than a year',
  };
  return labels[grp] || grp;
}

// dig for a property inside on object (o) using an array path (p)
// d is the default and its returned if nothing else is found
export const get = (p, o, d = null) =>
  p.reduce((xs, x) => (xs && typeof(xs[x])!==undefined) ? xs[x] : d, o);

export const label = (value) => {
  // is the value an array?
  if(Array.isArray(value)) {
    return value.map(d=>d.label||d).join(', ');
  } else if(value){
    return value.label || value;
  } else {
    return '--';
  }
};



// reduce object (o) to set of fields (k)
export const pick = (obj, keys) =>
  keys.map( k => k in obj ? {[k]: obj[k]} : {})
  .reduce((res, o) => Object.assign(res, o), {});

export const objectify = (arr, key) =>
  Object.assign({}, ...arr.map(item => ({[item[key]]: item})));

export const arrayify = (obj, keyName) =>
  Object.entries(obj).map(([key, value]) => ({ [keyName]: key, ...value }));

export const arrayify2 = (obj) =>
  Object.entries(obj).map(([key, value]) => ({ key, value }));

// simpler version than the d3 method
// https://stackoverflow.com/questions/14446511/what-is-the-most-efficient-method-to-groupby-on-a-javascript-array-of-objects/46431916#46431916
// By joseph nields
export const groupBy = (items, key) => items.reduce(
  (result, item) => ({
      ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

// https://gist.github.com/telekosmos/3b62a31a5c43f40849bb (cup and glococo, jul 2017)
export const collapse = (items, key) =>
  items.reduce((x, y) => x.findIndex(e=>e[key]==y[key])<0 ? [...x, y]: x, [])

export function getLocalValue(name, value) {
  var value = localStorage.getItem(name);
  if (value) return JSON.parse(value);
}

export function removeLocalValue(name) {
  localStorage.removeItem(name);
}

export function processData(obj) {
  if(!obj.data) return;
  var timeTypes = ['timestamp','datetime-field','date-field'];
  var factorTypes = ['factor','text-field','object-label','spatial-field'];

  for(let i = 0; i<obj.fields.length; i++) {
    let { key, type, format } = obj.fields[i];
    //key = obj.fields[i].key;
    //type = obj.fields[i].typ;

    if(timeTypes.includes(type)) {
      //console.log('date field', obj.data)
	    let fx = d3.timeParse(format || '%Y-%m-%d');
      obj.data.forEach(function(d){
	      if(d[key]!=null){
    		  d[key] = fx(d[key]);
	      }
      });
    }
    // calculate the min/max
    // calculate the min and max
    if(factorTypes.includes(type)){
      //console.log('factor', obj.data, key)
      obj.fields[i].domain = [...new Set(obj.data.map(d => d[key]))]
    } else {
      let mn = d3.min(obj.data, function(d){
    	  return d[key];
      });
      let mx = d3.max(obj.data, function(d){
    	  return d[key];
      });
      obj.fields[i].domain = [mn, mx]
    }
    obj.fields[i].processed = true;
  }
}



export function processWideData(obj){
  // process each field
  //if(!obj.fields) return;
  for(let i in obj.fields) {
    if(!obj.fields[i].processed){
      processWideDataField(obj, i);
    }
  }
  if(obj.flags){
    obj.flags.forEach(function(flag) {
      processFlag(flag);
    })
  }
}

export function processFlag(flag){
	var fx = d3.timeParse('%Y-%m-%d %H:%M:%S');
  flag['start_datetime'] = fx(flag['start_datetime']);
  flag['end_datetime'] = fx(flag['end_datetime']);
}

export function processWideDataField(obj,i){
  // check the data type
  var naz = obj.fields[i].naz || false; // null as zero
  var fld = obj.fields[i].name; // dont really need this, i == name
  const datetimeFields = ["date","time","datetime","timestamp"];

  if(datetimeFields.includes(obj.fields[i].type)){
	  // pull out the formt and build a function
    const fmt = obj.fields[i].format;
	  var fx = d3.timeParse(fmt);
    //console.log(fx, fmt, fx('2017-07-03 10:44:00'))
	  // make sure the scale is right
	  //this.par.xscale = 'timestamp'
  }else{ // assume numeric
	  var fx = function(d){ return +d;};
  }
  // use that function to fix values
  // loop through the groups
  obj.rows.forEach(function(data){
    data.forEach(function(d){
	    if(d[fld]!=null){
    		d[fld] = fx(d[fld]);
	    }else if(naz){
		    d[fld] =  0;
	    }
	  })
  });

  // calculate the min and max
  var mn = d3.min(obj.rows, function(data){
	  return d3.min(data,function(d){
    	return d[fld];
	  })
  });

  var mx = d3.max(obj.rows, function(data){
	  return d3.max(data,function(d){
    	return d[fld];
	  })
  });

  var rng = mx - mn;
  var bfr = rng*.05;

  obj.fields[fld].domain = [mn, mx]
  //obj.fields[i].domain2 = [mn-bfr, mx+bfr]
  obj.fields[fld].processed = true;

  return obj;
}
