<template>
<FormLayout
  class="template-field-form"
  :inline="inline"
  :saving="saving">

  <IconLink
    slot="actions"
    @clicked="$emit('close')">
    close
  </IconLink>

  <LabeledInput
    v-if="!id">
    <label slot="label">
      Source
    </label>
    <DropdownInput
      v-model="fieldSourceId"
      :options="fieldSources"
      :multiple="false"
      />
  </LabeledInput>

  <template
    v-if="!id && fieldSourceId == 1">
    <LabeledInput
      v-if="!templateId">
      <label slot="label">
        Template
      </label>
      <DropdownInput
        v-model="record.template_id"
        method="templates/lookup2"
        :multiple="false"
        />
    </LabeledInput>

    <LabeledInput
      v-if="record.template_id">
      <label slot="label">
        View
      </label>
      <DropdownInput
        v-model="viewId"
        method="views/lookup2"
        :args="{ objectId }"
        :multiple="false"
        />
    </LabeledInput>

    <LabeledInput
      v-if="viewId">
      <label slot="label">
        Field
      </label>
      <DropdownInput
        v-model="record.field_id"
        method="fields/lookup2"
        :args="{ viewId, notInTemplate: record.template_id }"
        :multiple="false"
        />
    </LabeledInput>

  </template>


  <template
    v-if="!id && fieldSourceId == 3">

    <LabeledInput>
      <label slot="label">
        Field
      </label>
      <DropdownInput
        v-model="record.field_id"
        method="fields/lookup2"
        :args="{ fieldSourceId }"
        :multiple="false"
        />
    </LabeledInput>

  </template>

  <template
    v-if="fieldSourceId == 2">

    <LabeledInput>
      <label slot="label">
        Deliverable Field
      </label>
      <DropdownInput
        v-model="record.field_id"
        method="templatedeliverables/lookup2"
        :args="{ templateId }"
        :multiple="false"
        />
    </LabeledInput>

  </template>


  <LabeledInput>
    <label slot="label">
      Label
    </label>
    <TextInput
      v-model="record.label"
      />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">
      Caption
    </label>
    <TextInput
      v-model="record.caption"
      />
  </LabeledInput>

  <LabeledInput
    :fill="true">
    <label slot="label">
      Description
    </label>
    <TextareaInput
      v-model="record.description"
      />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">
      Column order
    </label>
    <NumberInput
      v-model="record.sort_order"
      />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">
      Component
    </label>
    <DropdownInput
      v-model="record.field_component_id"
      method="fieldcomponents/lookup2"
      :multiple="false"
        />
    </LabeledInput>

  <template slot="buttons">
    <Button
      :disabled="!isDirty"
      @click="save"
      type="save">
      Save
    </Button>
    <Button
      @click="$emit('close')"
      type="cancel">
      Close
    </Button>
    <Button
      v-if="id"
      @click="removeRecord"
      type="delete">
      delete
    </Button>
  </template>


</FormLayout>
</template>

<script>
  import { get} from '@/utils';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import IconLink from '@acorns-ui/Icons/IconLink';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
import Button from '@acorns-ui/Inputs/Button';
import TextInput from '@acorns-ui/Inputs/TextInput';
import TextareaInput from '@acorns-ui/Inputs/TextareaInput';
import NumberInput from '@acorns-ui/Inputs/NumberInput';
import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';

export default {
  name: 'TemplateFieldForm',
  mixins: [CollectionFormMixin],
  props: {
    collection: {
      type: String,
      default: 'templateFields',
    },
  },
  data() {
    return {
      fieldSourceId: null,
      viewId: null,
      objectId: 3,
      templateId: 4,
      record: {
        template_field_id: null,
        template_id: null,
        field_id: null,
        label: null,
        caption: null,
        description: null,
        field_component_id: null,
        sort_order: 1,
        is_default: true,
        attributes: {},
      }
    };
  },
  components: {
    FormLayout,
    Button,
    IconLink,
    LabeledInput,
    TextInput,
    TextareaInput,
    NumberInput,
    DropdownInput,
  },
  created() {
    // if the template exists we should lookup the templateId
    this.record.template_id = 4;//this.parentId;
  },
  watch: {},
  computed: {
    fieldSources(){
      const fs = [
        {uid: 1, label: 'View/Table'},
        {uid: 3, label: 'Spatial'},
        {uid: 2, label: 'Deliverable'},
      ];
      return fs;
    }
  },
  methods: {
    //getMetaData(){
    //  this.$store.dispatch(`${this.template}/getMetaData`)
    //  .then( res => this.record.template_id = res.template_id);
   // }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.template-field-form  {

  .form-content {
    padding: 1rem;
  }
}
</style>
