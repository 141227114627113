import { post } from '@/api';

import {
  initialState as baseState,
  getters as baseGetters,
  actions as baseActions,
  mutations as baseMutations,
} from './collection';

import {
  state as eventsState,
  getters as eventsGetters,
} from './eventsCollectionMixins';

const initialState = {
    ...baseState,
    ...eventsState,
  uniqueId: 'property_status_id',
  record: 'propertyStatus',
  collection: 'propertyStatuses',
  eventFields: ['start_date']
};

const getters = {
    ...baseGetters,
    ...eventsGetters,
};

const mutations = {
  ...baseMutations,
};

const actions = {
  ...baseActions
};

const module = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default module;
