<template>
<FormLayout
   class="observation-form"
   :inline="inline"
   :saving="saving">

  <div slot="header" class="observation-options">

    <div v-if="property" class="parent-label">
      {{ propertyLabel }} -&nbsp
    </div>
    <div>New Observation</div>
  </div>

  <IconLink
     slot="actions"
     @clicked="$emit('close')">
    close
  </IconLink>

  <LabeledInput>
    <label slot="label">Taxon</label>
    <DropdownInput
       v-model="record.taxon_id"
       method="taxa/lookup2"
       :multiple="false"
       />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">Date/time</label>
    <DatetimeInput
       v-model="record.datetime"
       />
  </LabeledInput>

  <LabeledInput>
    <label slot="label">Description</label>
    <TextareaInput
       v-model="record.notes"
       />
  </LabeledInput>


  <template slot="buttons">
    <Button
       :disabled="!isDirty"
       @click="saveObservation"
       type="save">
      Save
    </Button>
    <Button
       @click="$emit('close')"
       type="cancel">
      Close
    </Button>

  </template>

</FormLayout>
</template>

<script>
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import IconLink from '@acorns-ui/Icons/IconLink';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
import Button from '@acorns-ui/Inputs/Button';
import TextInput from '@acorns-ui/Inputs/TextInput';
import TextareaInput from '@acorns-ui/Inputs/TextareaInput';
import DatetimeInput from '@acorns-ui/Inputs/DatetimeInput';
import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
import NestedInput from '@acorns-ui/Inputs/NestedInput';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ObservationForm',
  //  mixins: [DefaultValuesMixin],
  props: {
    property: [String, Number],
    callback: Function,
    inline: { default: false }
  },
  data() {
    return {
      saving: false,
      currentActivity: null,
      record: {
        label: '',
        description: null,
        activity_id: null,
        parent_observation: null,
        priority_id: 1,
        budgeted_cost: 0,
        lead_contact_id: null,
        worker_role_id: 1,
        account_id: 1,
        start_date: null,
        end_date: null,
      }
    };
  },
  components: {
    FormLayout,
    Button,
    IconLink,
    LabeledInput,
    TextInput,
    TextareaInput,
    DatetimeInput,
    DropdownInput,
    NestedInput,
  },
  watch: {},
  computed: {
      ...mapGetters('user', [
        'cid',
      ]),
      ...mapState('property', {
        propertyLabel: 'label',
      }),
    isDirty(){
      return Object.keys(this.record).length>0
    },
    parent(){
      if(this.property) return 'property';
      return null;
    }
  },
  created() {
  },
  methods: {
    reset(){
      this.record.label = null;
      this.record.description = null;
      this.record.activity_id = null;
      this.saving = false;
    },
    saveObservation(){
      this.saving = true;
      if(this.parent) return this.saveParentObservation();
      this.$store.dispatch('observations/addRecord', this.record )
        .then( res => {
          if(this.callback) this.callback(res)
          this.reset();
          this.$emit('updated', res);
          this.$emit('close')
        })
    },
    saveParentObservation() {
      const collection = 'observations';
      const parent = this.parent;
      this.$store.dispatch(`${parent}/addCollectionItem`, { collection, data: this.record })
        .then( res => {
          this.reset();
          this.$store.dispatch(`${parent}/refreshCollectionData`, { collection })
          if(this.callback) this.callback(res)
          this.$emit('updated', res);
          this.$emit('close')
        })
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/variables';
.observation-form  {
  //width: 50vw;
  .textarea-input {
    height: 12rem !important;
  }

  .parent-label {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }

  .observation-type-label {
    .display-label {
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }
  }

  .observation-options {
    display: flex;
    align-items: center;
  }
}
</style>
